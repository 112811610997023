import { Typography }  from 'antd'
import './memberIntro.scss'
import { CloseOutlined } from '@ant-design/icons'
import Breakpoint from "../../../../enums/breakpoint.enum";
import PhotoHelper from '../../../../helpers/photo.helper';
import useWidth from '../../../../hooks/useWidth.hook';
import { MembershipPoints } from '../../../membershipPoints/membershipPoints.component';
import MembershipTypeCode from '../../../../enums/membershipTypeCode.enum';
import { getMembershipType } from '../../../../services/membershipType.service';

const { Paragraph } = Typography

const MemberIntro = ({ footer, membershipType }) => {
  const width = useWidth()

  const onChat = () => {
    window.FrontChat("show");
  }

  
  membershipType.features[0] = {
      title: "Personal Longevity Physician",
      description: `Enjoy ongoing consultations with your own longevity physician, offering continual support and expert advice on how to improve you long-term health.`
  }
  

  return (
    <div 
      className="member-intro"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >

      {PhotoHelper.format({
        jpg: "/img/green.jpg",
        webp: "/img/green.webp",
        className: "intro-bg",
      })}

      <div 
        className="member-intro-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <Paragraph className="member-title">
          Join Instalab Membership
        </Paragraph>

        <Paragraph className="member-subtitle">
          Get a partner dedicated to your long-term health<br/>
        </Paragraph>


        <div className="member-pricing-container">
          <div className="member-pricing">
            Starting at ${membershipType?.isAnnual ? Math.round(membershipType?.cost/12) : membershipType?.cost}/month. Cancel anytime.
          </div>
        </div>


        <Paragraph className="member-description">
          <MembershipPoints membershipType={membershipType}/>
        </Paragraph>

        {footer({ 
          buttonText: "Let's Go",
          buttonCheck: false,
          buttonStyle: {
            height: 50,
            fontSize: 18,
            width: '100%',
            marginTop: 40,
            display: 'block',
          },
          enterStyle: {
            display: 'none',
          }
        })}

        <div className="question-link-container">
          <a 
            onClick={onChat}
            className="question-link"
          >
            I have a question.
          </a>
        </div>
      </div>
    </div>
  )
}

export default MemberIntro