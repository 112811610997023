import { Modal, Form, Input, Typography, Button, message } from 'antd'
import './subscribeModal.scss'
import { useContext, useEffect, useState } from 'react'
import PosthogHelper from '../../helpers/posthog.helper';
import StorageKey from '../../enums/storageKey.enum';
import RuleHelper from '../../helpers/rule.helper';
import { UserContext } from '../../contexts/user.context';
import { addSubscription } from '../../services/subscription.service';

const { Paragraph } = Typography
const { Item } = Form;
const { TextArea } = Input;

export const SubscribeModal = ({ open, setOpen }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState()
  const { currentUser } = useContext(UserContext)

  useEffect(() => {
    if (open && currentUser) {
      form.setFieldsValue({ email: currentUser.email })
    }
  }, [currentUser, open])

  const onCancel = () => {
    setOpen(false)
  }

  const onFinish = async () => {
    setIsSubmitting(true)
    const formError = await hasFormError()
    if (formError) {
      message.error('Enter a valid email address')
      setIsSubmitting(false)
      return
    }

    try {
      const {
        email,
        city,
        topics
      } = form.getFieldsValue()

      await addSubscription({
        email,
        city,
        topics,
      })

      setOpen(false)
      message.success(`You are subscribed!`)
      form.resetFields()
      PosthogHelper.track(currentUser, '[blog] subscribed', true)
    } catch (err) {
      console.log('err', err)
      message.error('Failed to subscribe to newsletter')
    }
    setIsSubmitting(false)
  }

  const hasFormError = async () => {
    try {
      await form.validateFields();
    } catch (errors) {
      return true
    }
    return false
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className="subscribe-modal"
      footer={null}
      width={700}
    >
      <Paragraph className="subscribe-title">
        Stay Informed, Stay Healthy
      </Paragraph>
      <Paragraph className="subscribe-subtitle">
      Subscribe to receive expert knowledge, actionable tips, and the latest breakthroughs in health, all curated to help you make informed decisions without compromising your productivity.
      </Paragraph>

      <Form 
        form={form} 
        onFinish={onFinish} 
        layout="vertical"
        className="subscribe-form"
      >
        <Item
          label="Email Address"
          name="email"
          onInput={e => e.target.value = e.target.value.toLowerCase()}
          rules={[
            RuleHelper.isRequired,
            RuleHelper.isEmail,
          ]}
          validateTrigger='onSubmit'
        >
          <Input placeholder="Enter email" />
        </Item>

        <Item
          label={"What city do you reside in?"}
          name="city"
        >
          <Input placeholder="Enter city" />
        </Item>

        <Item
          label="What topics would you like us to dig into?"
          name="topics"
        >
          <TextArea placeholder="Enter topics" autoSize />
        </Item>

        <Item noStyle>
          <Button
            type="primary"
            className="subscribe-btn"
            htmlType='submit'
            loading={isSubmitting}
          >
            Subscribe
          </Button>
        </Item>
      </Form>
    </Modal>
  )
}