import { useContext } from "react";
import { Typography, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PosthogHelper from "../../../helpers/posthog.helper";
import classNames from "classnames";
import ReactAudioPlayer from 'react-audio-player';
import { UserContext } from "../../../contexts/user.context";
import { StickyBlogHeader } from "./stickyBlogHeader.component";

const { Paragraph } = Typography;

export const ApoBPost = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const { blogId } = useParams();

  return (
    <div className={classNames("blog-post", "apob-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          Could your cholesterol test be missing a key risk factor? Meet ApoB.
        </Paragraph>

        <ReactAudioPlayer
          src="/audio/apob.mp3"
          controls
          className="blog-audio"
          onPlay={() => {
            PosthogHelper.track(currentUser, `[blog] play audio: ${blogId}`, true)
          }}
        />

        <Paragraph className="blog-subheading">
          Most of us know to keep an eye on our LDL cholesterol, but new research suggests that ApoB—a lesser-known marker—might give us a clearer picture of our heart health. If staying ahead of cardiovascular risk is a priority, let's explore how ApoB could help you prevent serious heart issues down the line.
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt="Nov 8, 2024"
        readTime="6 Min Read"
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          Why ApoB Matters
        </Paragraph>
        <Paragraph className="blog-description">
          Think of LDL cholesterol as cargo and ApoB as the delivery trucks. Each ApoB particle represents one of these "trucks," no matter how much cholesterol it's carrying. This means that even if your LDL levels look fine, a high ApoB count could mean there are more particles clogging up your arteries. Essentially, ApoB shows how many cholesterol-carrying particles are in your bloodstream, revealing risks that your standard cholesterol test might miss.
        </Paragraph>

        <Paragraph className="blog-title">
          How Does ApoB Impact Your Health?
        </Paragraph>
        <Paragraph className="blog-description">
          ApoB is a powerful predictor of heart disease—even when LDL cholesterol levels appear normal. It can catch risks that a standard cholesterol test might overlook. A <a onClick={() => window.open('https://doi.org/10.1016/j.amjcard.2012.07.007', '_blank')} className="blog-link">recent meta-analysis</a> of over <strong>131,000</strong> people highlighted the tangible benefits of reducing ApoB:
        </Paragraph>

        <Table
          className="blog-table"
          dataSource={[{
            reduction: '10 mg/dL decrease',
            benefit: '9% reduction in coronary heart disease (CHD) risk'
          }, {
            reduction: '10 mg/dL decrease',
            benefit: '6% reduction in overall cardiovascular events'
          }]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column 
            title="Reduction in ApoB" 
            dataIndex="reduction"
          />
          <Table.Column 
            title="Associated Health Benefit" 
            dataIndex="benefit"
          />
        </Table>

        <Paragraph className="blog-description">
          This study, led by <i>Robinson et al.</i>, demonstrated that each <strong>10 mg/dL drop</strong> in ApoB brings a <strong>9% decrease</strong> in CHD risk and a <strong>6% decrease</strong> in other cardiovascular events. For you, tracking and lowering ApoB could mean a significant reduction in your risk for heart disease, heart attacks, and other cardiovascular issues.
        </Paragraph>

        <Paragraph className="blog-description">
          The <a onClick={() => window.open('https://doi.org/10.1016/j.jacc.2015.10.055', '_blank')} className="blog-link"><i>CARDIA study</i></a> further showed why ApoB is especially important for young adults. Over a 25-year period, young adults with high ApoB levels were found to be <strong>2.28 times</strong> more likely to develop coronary artery calcification (CAC), an early warning sign of heart disease. CAC is essentially the buildup of plaque in your arteries—a gradual process that can quietly lead to blockages. So, even if you're young and feel healthy, knowing your ApoB levels might reveal hidden risks and help you take preventive steps.
        </Paragraph>

        <Paragraph className="blog-title">
          How to Get ApoB Tested
        </Paragraph>
        <Paragraph className="blog-description">
          If you're interested in having your ApoB levels tested, start by discussing it with your doctor. However, keep in mind that many doctors may not recommend ApoB testing as part of a standard cholesterol panel. Despite its strong predictive value, ApoB testing is not yet routine in traditional healthcare settings, largely because:
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>Testing Standards Lag Behind Research:</strong> It takes time for healthcare practices to catch up with new findings, and LDL has been the primary focus for years.
          </li>
          <li>
            <strong>Insurance Coverage:</strong> ApoB testing isn't always covered by insurance.
          </li>
          <li>
            <strong>Clinical Inertia:</strong> Many doctors stick to familiar cholesterol metrics and may not immediately recommend newer, lesser-known tests.
          </li>
        </ul>
        <Paragraph className="blog-description">
          If your doctor doesn't offer ApoB testing, Instalab makes it easy. We can <a onClick={() => {
            navigate('/heart-health-test')
            PosthogHelper.track(currentUser, `[blog] click ${blogId} cta: request test`, true)
          }} className="blog-link">send a blood test kit</a> to your home or <a onClick={() => {
            navigate('/longevity-panel')
            PosthogHelper.track(currentUser, `[blog] click ${blogId} cta: longevity panel`, true)
          }} className="blog-link">arrange an at-home blood draw</a> by a professional.
        </Paragraph>

        <Paragraph className="blog-title">
          Lifestyle Changes and Treatments
        </Paragraph>
        <Paragraph className="blog-description">
          If you find that your ApoB levels are high, the good news is that lifestyle changes and treatments can help bring them down. Here's a look at effective strategies:
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>Diet:</strong> A heart-healthy diet that limits saturated and trans fats, reduces processed sugars, and emphasizes whole grains, fruits, vegetables, and lean proteins can help lower ApoB levels. Adding fiber-rich foods can also improve cholesterol balance.
          </li>
          <li>
            <strong>Exercise:</strong> Regular physical activity—at least <i>150 minutes</i> of moderate-intensity exercise per week—supports cardiovascular health and helps lower ApoB. Combining aerobic exercise with strength training is especially beneficial.
          </li>
          <li>
            <strong>Weight Management:</strong> Maintaining a healthy weight, especially by reducing visceral fat, can reduce ApoB levels. Visceral fat is linked with higher ApoB levels, so managing it is key.
          </li>
          <li>
            <strong>Supplements:</strong> Natural supplements like <i>Berberine</i> and <i>Red Yeast Rice</i> have been shown to help lower ApoB levels, offering an over-the-counter alternative for those who prefer to avoid prescribed medication.
          </li>
          <li>
            <strong>Medications:</strong> For individuals who need extra support, medications like statins, ezetimibe, or PCSK9 inhibitors may be prescribed to lower ApoB levels. These drugs work by reducing cholesterol production or improving its clearance from the bloodstream, making them effective when lifestyle changes alone aren't enough.
          </li>
        </ul>

        <Paragraph className="blog-title">
          Reach Optimal ApoB Levels with Instalab
        </Paragraph>
        <Paragraph className="blog-description">
          For those who want a structured approach to reducing ApoB, Instalab offers a <a onClick={() => {
            navigate('/heart')
            PosthogHelper.track(currentUser, `[blog] click ${blogId} cta: heart health program`, true)
          }} className="blog-link">Heart Health Program</a> to support you every step of the way. An Instalab physician will work with you to create a personalized plan, monitor your progress, and help you reach optimal ApoB levels.
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>Personalized Coaching:</strong> Your Instalab physician will create a tailored plan with dietary recommendations, exercise guidance, and, if necessary, medication options suited to your specific health needs.
          </li>
          <li>
            <strong>Regular Retesting:</strong> Every six weeks, we retest your ApoB levels to ensure measurable progress and make adjustments as needed.
          </li>
          <li>
            <strong>Proven Results:</strong> Most patients reach their target ApoB levels within six months, with a clear plan to maintain these improvements long-term.
          </li>
        </ul>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Robinson, J. G., Wang, S., & Jacobson, T. A. (2012). Meta-analysis of the comparison of effectiveness of lowering apolipoprotein B versus low-density lipoprotein cholesterol and nonhigh-density lipoprotein cholesterol for cardiovascular risk reduction in randomized trials. American Journal of Cardiology, 110(10), 1468-1476. <a onClick={() => window.open('https://doi.org/10.1016/j.amjcard.2012.07.007', '_blank')}>https://doi.org/10.1016/j.amjcard.2012.07.007</a>
            </li>
            <li>
              Wilkins, J. T., Li, R. C., Sniderman, A., Chan, C., & Lloyd-Jones, D. M. (2016). Discordance between apolipoprotein B and LDL-cholesterol in young adults predicts coronary artery calcification: The CARDIA Study. Journal of the American College of Cardiology, 67(2), 193-201. <a onClick={() => window.open('https://doi.org/10.1016/j.jacc.2015.10.055', '_blank')}>https://doi.org/10.1016/j.jacc.2015.10.055</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};