const ProductDetailSection = Object.freeze({
    DIRECTIONS: "directions",
    BENEFITS: "benefits",
    FAQ: "faq",
    HERO: "hero",
    PANEL: "panel",
    TESTIMONIALS: "testimonials",
    XPANEL: "xpanel",
    TIMELINE: "timeline",
    SMALLPANEL: "smallpanel",
    XGRID: "xgrid"
})

export default ProductDetailSection