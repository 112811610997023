import { useEffect, useState } from 'react'
import { Form, Typography, Row, Col } from "antd"
import { Selector } from 'antd-mobile'
import { CheckOutlined, WarningFilled } from '@ant-design/icons'
import "./productSelect.scss"
import { FlowStepFooter } from '../../flowStep/flowStepFooter.component'
import _ from 'lodash'
import Breakpoint from '../../../../enums/breakpoint.enum'
import useWidth  from '../../../../hooks/useWidth.hook'


const { Item } = Form
const { Text, Paragraph } = Typography

export const ProductSelect = ({
  step,
  onSubmit,
  form,
  isSubmitting,
  onNextStep
}) => {
  const fieldWatch = Form.useWatch(step?.field.name, form)
  const [rules, setRules] = useState([])
  const letters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
  const width = useWidth()

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  useEffect(() => {
    fetchRules()
  }, [step])

  const fetchRules = () => {
    if (!step) return
    if (step.field.required) {
      setRules([{
        required: true,
        message: <><WarningFilled />&nbsp; Select at least one option</>,
      }])
    }
  }

  return (
    <div className="product-select">
      <Form
        form={form}
        className="product-select-form"
      >
        <Item 
          key={`field-${step.field.name}`}
          name={step.field.name}
          className="product-select-item"
          validateTrigger='onSubmit'
          rules={rules}
        >
          <Selector 
            onChange={value => {
              // Submit automatically if single select
              if (value?.length && !step.multiple) {
                onSubmit()
              }
            }}
            disabled={step.isLocked && step.isLocked()}
            multiple={step.multiple}
            options={step.field.options.map(({ 
              value,
              title,
              description,
              price,
              tag,
              disabled
            }, optionIndex) => {
              return {
                value,
                label: (
                  <div className="product-select-label">
                    <Row>
                      <Col className="col-1">
                        <div className="product-select-abbr">
                          {letters[optionIndex]}
                        </div>
                      </Col>
                      <Col className="col-2">
                        <Paragraph className="option-title">
                          {title} 
                          <CheckOutlined className="product-select-check" />
                        </Paragraph>
                        <div className="description-price-container">
                          <Paragraph 
                            className="option-description"
                            style={{
                              marginBottom: tag ? 25 : 10
                            }}
                          >
                            {description}
                          </Paragraph>
                          {(price || price === 0) && (
                            <Text className="option-price">
                              {isNaN(price) ? <>{price}</> : <>${price}</>}
                            </Text>
                          )}
                        </div>
                      </Col>
                    </Row>
                    
                    {tag && (
                      <Text className="option-tag">
                        {tag}
                      </Text>
                    )}
                  </div>
                ),
                disabled
              }
            })}
            className="product-select-field"
          />

        </Item>

        {/* {step?.hasSkip &&<><a onClick={() => onSubmit({[step.field.name]:[null]})}>Not interested</a></>} */}

        {(fieldWatch?.length > 0 || step.multiple) && (
          <FlowStepFooter 
            step={step}
            onSubmit={() => onSubmit()} 
            isSubmitting={isSubmitting}
            buttonText={step.buttonText}
            onNextStep={onNextStep}
            hasSkip={step.hasSkip}
          />
        )}
      </Form>
    </div>
  )
}
