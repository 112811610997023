import React from 'react'
import { Typography } from "antd";
import ReportHelper from "../../../helpers/report.helper";
import ReactDOM from "react-dom";
import "./lipidAdvice.scss";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import PatientHelper from "../../../helpers/patient.helper";
import { TestModal } from "../../testModal/testModal.component";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { NoteSection } from "../noteSection/noteSection.component";
import { ReportSection, ReportSectionStatus, Condition, MedicalCondition, QuestionTemplateCode, LowercaseTest, TestCode } from "../../../enums/index.enum";
import { CardioGeneticBox } from "./cardioGeneticBox.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const converter = require("number-to-words");
const { Paragraph } = Typography;

const metabolicConditions = [
  Condition.PRE_DIABETES,
  Condition.INSULIN_RESISTANCE,
  Condition.DIABETES,
  Condition.METABOLIC_SYNDROME,
  Condition.INSULIN_RESISTANCE_MAYBE
];

const cardioMetabolicConditions = [
  Condition.ELEVATED_TRIGYLCERIDES,
  Condition.HYPERTRIGLYCERIDEMIA,
  Condition.HYPERCHOLESTEROLEMIA,
  Condition.SUBOPTIMAL_CHOLESTEROL,
  Condition.SUBPEAK_CHOLESTEROL
];

const seriousConditions = [
  Condition.MODERATE_10_YEAR_CVD,
  Condition.HIGH_RISK_10_YEAR_CVD,
  Condition.VERY_HIGH_RISK_10_YEAR_CVD,
  Condition.HYPERTRIGLYCERIDEMIA,
  Condition.HYPERCHOLESTEROLEMIA,
];

const cardioEvents = [
  MedicalCondition.HEART_DISEASE,
  MedicalCondition.HEART_ATTACK,
  MedicalCondition.BLOCKED_ARTERY,
  MedicalCondition.STROKE,
];

const primaryCodes = [
  TestCode.APO_B,
  TestCode.HS_CRP,
  TestCode.DIRECT_LDL,
  TestCode.HDL,
  TestCode.VLDL,
  TestCode.TG,
  TestCode.CHOLESTEROL_BALANCE_SCORE,
  TestCode.LATHOSTEROL,
  TestCode.DESMOSTEROL,
  TestCode.SITOSTEROL,
  TestCode.CAMPESTEROL,
  TestCode.NMR_LDL_P,
  TestCode.NMR_HDL_P,
  TestCode.NMR_SMALL_LDL_P,
  TestCode.NMR_HDL_P,
  TestCode.NMR_LDL_P,
  TestCode.NMR_LDL_SIZE,
  TestCode.NMR_SMALL_LDL_P,
  TestCode.NMR_LARGE_VLDL_P,
  TestCode.NMR_LARGE_HDL_P,
  TestCode.NMR_VLDL_SIZE,
  TestCode.LDL_PARTICLE_NUMBER,
  TestCode.LDL_PATTERN,
  TestCode.LDL_PEAK_SIZE,
  TestCode.LDL_MEDIUM,
  TestCode.LDL_SMALL,
];

export const LipidAdvice = ({ report, section, setSectionMeta, reports, results, tests, user }) => {
  const [recs, setRecs] = useState()

  // progress
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();
  const [isStatic, setIsStatic] = useState();
  const [isDiscordant, setIsDiscordant] = useState();

  // tests
  const [lipidTests, setLipidTests] = useState();
  const [primaryTests, setPrimaryTests] = useState();
  const [improvedTests, setImprovedTests] = useState();
  const [worseTests, setWorseTests] = useState();
  const [staticTests, setStaticTests] = useState();
  const [suboptimalTests, setSuboptimalTests] = useState();
  const [newSuboptimalTests, setNewSuboptimalTests] = useState();

  // helpers
  const [hasSeriousCondition, setHasSeriousCondition] = useState();
  const [hasCardioHistory, setHasCardioHistory] = useState();
  const [hasHighCVDRisk, setHasHighCVDRisk] = useState();
  const [hasHighLPA, setHasHighLPA] = useState();
  const [familyHistory, setFamilyHistory] = useState();
  const [hasFH, setHasFH] = useState();
  const [testId, setTestId] = useState();
  const [openModal, setOpenModal] = useState();
  const [apoE, setApoE] = useState();
  const [hasCardioMetabolicIssue, setHasCardioMetabolicIssue] = useState();
  const [hasHighCAC, setHasHighCAC] = useState();

  // content
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [followupDescription, setFollowupDescription] = useState();
  const [secondaryDescription, setSecondaryDescription] = useState();

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          secondaryDescription: renderToString(secondaryDescription),
          followupDescription: renderToString(followupDescription),
          recs
        }
      }
    })
  }, [title, description, secondaryDescription, followupDescription, recs])

  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.LIPID_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.LIPID_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.LIPID_ADVICE));
    setIsStatic(ReportHelper.isStatic(report, ReportSection.LIPID_ADVICE));
  }, [report]);

  // tests
  useEffect(() => {
    setLipidTests(getLipidTests());
    setHasCardioMetabolicIssue(getHasCardioMetabolicIssue());
  }, [user, report, tests]);

  useEffect(() => {
    setPrimaryTests(getPrimaryTests());
  }, [lipidTests]);

  useEffect(() => {
    categorizeTests()
  }, [report, tests, primaryTests, lipidTests]);

  useEffect(() => {
    setHasSeriousCondition(getHasSeriousCondition());
    setHasCardioHistory(getHasCardioHistory());
    setHasHighCVDRisk(getHasHighCVDRisk());
    setHasHighLPA(getHasHighLPA());
    setFamilyHistory(getFamilyHistory());
    setHasFH(getHasFH());
    setHasHighCAC(getHasHighCAC())
  }, [user, report]);

  useEffect(() => {
    setIsDiscordant(getIsDiscordant());
  }, [report, worseTests, improvedTests, hasHighCVDRisk])

  useEffect(() => {
    replaceFirstCardioGeneticBox()
  }, [description, report, apoE, hasHighLPA, hasHighCAC, tests, familyHistory])

  useEffect(() => {
    replaceFollowupCardioGeneticBox()
  }, [followupDescription, report, apoE, hasHighLPA, hasHighCAC, tests, familyHistory])

  useEffect(() => {
    replaceTestViewWorseTests()
  }, [description, followupDescription, report, worseTests, results, reports, user])

  useEffect(() => {
    replaceTestViewStaticTests()
  }, [description, followupDescription, report, staticTests, results, reports, user])

  useEffect(() => {
    replaceTestViewImprovedTests()
  }, [description, followupDescription, report, improvedTests, results, reports, user])

  useEffect(() => {
    replaceTestViewTests()
  }, [description, followupDescription, report, staticTests, results, reports, user])

  useEffect(() => {
    replaceTestViewNewSuboptimalTests()
  }, [description, followupDescription, report, newSuboptimalTests, results, reports, user])

  useEffect(() => {
    replaceDiscordant()
  }, [description, followupDescription])


  const getLipidTests = () => {
    if (!report || !tests || !user) return null;
    const lipidTests = ReportHelper.getSectionTests(report, ReportSection.LIPID_ADVICE, tests);
    if (PatientHelper.isAthlete(user)) return lipidTests.filter(({code}) => code !== TestCode.HS_CRP);
    return lipidTests;
  };


  const getPrimaryTests = () => {
    if (!lipidTests) return null;
    return lipidTests.filter(({ code }) => primaryCodes.includes(code));
  };


  const replaceDiscordant = () => {
    const target = document.getElementById('discordant')
    if (!target || report === undefined) return
    ReactDOM.render(
      <Discordant />,
      target
    );
  }

  const replaceFirstCardioGeneticBox = () => {
    const target = document.getElementById('first-cardio-genetic-box')
    if (!target || report === undefined || !tests) return
    ReactDOM.render(
      <CardioGeneticBox 
        report={report}
        apoE={apoE}
        hasHighLPA={hasHighLPA}
        hasHighCAC={hasHighCAC}
        tests={tests} 
        results={results}
        reports={reports}
        familyHistory={familyHistory}
        isFollowup={false}
      />,
      target
    );
  }
  
  const replaceFollowupCardioGeneticBox = () => {
    const target = document.getElementById('followup-cardio-genetic-box')
    if (!target || report === undefined || !tests) return
    ReactDOM.render(
      <CardioGeneticBox 
        report={report}
        apoE={apoE}
        hasHighLPA={hasHighLPA}
        hasHighCAC={hasHighCAC}
        tests={tests} 
        results={results}
        familyHistory={familyHistory}
        isFollowup={true}
        reports={reports}
      />,
      target
    );
  }

  const replaceTestViewTests = () => {
    const target = document.getElementById('test-view-tests')
    if (!target || report === undefined || !staticTests) return
    ReactDOM.render(
      <TestView
        user={user} 
        report={report} 
        tests={staticTests}
        results={results}
        reports={reports}
      />,
      target
    );
  }

  const replaceTestViewWorseTests = () => {
    const target = document.getElementById('test-view-worse-tests')
    if (!target || report === undefined || !worseTests) return
    ReactDOM.render(
      <TestView
        user={user} 
        report={report} 
        tests={worseTests}
        results={results}
        reports={reports}
      />,
      target
    );
  }

  const replaceTestViewNewSuboptimalTests = () => {
    const target = document.getElementById('test-view-newsuboptimal-tests')
    if (!target || report === undefined || !newSuboptimalTests) return
    ReactDOM.render(
      <TestView
        user={user} 
        report={report} 
        tests={newSuboptimalTests}
        results={results}
        reports={reports}
      />,
      target
    );
  }

  const replaceTestViewStaticTests = () => {
    const target = document.getElementById('test-view-static-tests')
    if (!target || report === undefined || !staticTests) return
    ReactDOM.render(
      <TestView
        user={user} 
        report={report} 
        tests={staticTests}
        results={results}
        reports={reports}
      />,
      target
    );
  }

  const replaceTestViewImprovedTests = () => {
    const target = document.getElementById('test-view-improved-tests')
    if (!target || report === undefined || !improvedTests) return
    ReactDOM.render(
      <TestView
        user={user} 
        report={report} 
        tests={improvedTests}
        results={results}
        reports={reports}
      />,
      target
    );
  }

  useEffect(() => {
    const t =  getTitle()
    setTitle(report?.isLocked ? parse(section.meta.title) : t);
  }, [user, hasCardioMetabolicIssue, report, hasFH, isBetter, isFixed, isWorse, isStatic, hasHighLPA]);


  useEffect(() => {
    const d = PatientHelper.isAthlete(user) ? getAthleteDescription() : getDescription()
    setDescription(report?.isLocked ? parse(section.meta.description) : d);
  }, [user, apoE, report, primaryTests, familyHistory, hasSeriousCondition, hasHighCVDRisk]);

  useEffect(() => {
    const s = PatientHelper.isAthlete(user) ? getAthleteSecondaryDescription() : getSecondaryDescription()
    setSecondaryDescription(report?.isLocked ? parse(section.meta.secondaryDescription) : s);
  }, [user, report, hasCardioMetabolicIssue, hasHighCVDRisk]);

  useEffect(() => {
    setFollowupDescription(getFollowupDescription());
  }, [report, primaryTests, improvedTests, staticTests, worseTests, isBetter, isWorse, isFixed, isStatic, hasHighLPA, isDiscordant]);

  useEffect(() => {
    setApoE(getApoE());
  }, [lipidTests, results, tests, report]);

  const categorizeTests = () => {
    if (!report || !tests || !primaryTests || !lipidTests ) return null;

    const iTests = ReportHelper.getSectionImprovedTests(report, ReportSection.LIPID_ADVICE, tests) || [];
    setImprovedTests(iTests?.filter(({code})=>primaryCodes.includes(code)))

    setSuboptimalTests(ReportHelper.getSectionSuboptimalTests(report, ReportSection.LIPID_ADVICE, tests)|| null)

    const wTests = ReportHelper.getSectionWorseTests(report, ReportSection.LIPID_ADVICE, tests) || []
    if (PatientHelper.isAthlete(user))
      setWorseTests(wTests?.filter(({code})=>primaryCodes.includes(code) && code!== TestCode.HS_CRP));
    else
      setWorseTests(wTests?.filter(({code})=>primaryCodes.includes(code)));

    const sTests = ReportHelper.getSectionStaticTests(report, ReportSection.LIPID_ADVICE, tests)|| []
    setStaticTests(sTests?.filter(({code})=>primaryCodes.includes(code)))

    // what are new tests that were run, e.g. omega 3? 
    const suboptimalTests = ReportHelper.getSectionSuboptimalTests(report,  ReportSection.LIPID_ADVICE, tests ) || null;
    const oldCodes = new Set([...sTests, ...wTests,...iTests].map(test => test.code));

    setNewSuboptimalTests(suboptimalTests?.filter(test => !oldCodes.has(test.code) && primaryCodes.includes(test.code)))    
  };

  const getHasCardioMetabolicIssue = () => {
    if (!report) return null;
    const { result } = report;
    if (!result.conditions?.some((code) => cardioMetabolicConditions.includes(code))) return false;
    return result.conditions?.some((code)=> metabolicConditions.includes(code));
  };

  const getIsDiscordant = () => {
    if (!report || !improvedTests || !worseTests) return null;

    //need to fix - need to know if insulin resistance is improving
    const metabolicSectionStatus = report?.sections.find(section => section.code===ReportSection.METABOLIC_ADVICE)?.status
    const insulinResistanceImproving = [ReportSectionStatus.FIXED, ReportSectionStatus.IMPROVED].includes(metabolicSectionStatus)
    return  insulinResistanceImproving && improvedTests.find((test) => test.code === TestCode.APO_B) && worseTests.find((test)=> test.code === TestCode.DIRECT_LDL)
  }

  const Discordant = () => {
    return (
      <div className="discordant-section">
      <NoteSection
        title = "Understanding Your ApoB and LDL Cholesterol Discordance"
        summary = "ApoB and LDL cholesterol levels typically move in tandem, but in your case, they exhibit a divergence ..."
        content ={<>
          <Paragraph>ApoB and LDL cholesterol levels typically move in tandem, but in your case, they exhibit a divergence.</Paragraph>
          <Paragraph>ApoB levels give a more accurate view of your cardiovascular risk: the number of harmful (atherogenic) particles has decreased, a positive development.</Paragraph> 
          <Paragraph>Conversely, the cholesterol content carried by these particles (LDL) has seen a slight increase. With improving insulin resistance, the cholesterol-carrying particles have grown larger and less numerous, signifying progress (lower ApoB). But the cholesterol previously transported by more common VLDL particles is now being carried by LDL particles. This shift is a natural part of your body's response to insulin resistance improvement.</Paragraph>
        </>}
      />
    </div>
    )
  }

  const getTitle = () => {
    if (!report) return null;
    
    if (isFixed && hasHighLPA ) {
      return (
        <>
          Your <span>lipid profile</span> has <span>improved</span>{" "}
          since your last blood draw.
        </>
      );
    }
    else if (isFixed && !hasHighLPA && suboptimalTests?.length===0) {
      return (
        <>
          Your <span>lipid profile</span> is now <span>healthy</span>. Congratulations on this milestone!
        </>
      );
    }
    else if (isBetter || isFixed) {
      return (
        <>
          Your <span>lipid profile</span> has <span>improved</span>{" "}
          since your last blood draw.
        </>
      );      
    } 
    else if (isStatic) {
      return (
        <>
          Your <span>lipid profile</span> remains relatively <span>unchanged</span>{" "}
          since your last blood draw.
        </>
      );      
    }     
    else if (isWorse) {
      return (
        <>
          Your <span>lipid profile</span> has <span>worsened</span>{" "}
          since your last blood draw.
        </>
      );
    } 
    else if (ReportHelper.getSectionIndex(report, ReportSection.LIPID_ADVICE)<=1 && !PatientHelper.isAthlete(user))
      return (
        <>
          Let's focus on the area where you can make the most progress:{" "}
          <span>your lipid profile</span>.
        </>
      );
    else if (ReportHelper.getSectionIndex(report, ReportSection.LIPID_ADVICE) > ReportHelper.getSectionIndex(report, ReportSection.METABOLIC__ADVICE) && ReportHelper.getSectionIndex(report, ReportSection.METABOLIC_ADVICE)) {
        return (
          <>
            Your <span>lipid profile</span> is {hasHighCVDRisk ? <>at <span>high risk</span></> : <span>not optimal</span>},
            which may stem from issues with your metabolism{hasFH && <> and FH</>}{hasCardioHistory && <> and previous cardiac events</>}.
          </>
        );
      } 

    else {
      return (
        <>
          Your <span>lipid profile</span> is <span>not optimal</span> and improving it will lower your long-term risk of heart attack and stroke.
        </>
      );
    }
    
  };

  const getString = (familyHistory) => {
    return familyHistory.map((condition, index) => {
      return ` ${
        familyHistory.length > 1 && index === familyHistory.length - 1
          ? " and "
          : ""
      }${condition.toLowerCase()}${
        index < familyHistory.length - 1 && familyHistory.length > 2
          ? ", "
          : ""
      }`;
    })
  }

  const getDescription = () => {
    if (!report || !primaryTests) return null;

    const {result} = report 
    const allMedicalConditions = ReportHelper.getAnswer(report, QuestionTemplateCode.ALL_MEDICAL_CONDITIONS)
    const events = cardioEvents.filter(condition => allMedicalConditions?.includes(condition))

    let content = (
      <>
       Lipids, such as cholesterol and fats, are essential for various biological functions, but dyslipidemia — imbalances in these levels — can accelerate plaque formation in your coronary arteries, significantly elevating your risk for atherosclerotic cardiovascular disease (ASCVD), including heart attack and stroke.

        {/* {hasCardioHistory 
                  ? <> Considering your history of {getString(events)}, you face a signifcant risk of another major cardiovascular event in the next decade.{" "}  </> 
                  : <> Neglecting it can lead to serious conditions like heart attack and stroke.</>} */}
      </>
    )

    // GENETIC RISK BOX
    if (apoE || hasHighLPA || hasHighCAC) {
      content = <>{content} 
        <div id="first-cardio-genetic-box"/>
      </>
    }
    else  {
      content = <>{content}<br/><br/></>
    }

    // HAS HIGH CVD RISK
    if (hasHighCVDRisk) {

      if (hasCardioHistory) {

        // const allMedicalConditions = ReportHelper.getAnswer(report, QuestionTemplateCode.ALL_MEDICAL_CONDITIONS)
        // const events = cardioEvents.∂filter(condition => allMedicalConditions.includes(condition))

        content = <>{content}{" "} 
        {/* Considering your history of {getString(events)}, you face a signifcant risk of another major cardiovascular event in the next decade.{" "}   */}
        {primaryTests.length === 1 ? "This biomarker indicates" : `These ${converter.toWords(primaryTests.length)} biomarkers indicate`}{" "} a need for improvement in your cardiovascular health to prevent another event.
        </>
      }

      else {
        content = <>{content}{" "}
          {primaryTests.length === 1 ? "This biomarker shows" : `These ${converter.toWords(primaryTests.length)} biomarkers show`}{" "}that you cardiovascular health requires improvement.
          Without some substantial changes, you {result?.conditions?.includes(Condition.MODERATE_10_YEAR_CVD) ? "are at " : " have a high"} risk of a major cardiovascular event in the next decade. Let's get started now on preventing this. 
        </>
      }
    }

    else {
      content = <>{content}{" "}
        {primaryTests.length === 1 ? "This biomarker shows" : `These ${converter.toWords(primaryTests.length)} biomarkers show`}{" "} that your lipid profile{" "}{hasSeriousCondition ? `really needs improvement.` : "is okay, but has room to improve."}

        {(hasHighLPA || apoE) && ` Especially in light of your genetic risks, we recommend you start addressing this now.`}
        {!(hasHighLPA || apoE) && familyHistory?.length > 0 && (
          <>
            &nbsp;In light of your{hasFH && " FH condition and"} {hasCardioHistory && " previous cardiac events and"} family's
            history of{" "}{getString(familyHistory)}, we recommend you start addressing this now.
          </>
        )} 
      </>
    }

    return content
  };

  const getSecondaryDescription = () => {
    if (!report && !user) return null;

    if (hasHighCVDRisk) {
      return null;
    }

    if (isFixed || isBetter) {
      return `Stick to the diet changes suggested in your game plan, so your lipid health continues to improve by your next blood test.`;
    } 
    else if (hasCardioMetabolicIssue && (ReportHelper.getSectionIndex(report, ReportSection.LIPID_ADVICE)>ReportHelper.getSectionIndex(report, ReportSection.METABOLIC_ADVICE))) {
      return `As you improve your metabolic health, you can expect your lipids biomarkers to improve. Moreover, making simple changes to your diet can further decrease your cardiovascular risk.`;
    } 
    else if (hasSeriousCondition) {
      return <> The good news is that you are paying attention to this now,{" "}
      {PatientHelper.getAge(user) <= 40 && "while you're young and "}{" "}
      before it has progressed to a more serious condition. With focused effort, you can significantly decrease your risk fairly quickly.</>
    }
    else {
      return (
        <>
          The good news is that you're catching this early,{" "}
          {PatientHelper.getAge(user) <= 40 && "while you're young and "}{" "}
          before it's progressed to a more serious condition. You can significantly decrease your risk now.
        </>
      );
    }
  };

  const getFollowupDescription = () => {
    if (report?.lockedAt) return parse(section.meta.followupDescription)

    if (!report || !(isFixed || isBetter || isWorse || isStatic) || !(improvedTests || staticTests || worseTests)) return null;   
    
    const staticAndWorseTests = [...staticTests, ...worseTests]

    let content = (
      <>
        Lipids, such as cholesterol and fats, are essential for various biological functions, but dyslipidemia — imbalances in these levels — can accelerate plaque formation in your coronary arteries, significantly elevating your risk for atherosclerotic cardiovascular disease (ASCVD), including heart attack and stroke.

        {( apoE || hasHighLPA || hasHighCAC) ? <div id="followup-cardio-genetic-box"/> : <><br/><br/></>
        }
      </>
    );

    if (isStatic) {
      
      if (improvedTests?.length>0) {
        content = (
            <>
              {content}
                    
              {improvedTests?.length === 1
                ? `On a positive note, your ${LowercaseTest[improvedTests[0].code]}, an indepedent risk factor, has improved.`
                : `On a positive note, these ${converter.toWords(improvedTests?.length)} independent risk factors for cardiovascular disease have improved.`} 
              
              <div id="test-view-improved-tests" />
            </> 
        );
      }
    
      if (staticAndWorseTests?.length) {

        content = (
          <>
          {content}

          {worseTests?.length>0 && <>
              {worseTests?.length === 1
              ? `Unfortunately, your ${LowercaseTest[worseTests[0].code]} levels is `
              : `Unfortunately, these ${converter.toWords(worseTests?.length)} biomarkers are `
              }
              moving in the wrong direction. This development in particular requires your attention and action.

              <div id="test-view-worse-tests" />
          </>}
        
          {staticTests?.length>0 && <>
            {staticTests?.length === 1
                ? <>{worseTests?.length>0 ? `Moreover` : `On the other hand`}, your {LowercaseTest[staticTests[0].code]} levels </>
                : <>{improvedTests?.length === 0 ? `These` : worseTests?.length>0 ? `Moreover, these` : `On the other hand, these`} {converter.toWords(staticTests?.length)} biomarkers </>}
              have not moved in clinically significant manner. This{worseTests?.length>0 && `, while not as critical, still `} requires careful monitoring and intervention for optimal cardiovascular health.

              <div id="test-view-tests" />
            </>
          }

          {newSuboptimalTests?.length>0 && <>
            {newSuboptimalTests?.length === 1
                ? <>{newSuboptimalTests?.length>0 ? `Also` : `On the other hand`}, your {LowercaseTest[newSuboptimalTests[0].code]} levels </>
                : <>{newSuboptimalTests?.length>0 ? `Also` : `On the other hand`}, these {converter.toWords(newSuboptimalTests?.length)} new biomarkers </>}
              are suboptimal.

            <div id="test-view-newsuboptimal-tests" />
          </>
          }

          Stick to your game plan to help make sure your overall cardiovascular health starts moving in the right direction for your next blood draw.
          </>
        )
      }

      else if (isStatic) {
        content = <>{content} But not enough to make to decrease your risk for cardiovascular events. Stick to your game plan so that your cardiovascular health significantly improves for your next blood draw.
        </>
      }
    
      else if (isBetter || isFixed)  {

        content = <>{content} Stick to your game plan so that your cardiovascular health continues improving for your next blood draw.
        </>

      }

      if (isDiscordant) {
        content = <>{content} <div id="discordant" /></>
      }

    } 

    else if ((isBetter || isFixed) && improvedTests?.length) {

      content = (
          <>
          {content} Great job on making progress in this area! {" "}
            {improvedTests?.length === 1
              ? `Your ${LowercaseTest[improvedTests[0].code]} levels have `
              : `These ${converter.toWords(improvedTests?.length)} biomarkers have `}{" "}
            gotten better, lowering your risk for heart attack and stroke.

            <div id="test-view-improved-tests" />
          </>
      );

      if (staticAndWorseTests?.length) {
        content = (
          <>
          {content}
        
          {staticTests?.length>0 && <>
            {staticTests?.length === 1
                ? <>That said, your {LowercaseTest[staticTests[0].code]} levels </>
                : <>That said, these {converter.toWords(staticTests?.length)} biomarkers </>}
            have not moved in a clinically significant manner. 
            <div id="test-view-static-tests" />
            </>
          }

          {worseTests?.length>0 && <>
              {worseTests?.length === 1
              ? <>{staticTests?.length===0 ? <>That said, </> : <>And </>} your {LowercaseTest[worseTests[0].code]}, an independent risk factor for heart disease, is </>
              : <>{staticTests?.length===0 ? <>That said, </> : <>And </>} these {converter.toWords(worseTests?.length)} biomarkers are </>
              }
              not moving in the right direction. 
              <div id="test-view-worse-tests" />
          </>}

          {newSuboptimalTests?.length>0 && <>
            {newSuboptimalTests?.length === 1
                ? <>{newSuboptimalTests?.length>0 ? `Also` : `On the other hand`}, your {LowercaseTest[newSuboptimalTests[0].code]} levels </>
                : <>{newSuboptimalTests?.length>0 ? `Also` : `On the other hand`}, these new {converter.toWords(newSuboptimalTests?.length)} biomarkers </>}
              are suboptimal.

            <div id="test-view-newsuboptimal-tests" />
          </>
          }

          It's important to closely monitor {staticAndWorseTests?.length > 1 ? "these levels" : "this"} despite the positive changes in your overall cardiovacular health. Stick to your game plan to help make sure your cardiovascular health continues moving in the right direction.
          </>
        )
      }
    
      else if (isBetter || isFixed)  {

        content = <>{content} Stick to your game plan so that your lipid profile and thus cardiovascular health continues improving for your next blood draw.
        </>

      }

      if (isDiscordant) {
        content = <>{content} <div id="discordant" /></>
      }

    } 

    else if (isWorse) {

      content = (
        <>
          {content}

          {worseTests && <> {worseTests?.length === 1
              ? `Unfortunately, your ${LowercaseTest[worseTests[0].code]} levels have `
              : `Unfortunately, these ${converter.toWords(worseTests?.length)} biomarkers have `}{" "}
            gotten worse, increasing your risk for cardiovascular disease. This development requires your attention and action.

          <div id="test-view-worse-tests" />
        
          {staticTests?.length>0 && <>
          {staticTests?.length === 1
              ? `Moreover, your ${LowercaseTest[staticTests[0].code]} levels `
              : `Moreover, these ${converter.toWords(staticTests?.length)} biomarkers `}
            have experienced a slight shift, though not enough to be considered clinically significant. This, while not as critical, still requires careful monitoring and intervention for optimal cardiovascular health.

            <div id="test-view-static-tests" />
          </>} 
          </>}


          {newSuboptimalTests?.length>0 && <>
            {newSuboptimalTests?.length === 1
                ? <>{newSuboptimalTests?.length>0 ? `Also` : `On the other hand`}, your {LowercaseTest[newSuboptimalTests[0].code]} levels </>
                : <>{newSuboptimalTests?.length>0 ? `Also` : `On the other hand`}, these new {converter.toWords(newSuboptimalTests?.length)} biomarkers </>}
              are suboptimal.

            <div id="test-view-newsuboptimal-tests" /></>}

          {improvedTests?.length > 0 && 
          <>
          On the positive side, 
          {improvedTests?.length === 1
            ? ` your ${LowercaseTest[improvedTests[0].code]} has`
            : ` these ${converter.toWords(
                improvedTests?.length
              )} biomarkers have `}{" "}
          shown improvement.
          <div id="test-view-improved-tests" />
          </>
          }

          Let's really focus on following your below game plan to help make sure your cardiovascular health moves back in the right direction for your next blood draw.
        </>
      );
    }

    else if (isWorse && worseTests?.length) {
      content = (
        <>
        {content}<br/><br/>     
          {worseTests?.length === 1
              ? `Your ${LowercaseTest[worseTests[0].code]} has `
              : `These ${converter.toWords(
                  worseTests?.length
                )} biomarkers have `}{" "}
            not really moved significantly or have gotten worse, increasing your risk for heart attack and stroke.

          <div id="test-view-worse-tests" />
          Stick to your game plan to help make sure your cardiovascular health moves back in the right direction for your next blood draw.
        </>
      );
    }


    return content;
  }

  const getApoE = () => {
    if (!results || !tests || !report) return null;
    if (!lipidTests?.some(({ code }) => code === TestCode.APO_E)) return null;
    return ReportHelper.getTestValue(TestCode.APO_E, report, results, tests);
  };

  const getHasFH = () => {
    if (!report) return null;
    const currentMedicalConditions = ReportHelper.getAnswer(report, QuestionTemplateCode.CURRENT_MEDICAL_CONDITIONS)
    return currentMedicalConditions?.includes(MedicalCondition.FAMILIAL_HYPERCHOLESTEROLEMIA);

  };

  const getHasHighCAC = () => {
    if (!report) return null;
    const { result } = report
    return result?.conditions?.includes(Condition.HIGH_CAC);
  };

  const getHasCardioHistory = () => {
    if (!report) return null;
    const allMedicalConditions = ReportHelper.getAnswer(report, QuestionTemplateCode.ALL_MEDICAL_CONDITIONS)
    //return cardioEvents.filter(condition => allMedicalConditions.includes(condition))
    return cardioEvents.some((condition) => allMedicalConditions?.includes(condition));
  };

  const getHasSeriousCondition = () => {
    if (!report) return null;
    const { result } = report
    return seriousConditions.some((condition) =>
      result?.conditions?.includes(condition)
    );
  };

  const getHasHighCVDRisk = () => {
    if (!report) return null;
    const { result } = report
    return [
      // Condition.MODERATE_10_YEAR_CVD,
      Condition.HIGH_RISK_10_YEAR_CVD,
      Condition.VERY_HIGH_RISK_10_YEAR_CVD,
    ].some((condition) => result?.conditions?.includes(condition));
  };

  const getHasHighLPA = () => {
    if (!report) return null;
    const { result } = report
    return result?.conditions?.includes(Condition.HIGH_LPA);
  };

  const getFamilyHistory = () => {
    if (!user) return null;
    return user.familyHistory?.filter((condition) =>
      cardioEvents.includes(condition)
    );
  };

  const getAthleteDescription = () => {
    if (!report || !primaryTests) return null;
    return <>
    As an athlete, your risk for cardiovascular disease is lower than average. However, based on your results, there is still room for improvement to further reduce your risk of heart attack and stroke in the long term.
    <br/><br/>    

    You already understand the importance of a healthy cardiovascular system for optimal performance. By focusing on improving these {converter.toWords(primaryTests?.length)} biomarkers, you can better maintain your heart health as you age. 
    </>
  }

  const getAthleteSecondaryDescription = () => {
    if (!report) return null;
    return <>The good news is that you're paying attention to this now {PatientHelper.getAge(user) <= 40 && "while you're young "} and determining how much of a factor it plays in your cardiovascular health. With focused effort, you can significantly decrease any risk in a relatively short period of time.</>
  }


  return (
    user &&
    report &&
    primaryTests && (
      <div className="lipid-advice">
        <TestModal
          open={openModal}
          setOpen={setOpenModal}
          testId={testId}
          setTestId={setTestId}
          report={report}
          tests={tests}
          results={results}
          reports={reports}
        />

        <Paragraph className="report-template-title">{title}</Paragraph>

        {isStatic || ((isBetter || isFixed) && improvedTests?.length) || (isWorse && worseTests?.length)?
          <Paragraph className="report-template-description">{followupDescription}</Paragraph>
        : 
        <>
          <Paragraph className="report-template-description">
            {description}
          </Paragraph>          
          
          <TestView
            user={user} 
            report={report} 
            tests={primaryTests}
            results={results}
            reports={reports}
          />
          
          <Paragraph className="report-template-description">
            {secondaryDescription}
          </Paragraph>


        </>
        }

        <AdviceSection 
          report={report} 
          section={section}
          results={results}
          tests={tests}
          setTestId={setTestId}
          setOpenModal={setOpenModal}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};
