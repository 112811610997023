import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import { TestCode, ReportSection, Injury, QuestionTemplateCode, RiskLevel, LowercaseTest } from "../../../enums/index.enum";
import { Reference } from "../reference/reference.component";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { TestView } from "../testView/testView.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import "./strengthAdvice.scss";

const { Paragraph } = Typography;
const converter = require("number-to-words");


export const StrengthAdvice = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [strengthTests, setStrengthTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const isFixed = ReportHelper.isFixed(report, ReportSection.STRENGTH_ADVICE);
  const isBetter = ReportHelper.isBetter(report, ReportSection.STRENGTH_ADVICE);
  const isWorse = ReportHelper.isWorse(report, ReportSection.STRENGTH_ADVICE);
  const isStatic = ReportHelper.isStatic(report, ReportSection.STRENGTH_ADVICE);

  useEffect(() => {
    setStrengthTests(getStrengthTests());
  }, [report, tests, isFixed]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [user, report, strengthTests, isFixed, isBetter, isWorse, isStatic]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])


  const getStrengthTests = () => {
    if (!report || !tests) return null;

    const sTests = ReportHelper.getSectionTests(report, ReportSection.STRENGTH_ADVICE, tests);
    const injuries = ReportHelper.getAnswer(report, QuestionTemplateCode.RECENT_INJURY)

    const filteredTests = sTests.filter((test) => {
      if (injuries?.includes(Injury.RIGHT_HAND)) {
        // Exclude right-hand grip tests if the right hand is injured
        return test.code !== TestCode.GRIP_RIGHT && test.code !== TestCode.GRIP;
      }
    
      if (injuries?.includes(Injury.LEFT_HAND)) {
        // Exclude left-hand grip tests if the left hand is injured
        return test.code !== TestCode.GRIP_LEFT && test.code !== TestCode.GRIP;
      }
    
      // If no relevant injuries, include the result by default
      return true;
    });

    // Check if 'GRIP' is in the filtered tests and filter out 'GRIP_RIGHT' and 'GRIP_LEFT'
    const finalFilteredTests = filteredTests.filter((test) => {
      const containsGrip = filteredTests.some((t) => t.code === TestCode.GRIP);
      // If 'GRIP' is present, exclude 'GRIP_RIGHT' and 'GRIP_LEFT'
      if (containsGrip) {
        return test.code !== TestCode.GRIP_RIGHT && test.code !== TestCode.GRIP_LEFT;
      }

      return true; // Otherwise, include all tests
    });

        
    return finalFilteredTests
  };
  

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!user || !report || !strengthTests) return null;

    if (isFixed && ReportHelper.getSectionSuboptimalTests(report, ReportSection.STRENGTH_ADVICE, tests)?.length===0) {
      return (
        <>
          Your <span>grip strength</span> is now{" "}
          <span>optimal</span>, which decreases your risk
          of age-related muscle loss, dementia, and cardiovascular disease.
        </>
      );
  
    }

    else if (isBetter || (isFixed &&  ReportHelper.getSectionSuboptimalTests(report, ReportSection.STRENGTH_ADVICE, tests)?.length>0)) {
      return (
        <>
          Your <span>grip strength</span> is{" "}<span>improving</span>, which decreases your risk
        for age-related muscle loss, dementia, and cardiovascular disease.
        </>
      );
  
    }

    if (isStatic) {
      return (
        <>
          Your <span>grip strength</span> is <span> {ReportHelper.getRiskEval(TestCode.GRIP, report, results, tests)}</span>, which increases your risk
        of age-related muscle loss, dementia, and cardiovascular disease.
        </>
      );
  
    }

    if (ReportHelper.getSectionIndex(report, ReportSection.STRENGTH_ADVICE)===1)
      return (
        <>
          Let's focus on the area where you can make the most progress:{" "}
          <span>your strength</span>.
        </>
      );

      return (
        <>
          {strengthTests?.length === 1 ? (
            <>Your <span>{LowercaseTest[strengthTests[0].code]}</span> indicates </>
          ) : (
            <>These <span>{converter.toWords(strengthTests?.length)} biomarkers</span> indicate </>
          )}{" "}
          suboptimal <span>muscle health</span>, which can increase your risk of age-related muscle loss, dementia, and cardiovascular disease.
        </>
      );
  };


  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!user || !report) return null;

    const strengthRisk = ReportHelper.getTestRisk(TestCode.GRIP, report, results, tests);
    const strengthTrains = ReportHelper.getAnswer(report, QuestionTemplateCode.STRENGTH)
    
    return (
      <>
        Maintaining strong muscles is essential for balance, mobility, and overall functionality as you age, and it plays a key role in reducing the risk of age-related diseases.
        <br />
        <br />
        {isFixed && (
          <>
            Great job on improving your strength! Stick to your game plan so your overall muscle strength continues to improve and keep your grip strength at optimal levels.
          </>
        )}
    
        {!isFixed && strengthTests?.length === 1 && (
          <>
            Improving grip strength goes beyond enhancing hand strength;{" "}
            <Reference
              content="it's one indicator of overall muscle health"
              url="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7297622/"
            />.
          </>
        )}

        <> To improve muscle health, strength training that targets various muscle groups is crucial. This not only supports overall strength but also helps preserve endurance and functionality in daily activities.</>
    
        {strengthRisk === RiskLevel.MODERATE && (strengthTrains === true || strengthTrains === "true") && (
          <>
            <br />
            <br />
            You're already close to an optimal level, so stick with your routine and you'll get there soon!
          </>
        )}
      </>
    );
  };


  return (
    strengthTests && (
      <div className="strength-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {description}
        </Paragraph>

        <TestView 
          tests={strengthTests} 
          report={report} 
          results={results}
          reports={reports}
          user={user}
        />

        <AdviceSection 
          report={report}
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};
