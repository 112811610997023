const FormHelper = {
  fetchHasError: async (form) => {
    let error = null
    try {
      await form.validateFields();
      error = false
    } catch (err) {
      error = true
    }
    return error
  }
}

export default FormHelper