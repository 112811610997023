import { useState, useContext } from "react";
import { LinkedinFilled, RedditSquareFilled, TwitterSquareFilled, LinkOutlined, FacebookFilled, MailOutlined, CalendarOutlined, ClockCircleOutlined, BellOutlined, ShareAltOutlined } from "@ant-design/icons";
import "./stickyBlogHeader.scss";
import { Affix, Avatar, Dropdown, Typography, message } from "antd";
import { SubscribeModal } from "../../subscribeModal/subscribeModal.component";
import PosthogHelper from "../../../helpers/posthog.helper";
import { UserContext } from "../../../contexts/user.context";
import { 
  FacebookShareButton, 
  TwitterShareButton,
  LinkedinShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  RedditIcon
} from 'react-share';
const { Text } = Typography

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const StickyBlogHeader = ({ publishedAt, readTime, authorPhoto, authorName }) => {
  const [openSubscribe, setOpenSubscribe] = useState()
  const { currentUser } = useContext(UserContext)

  const shareItems = [
    {
      key: 'linkedin',
      label: (
        <LinkedinShareButton url={window.location.href}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <LinkedinFilled className="linkedin-icon" />
            <span>LinkedIn</span>
          </div>
        </LinkedinShareButton>
      ),
      onClick: () => {
        PosthogHelper.track(currentUser, '[blog] click share linkedin link', true)
      }
    },
    {
      key: 'twitter',
      label: (
        <TwitterShareButton url={window.location.href}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <TwitterSquareFilled className="twitter-icon" />
            <span>Twitter</span>
          </div>
        </TwitterShareButton>
      ),
      onClick: () => {
        PosthogHelper.track(currentUser, '[blog] click share twitter link', true)
      }
    },
    {
      key: 'reddit',
      label: (
        <RedditShareButton url={window.location.href}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <RedditSquareFilled className="reddit-icon" />
            <span>Reddit</span>
          </div>
        </RedditShareButton>
      ),
      onClick: () => {
        PosthogHelper.track(currentUser, '[blog] click share reddit link', true)
      }
    },
    {
      key: 'facebook',
      label: (
        <FacebookShareButton url={window.location.href}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <FacebookFilled className="facebook-icon" />
            <span>Facebook</span>
          </div>
        </FacebookShareButton>
      ),
      onClick: () => {
        PosthogHelper.track(currentUser, '[blog] click share facebook link', true)
      }
    },
    {
      type: 'divider'
    },
    {
      key: 'email',
      label: 'Email',
      icon: <MailOutlined className="email-icon" />,
      onClick: () => {
        PosthogHelper.track(currentUser, '[blog] click share email link', true)
        window.open(`mailto:?body=${window.location.href}`);
      }
    },
    {
      key: 'copy',
      label: 'Copy Link',
      icon: <LinkOutlined className="copy-icon" />,
      onClick: () => {
        PosthogHelper.track(currentUser, '[blog] click copy link', true)
        navigator.clipboard.writeText(window.location.href);
        message.success('Link copied to clipboard!');
      }
    }
];

  return (
    <Affix offsetTop={0}>
      <div className="sticky-blog-header">
        <SubscribeModal
          open={openSubscribe}
          setOpen={setOpenSubscribe}
        />

        <div className="sticky-blog-header-inner">
          <div className="blog-label-list">
            {authorPhoto && authorName && (
              <Text className="blog-label">
                <Avatar src={authorPhoto} className="blog-avatar" /> {authorName}
              </Text>  
            )}

            <Text className="blog-label">
              <CalendarOutlined /> {publishedAt}
            </Text>

            <Text className="blog-label">
              <ClockCircleOutlined /> {readTime}
            </Text>

            <a 
              className="blog-label-link" 
              onClick={() => {
                setOpenSubscribe(true)
                PosthogHelper.track(currentUser, '[blog] click subscribe btn', true)
              }}
            >
              <Text className="blog-label">
                <BellOutlined /> Subscribe
              </Text>
            </a>

            <Dropdown 
              menu={{ items: shareItems }} 
              trigger={['click']}
              onOpenChange={isOpen => {
                if (isOpen) {
                  PosthogHelper.track(currentUser, '[blog] click share btn', true)
                }
              }}
            >
              <a className="blog-label-link">
                <Text className="blog-label">
                  <ShareAltOutlined /> Share
                </Text>
              </a>
            </Dropdown>
          </div>
        </div>
    </div>
  </Affix>
  )
}