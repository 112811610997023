import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import { ReadableRiskLevel, RiskLevel, ReportSection, TestCode, Supplement, MedicalCondition, QuestionTemplateCode, Diet } from "../../../enums/index.enum"
import ReportHelper from "../../../helpers/report.helper";
import { TestChart } from "../testChart/testChart.component";
import { TestModal } from "../../testModal/testModal.component";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { Reference } from "../reference/reference.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import "./homocysteineAdvice.scss";


const { Paragraph } = Typography;

const reference = {
  url: "https://pubmed.ncbi.nlm.nih.gov/18990318/",
  content:
    "For every 5 μmol/L increase of homocysteine, your risk for heart disease increases by at least 20%.",
};

const medicalConditions = [
  MedicalCondition.HEART_DISEASE,
  MedicalCondition.STROKE,
  MedicalCondition.BLOCKED_ARTERY,
  MedicalCondition.HEART_ATTACK,
];


export const HomocysteineAdvice = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [riskLevel, setRiskLevel] = useState();
  const [openModal, setOpenModal] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [testId, setTestId] = useState();


  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [riskLevel, report]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  useEffect(() => {
    setRiskLevel(getRiskLevel());
  }, [tests, results, report]);

  const getFamilyHistory = () => {
    return user.familyHistory
      ?.filter((condition) => medicalConditions.includes(condition))
      .map((condition) => condition.toLowerCase());
  };


  const isTakingBVitamins = () => {
    const answer = ReportHelper.getAnswer(
      report,
      QuestionTemplateCode.CURRENT_SUPPLEMENTS
    );
    return (
      answer?.includes(Supplement.VITAMIN_B12) &&
      answer?.includes(Supplement.VITAMIN_B9) &&
      answer?.includes(Supplement.VITAMIN_B6)
    );
  };

  const getRiskLevel = () => {
    if (!tests || !results || !report) return null;
    return ReportHelper.getTestRisk(
      TestCode.HOMOCYSTEINE,
      report,
      results,
      tests
    );
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
    if (!report || !riskLevel) return null;
    const isBetter = ReportHelper.isBetter(
      report,
      ReportSection.HOMOCYSTEINE_ADVICE
    );
    const isWorse = ReportHelper.isWorse(
      report,
      ReportSection.HOMOCYSTEINE_ADVICE
    );
    if (isBetter) {
      return (
        <>
          Your <span>homocysteine</span> is <span>improving</span> - an amino
          acid marker that is a risk factor for heart disease, stroke and
          dementia.
        </>
      );
    }
    if (isWorse) {
      return (
        <>
          Your <span>homocysteine</span> is <span>increasing</span> - an amino
          acid marker that is a risk factor for heart disease, stroke and
          dementia.
        </>
      );
    }

    if (isTakingBVitamins()) {
      return (
        <>
          Your <span>homocysteine</span> is{" "}
          <span>{ReadableRiskLevel[riskLevel]}</span>, despite taking vitamin B
          supplements.
        </>
      );
    }

    return (
      <>
        Your <span>homocysteine</span> is{" "}
        <span>{ReportHelper.getRiskEval(TestCode.HOMOCYSTEINE, report, results, tests)}</span> - an amino acid marker that
        is a risk factor for heart disease, stroke and dementia.
      </>
    );
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)
    if (!report || !riskLevel) return null;

    let triggers = [];
    const familyHistory = getFamilyHistory();
    if (familyHistory?.length) {
      triggers.push(
        `family history of ${familyHistory.slice(0, 2).join(" and ")}`
      );
    }

    const content = (
      <>
        Elevated homocysteine levels can increase oxidative stress, damage your
        arterial walls and lead to blood clots.
      </>
    );
    const isBetter = ReportHelper.isBetter(
      report,
      ReportSection.HOMOCYSTEINE_ADVICE
    );
    const isWorse = ReportHelper.isWorse(
      report,
      ReportSection.HOMOCYSTEINE_ADVICE
    );
    if (isBetter) {
      return (
        <>
          {content}
          <br />
          <br />
          Good job decreasing your homocysteine level since your last blood
          draw. Stick to your game plan, so you can hit an optimal level by your
          next blood draw.
        </>
      );
    } else if (isWorse) {
      return (
        <>
          {content}
          <br />
          <br />
          Stick to your game plan, so you can decrease your homocysteine level by your next blood draw.
        </>
      );
    } else if (isTakingBVitamins()) {
      return (
        <>
          Elevated homocysteine levels can increase oxidative stress, damage
          your arterial walls and lead to blood clots. It is a risk factor for
          heart disease, stroke and dementia <br />
          <br />
          {riskLevel === RiskLevel.MODERATE &&
            ` B6, B9 and B12 vitamins helps
            facilitate the breakdown of homocysteine, resulting in lower
            homocysteine levels. The good news is that you are already close to an optimal homocysteine level. Stick to your B vitamin supplementation and you should get there soon.`}
        </>
      );
    }

    return (
      <>
        {content} <Reference content={reference.content} url={reference.url}/> 
        {triggers?.length>0 && <>
          <br />
          <br /> Due to your {triggers.join(" and ")}, it’s recommended you
          start taking action now.
          {riskLevel === RiskLevel.MODERATE
            ? ` This is an easy fix and you're not far from optimal.`
            : ` You can easily fix your homocysteine level by taking a supplement.`}
        </>
        }
      </>
    );
  };


  return (
    user &&
    riskLevel && (
      <div className="homocysteine-advice">
        <TestModal
          open={openModal}
          setOpen={setOpenModal}
          testId={testId}
          report={report}
          tests={tests}
          results={results}
          reports={reports}
        />
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {description}
        </Paragraph>

        <TestChart 
          report={report} 
          code={TestCode.HOMOCYSTEINE} 
          tests={tests}
          results={results}
          reports={reports}
        />

        <AdviceSection 
          report={report} 
          section={section}
          results={results}
          tests={tests}
          setTestId={setTestId}
          setOpenModal={setOpenModal}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};
