import { createRef } from 'react'
import { Table, Button, Input, Space, Checkbox, Dropdown } from 'antd'
import "./flexibleTable.scss";
import FilterDropdownType from '../../enums/filterDropdownType.enum'
import { FilterFilled, SearchOutlined, SettingOutlined } from '@ant-design/icons'
import classNames from 'classnames';

export const FlexibleTable = ({ 
  columns, 
  tableProps={}, 
  onFilterSuccess, 
  getCustomFilter, 
  isLoading, 
  records, 
  setFilteredCount, 
  getActionItems, 
  pageSize=50,
  rowClassName
}) => {
  const searchInput = createRef();

  const getColumnProps = (dataIndex, filterDropdownType, filterOptions=[]) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      switch (filterDropdownType) {
        case FilterDropdownType.INPUT:
          return (
            <div style={{ padding: 8 }}>
              <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}
                >
                  Search
                </Button>
                <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                  Reset
                </Button>
              </Space>
            </div>
          )
        case FilterDropdownType.CHECKBOX:
          return (
            <div style={{ padding: 8 }}>
              <Checkbox.Group
                value={selectedKeys}
                onChange={(checkedValues) => setSelectedKeys(checkedValues)}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                {filterOptions.map(option => {
                  if (typeof option === 'string') {
                    return (
                      <Checkbox key={option} value={option}>
                        {option}
                      </Checkbox>
                    )
                  }
                  return (
                    <Checkbox key={option?.value} value={option?.value}>
                      {option?.label}
                    </Checkbox>
                  )
                })}
              </Checkbox.Group>
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    confirm()
                  }}
                  size="small"
                >
                  Filter
                </Button>
                <Button
                  onClick={() => handleReset(clearFilters, confirm)}
                  size="small"
                >
                  Reset
                </Button>
              </Space>
            </div>
          )
      }
    },
    filterIcon: (filtered) => {
      switch (filterDropdownType) {
        case FilterDropdownType.INPUT:
          return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        case FilterDropdownType.CHECKBOX:
          return <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
      }
    },
    onFilter: (value, record) => isFiltered(filterDropdownType, record, dataIndex, value),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };

  const isFiltered = (filterDropdownType, record, key, value) => {
    if (getCustomFilter) {
      const customFilter = getCustomFilter(record, value)
      if (Object.keys(customFilter).includes(key)) {
        return customFilter[key]()
      }
    }
    if (filterDropdownType === FilterDropdownType.CHECKBOX) {
      return record[key] === value
    } 
    return record[key] && record[key].toString().toLowerCase().includes(value.toLowerCase())
  }

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm(); // Confirm to close the dropdown
  };

  const handleFilterChange = (_, filters) => {
    const filteredOrders = records.filter((record) => {
      let match = true;
      Object.keys(filters).forEach((key) => {
        if (filters[key]) {
          const { filterDropdownType } = columns.find(({ dataIndex }) => dataIndex === key)
          match = match && filters[key].some(value => isFiltered(filterDropdownType, record, key, value))
        }
      });
      return match;
    });
    if (onFilterSuccess) {
      onFilterSuccess(filteredOrders)
    }
    setFilteredCount(filteredOrders.length)
  };

  return (
    <Table
      loading={isLoading}
      rowClassName={rowClassName}
      size="small"
      className={classNames("primary-table", "flexible-table")}
      rowKey="_id"
      dataSource={records}
      onChange={handleFilterChange}
      pagination={{
        pageSize,
        showSizeChanger: false,
      }}
      columns={[
        ...columns.filter(column => column).map(column => {
          if (column.filterDropdownType) {
              return {
                ...column,
                ...getColumnProps(column.dataIndex, column.filterDropdownType, column.filterOptions)
              }
            }
            return column
        }),
        ...(getActionItems ? [{
          dataIndex: '_id',
          width: 50,
          className: 'action-column',
          render: (_, record) => {
            const items = getActionItems(record)
            if (items?.length > 0) {
              return (
                <Dropdown 
                  menu={{items}}
                  placement='bottomRight'
                  overlayClassName='menu-overlay'
                >
                  <Button>
                    <SettingOutlined />
                  </Button>
                </Dropdown>
              )
            }
          }
        }] : [])
      ]}
      {...tableProps}
    />
  )
}