import React from 'react';
import { Modal } from 'antd';
import PaymentMethods from '../paymentMethods/paymentMethods.component';
import './paymentMethodsModal.scss';
const PaymentMethodsModal = ({ open, setOpen, activeCardId, onSetDefault, flow }) => {
  return (
    <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        width={600}
        className="payment-methods-modal"
    >
        <h2>Payment Methods</h2>
        <PaymentMethods 
            activeCardId={activeCardId}
            onSetDefault={onSetDefault}
            flow={flow}
        />
    </Modal>
  );
};

export default PaymentMethodsModal;