import { useState, useEffect, useRef } from 'react'
import { Form, Button, message, Input } from "antd"
import "./invite.scss"
import { CheckCircleFilled } from '@ant-design/icons'
import { FlowStepFooter } from '../../flowStep/flowStepFooter.component'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { removeAppointmentInvite } from '../../../../services/appointment.service'
import { MaskedInput } from 'antd-mask-input'
import copy from 'copy-to-clipboard';

const { Item } = Form

export const Invite = ({
  onSubmit,
  form,
  isSubmitting,
  flow,
  setFlow,
}) => {
  const { flowId } = useParams()
  const [removing, setRemoving] = useState([])
  const removingRef = useRef(null)
  removingRef.current = removing
  const [phones, setPhones] = useState([""])
  const inputRef = useRef()

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef])

  useEffect(() => {
    onRemovePhones()
  }, [flow])

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const onRemovePhones = () => {
    if (!flow) return
    form.resetFields()
    setPhones([""])
  }

  const onChange = () => {
    const values = form.getFieldsValue()
    
    const newPhones = Object.values(values).filter(phone => phone?.length)
    setPhones([
      ...newPhones,
      "",
    ])
  }

  const onRemove = async (inviteId) => {
    try {
      setRemoving([
        ...removingRef.current,
        inviteId
      ])
      const appointment = await removeAppointmentInvite(flow.appointment._id, inviteId, {
        select: '_id start location phlebType status invitePrice patients',
        populate: [{
          path: 'invites',
          select: '_id phone status'
        }]
      })
      setFlow(cachedFlow => {
        return {
          ...cachedFlow,
          appointment
        }
      })

    } catch (err) {
      console.log(err)
      message.error('Failed to remove invite')
    }
    setRemoving(removingRef.current.filter(i => i !== inviteId))
  }

  const inviteLink = `${process.env.REACT_APP_CLIENT_URL}/appointments/${flow.appointment?._id}/join`;
  const onCopyInviteLink = () => {  
    copy(inviteLink);
    message.info('Appointment invite link copied') 
  }

  return (
    <div className="invite">
      
      <p>Share this invite link:</p>
      <div>
        <Item className="invite-item">
        <Input 
          type="text" 
          value={inviteLink} 
          readOnly 
          style={{width: "75%"}}
          className="invite-field"
        />
        <Button 
          onClick={onCopyInviteLink}
          className="invite-log"
          style={{width:"24%", display: "inline-block", marginLeft: "1%"}}
        >
          Copy
        </Button>
        </Item>
      </div>


      <p>Or send the invite link via SMS:</p>

      <Form
        form={form}
        className="invite-form"
      >
        {flow?.appointment?.invites.map(invite => (
          <div 
            key={`invite-${invite._id}`}
            className="invite-log"
          >
            <div className="invite-log-phone">
              <CheckCircleFilled className="invite-log-icon" /> 
              {invite.phone}
            </div>

            <Button
              className="invite-log-btn"
              onClick={() => onRemove(invite._id)}
              loading={removingRef.current?.some(inviteId => inviteId === invite._id)}
            >
              Remove
            </Button>
          </div>
        ))}
        {phones.map((phone, index) => (
          <Item 
            key={`phone-${index}`}
            name={`phone-${index}`}
            className="invite-item"
            validateTrigger='onSubmit'
          >
            <MaskedInput
              inputMode="numeric"
              placeholder="Type phone number here..."
              ref={index === 0 ? inputRef : null}
              className="invite-field"
              mask={'(000) 000-0000'}
              onChange={onChange}
            />
          </Item>
        ))}
        
        <FlowStepFooter 
          onSubmit={() => onSubmit()}
          isSubmitting={isSubmitting}
          buttonText={phones.some(phone => phone?.length) ? `Send ${phones.filter(phone => phone?.length).length === 1 ? 'Invite' : 'Invites'}` : "Continue"}
        />
      </Form>
    </div>
  )
}