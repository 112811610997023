import React from "react";
import "./cacAdvice.scss";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { LowercaseTest, ReportSection, Condition, TestCode, RiskLevel } from "../../../enums/index.enum";
import { renderToString } from 'react-dom/server'
import { AdviceSection } from "../adviceSection/adviceSection.component";
import parse from 'html-react-parser';
const converter = require("number-to-words");

const { Paragraph } = Typography;

export const CacAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [cacTest, setCacTest] = useState([]);
  const [cacScore, setCacScore] = useState(null);
  const [cacRisk, setCacRisk] = useState(null);
  const [recs, setRecs] = useState()

  const [title, setTitle] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();
  
  useEffect(() => {
    categorizeTests();
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setPrimaryDescription(getPrimaryDescription());
  }, [report, cacTest, results, tests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
          recs
        }
      };
    });
  }, [title, primaryDescription]);

  const categorizeTests = () => {
    if (!report || !tests) return null;
    setCacTest(ReportHelper.getSectionTests(report, ReportSection.HIGH_CAC_ADVICE, tests) || []);
    setCacScore(ReportHelper.getTestValue(TestCode.CAC, report, results, tests));
    setCacRisk(ReportHelper.getTestRisk(TestCode.CAC, report, results, tests));
  };


  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title);

    if (!report || !results || !tests || !cacTest) return null;
  
    let content = (
      <>
       {cacScore>0  ? <>Your <span>CAC score</span> is {cacRisk === RiskLevel.HIGH && cacScore>100 ? <span>high</span> : <span>elevated</span>}, which increases your risk for heart attack and stroke.</> : <>Your <span>CAC score</span> is <span>0</span>, indicating a very low risk of heart attack and stroke.</>}
      </>
    );

    return content;
  };

  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription);
    if (!report || !cacTest) return null;

    return (
      <>
      The CAC score measures the calcium buildup in your coronary arteries, a key marker of plaque accumulation and heart disease risk. This plaque can narrow or block arteries, reducing blood flow to the heart and significantly increasing the likelihood of a cardiac event.
  
      {(cacScore === 0 || cacScore === "0") && <><br/><br/>With a CAC score of {cacScore}, you currently have no detectable plaque, indicating a near zero-risk of cardiac events in the next 5-10 years. However, it’s important to continue monitoring other risk factors, such as high cholesterol, high blood pressure, and insulin resistance, as they can still contribute to future heart disease risk.</>}

      {cacScore <=100 && cacScore>0 && <><br/><br/>With a CAC score of {cacScore}, you currently have mild plaque buildup, which puts you at a moderate risk for a cardiac event within the next 5-10 years. It’s essential to adopt lifestyle changes, and medication should be considered to slow disease progression and manage this risk effectively.</>}

      {cacScore <300 && cacScore>100 && <><br/><br/>With a CAC score of {cacScore}, you currently have moderate plaque buildup, indicating a moderate to high risk of a cardiac event within the next 5-10 years. To lower your risk of heart attack, intensive lifestyle modifications and medications are needed to manage plaque progression and reduce your overall risk. Regular monitoring and proactive treatment are critical to improving heart health.</>}


      {cacScore >=300 && <><br/><br/>With a CAC score of {cacScore}, you have extensive plaque buildup, indicating a high risk of a cardiac event within the next 5-10 years. Intensive treatment, including both lifestyle changes and medications, is critical to reduce this risk. Close monitoring and regular follow-ups with your healthcare provider are essential to manage plaque progression and protect your heart health.</>}
      </>
    );
  };


  return (
    cacTest && (
      <div className="vat-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {primaryDescription}
        </Paragraph>
        <TestView 
          report={report} 
          tests={cacTest}
          results={results}
          reports={reports}
          user={user}
        />
        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />

      </div>
    )
  );
};