import { useState, useEffect, useRef, useContext } from 'react'
import { Input, Form } from "antd"
import MaskedInput from 'antd-mask-input'
import "./singleInput.scss"
import { FlowStepFooter } from '../../flowStep/flowStepFooter.component'
import _ from 'lodash'
import FormHelper from '../../helpers/form.helper'
import { useNavigate, useParams } from 'react-router-dom'
import RuleHelper from '../../../../helpers/rule.helper'
import { UserContext } from '../../../../contexts/user.context'

const { Item } = Form
const { TextArea } = Input

export const SingleInput = ({
  step,
  onSubmit,
  form,
  isSubmitting,
  hasAttempt,
  skeleton,
  textarea=false,
  flow,
}) => {
  const [rules, setRules] = useState([])
  const navigate = useNavigate()
  const inputRef = useRef(null)
  const { flowType } = useParams()
  const { token } = useContext(UserContext)
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    inputRef.current.focus();
  }, [step]);

  useEffect(() => {
    fetchRules()
    inputRef.current.focus()
  }, [step])

  useEffect(() => {
    if (!textarea) {
      const keyDownHandler = event => {
        if (event.key === 'Enter') {
          event.preventDefault();
          onSubmit()
        }
      };
      document.addEventListener('keydown', keyDownHandler);
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };  
    }
  }, []);

  const fetchRules = () => {
    if (!step) return
    let r = []

    const canSkipPassword = token && step.field.password

    if (step.field.required && !canSkipPassword) {
      r.push(RuleHelper.isRequired)
    }

    if (step.field.date) {
      r.push(RuleHelper.isDate)
    }

    if (step.field.phone) {
      r.push(RuleHelper.isPhone)
    }

    if (step.field.phone && step.field.unique) {
      r.push(RuleHelper.isUniquePhone({
        fieldName: step.field.name,
        navigate, 
        redirectUrl: `/flow/${flowType}/${Object.keys(skeleton)[0]}`,
        userId: flow?.user?._id,
        uniqueError: step.field.uniqueError
      }))
    }

    if (step.field.email) {
      r.push(RuleHelper.isEmail)
      r.push(RuleHelper.isUniqueEmail({
        fieldName: step.field.name,
        navigate, 
        redirectUrl: `/flow/${flowType}/${Object.keys(skeleton)[0]}`,
        userId: flow?.user?._id,
        uniqueError: step.field.uniqueError
      }))
    }
    setRules(r)
  }

  const InputComponent = textarea ? TextArea : (step?.field.date || step?.field.phone) ? MaskedInput : Input

  return (
    <div className="single-input">
      <Form
        form={form}
        className="single-input-form"
      >
        <Item 
          key={`field-${step.field.name}`}
          name={step.field.name}
          className="single-input-item"
          rules={rules}
          validateTrigger='onSubmit'
        >
          <InputComponent
            inputMode={step.field.inputMode || 'text'}
            placeholder={step.field.placeholder}
            type={step.field.password ? 'password' : 'default'}
            className="single-input-field"
            onChange={() => {
              if (hasAttemptRef.current) {
                FormHelper.fetchHasError(form)
              }
            }}
            mask={step?.field.date ? '00/00/0000' : step?.field.phone ? '(000) 000-0000' : null}
            defaultValue={step.field?.initialValue}
            ref={inputRef}
            autoSize={{
              minRows: 3,
              maxRows: 6,
            }}
          />
        </Item>
        
        <FlowStepFooter 
          onSubmit={() => onSubmit()}
          isSubmitting={isSubmitting}
          buttonText={step?.buttonText}
        />
      </Form>
    </div>
  )
}