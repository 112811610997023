import { useParams } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import { OverexercisePost } from "./posts/overexercisePost.component";
import { CacPost } from "./posts/cacPost.component";
import { FounderPost } from "./posts/founderPost.component";
import { SaunaPost } from "./posts/saunaPost.component";
import { InsurancePost } from "./posts/insurancePost.component";
import { CumulativeExposurePost } from "./posts/cumulativeExposurePost.component";
import { SoftPlaquePost } from "./posts/softPlaquePost.component";
import { GalleriPost } from "./posts/galleriPost.component";
import { PCSK9iPost } from "./posts/PCSK9iPost.component";
import { ApoBPost } from "./posts/apoBPost.component";
import { SupplementPost } from "./posts/supplementPost.component";
import { PeakPost } from "./posts/peakPost.component";
import { ExercisePost } from "./posts/exercisePost.component";
import { BloodPressureDietPost } from "./posts/bloodPressureDietPost.component";
import { ForecastPost } from "./posts/forecastPost.component";
import './blog.scss';
import { useEffect } from "react";
import PosthogHelper from "../../helpers/posthog.helper";
import BlogId from "../../enums/blogId.enum";
export const Blog = () => {
  const { blogId } = useParams()
  const { currentUser } = useContext(UserContext)

  useEffect(() => {
    if (currentUser) {
      PosthogHelper.track(currentUser, `[blog] view post: ${blogId}`, true)
    }
  }, [currentUser])

  return (
    <div className="blog">
      {blogId === BlogId.OVEREXERCISE && <OverexercisePost />}
      {blogId === BlogId.CAC && <CacPost />}
      {blogId === BlogId.FOUNDER && <FounderPost />}
      {blogId === BlogId.SAUNA && <SaunaPost />}
      {blogId === BlogId.INSURANCE && <InsurancePost />}
      {blogId === BlogId.SOFT_PLAQUE && <SoftPlaquePost />}
      {blogId === BlogId.GALLERI && <GalleriPost />}
      {blogId === BlogId.PEAK && <PeakPost />}
      {blogId === BlogId.CUMULATIVE_EXPOSURE && <CumulativeExposurePost />}
      {blogId === BlogId.FORECAST && <ForecastPost />}
      {blogId === BlogId.PCSK9I && <PCSK9iPost />}
      {blogId === BlogId.APOB && <ApoBPost />}
      {blogId === BlogId.SUPPLEMENT && <SupplementPost />}
      {blogId === BlogId.BLOOD_PRESSURE_DIET && <BloodPressureDietPost />}
      {/* {blogId === BlogId.EXERCISE && <ExercisePost />} */}
    </div>
  )
}