import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Spin, Typography, Table, message } from 'antd'
import { confirmPaymentLink, getPaymentLink } from "../../services/charge.service"
import "./paymentLink.scss";
import { useContext } from "react";
import { UserContext } from "../../contexts/user.context";
import { CardForm } from "../cardForm/cardForm.component";
import ChargeStatus from "../../enums/chargeStatus.enum";
import { CheckCircleFilled } from "@ant-design/icons";

const { Text, Paragraph } = Typography

const select = 'discount amount patient status'
const populate = [{
  path: 'products',
  select: 'cost',
  populate: {
    path: 'productType',
    select: 'title'
  }
}]

export const PaymentLink = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { currentUser, token } = useContext(UserContext)
  const [charge, setCharge] = useState()
  const [isLoadingCharge, setIsLoadingCharge] = useState()
  const [isPaying, setIsPaying] = useState()
  const [isPaymentComplete, setIsPaymentComplete] = useState(false); 

  useEffect(() => {
    fetchCharge()
  }, [id])

  const fetchCharge = async () => {
    setIsLoadingCharge(true)
    if (!id) return
    try {
      const fetchedCharge = await getPaymentLink(id, {
        select,
        populate
      })
      
      setCharge(fetchedCharge)

      if (fetchedCharge.status === ChargeStatus.PAID) {
        setIsPaymentComplete(true); 
      }

    } catch (err) {
      onInvalidCharge()
    }
    setIsLoadingCharge(false)
  }

  const onInvalidCharge = () => {
    message.error(`Payment link is invalid`)
    navigate('/')
  }

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const onCardSuccess = async (card) => {
    setIsPaying(true);

    setCharge(await confirmPaymentLink(id, {
      select,
      populate
    }))
    setIsPaying(false);
    setIsPaymentComplete(true); 
    message.success('Payment confirmed. Thank you!')
  }

  return isLoadingCharge ? (
    <div className="loading">
      <Spin />
    </div>
  ) : charge ? (
    <div className="payment-link">
      {isPaymentComplete ? (
        <>
        <div className="thank-you-message">
          <Paragraph><CheckCircleFilled className="check-icon" /></Paragraph>
          <Paragraph className="payment-link-title">Payment Successful</Paragraph>
          <Paragraph className="payment-link-description">Thank you! Your transaction of ${charge.amount.toLocaleString(undefined, { minimumFractionDigits: 2 })} was completed successfully.<br/>Please <a onClick={onChat} className="message-link">message us</a> if you need any assistance.</Paragraph>
        </div>
        </>
      ) : (
      <>
        <Paragraph className="payment-link-title">
          Pay for the following {charge.products.length === 1 ? 'item' : 'items'}.
        </Paragraph>

        <Paragraph className="payment-link-description">
          If you have any questions, <a onClick={onChat} className="message-link">send us a message</a>.
        </Paragraph>

        <Table
          dataSource={charge.products.length === 1 ?
            charge.products : 
            [...charge.products, {}] // Empty row for total
          }
          rowClassName={({ productType }) => {
            if (!productType) {
              return 'total-row'
            }
          }}
          size='small'
          pagination={false}
          className="product-table"
          columns={[{
            title: 'Purchase',
            dataIndex: 'productType',
            render: productType => {
              return productType?.title || <Text className="total-label">Total</Text>
            }
          }, {
            title: 'Amount',
            dataIndex: 'cost',
            render: (cost) => {
              if (!cost) {
                // Total
                if (charge.products.length === 1) return

                if (charge.discount) {
                  return (
                    <div className='total-price'>
                      <Text className="true-price">${charge.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                      <Text className="original-price">${(charge.amount/(1 - charge.discount/100)).toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                    </div>
                  )
                } else if (!cost) {
                  return (
                    <div className="total-price">
                      <Text className="true-price">${charge.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                    </div>
                  )
                }
              } else {
                // Item
                if (charge.discount) {
                  return (
                    <div className="product-price">
                      <Text className="true-price">${cost.toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                      <Text className="original-price">${(cost/(1 - charge.discount/100)).toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                    </div>
                  )
                } else {
                  return (
                    <div className="product-price">
                      <Text className="true-price">${cost.toLocaleString(undefined, {minimumFractionDigits: 2})}</Text>
                    </div>  
                  )
                }
              }
            }
          }]}
        />

        <CardForm
          onSuccess={onCardSuccess}
          isLoading={isPaying}
          setIsLoading={setIsPaying}
        />
      </>
      )}
    </div>
  ) : null
}