import React, { useEffect, useState, useContext } from "react";
import { Button, Table, Modal, Input, Form, Select, DatePicker, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import PatientHelper from "../../helpers/patient.helper";
import "./profile.scss";
import { PageHeader } from "../pageHeader/pageHeader.component";
import { AdminLocationModal } from "../adminLocationModal/adminLocationModal.component";
import ObjectType from "../../enums/objectType.enum";
import MaskedInput from 'antd-mask-input'
import { UserContext } from "../../contexts/user.context";
import UserHelper from "../../helpers/user.helper";
import { getMe, updateMe } from "../../services/user.service";
const { Column } = Table;


export const PhoneEditModal = ({ open, setOpen, patient, onSuccess }) => {
  const [form] = Form.useForm(); // Create form instance

  useEffect(() => {
    // Reset phone number in form when the modal opens
    if (open) {
      form.setFieldsValue({ phone: patient?.phone || "" });
    }
  }, [open, patient, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const updatedPatient = await updateMe({
        fields: { phone: values.phone },
      });
      setOpen(false);
      onSuccess(updatedPatient); // Ensure onSuccess is passed the updated patient
    } catch (error) {
      console.error("Failed to update phone number:", error);
      message.error(error.response?.data?.message || "Failed to update phone number");
    }
  };

  return (
    <Modal
      title="Edit Phone Number"
      visible={open}
      onCancel={() => setOpen(false)}
      onOk={handleSave}
      okText="Save"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please enter a phone number",
            },
            {
              pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
              message:
                "Please enter a valid phone number format: (###) ###-####",
            },
          ]}
        >
          <MaskedInput mask="(000) 000-0000" placeholder="Enter new phone number" />
        </Form.Item>
      </Form>
    </Modal>
  );

};

export const BasicFieldEditModal = ({ open, setOpen, patient, fieldName, onSuccess }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (open) {
      const initialValue = fieldName === 'name' 
        ? { firstName: patient?.firstName, lastName: patient?.lastName }
        : { [fieldName]: patient?.[fieldName] };
      form.setFieldsValue(initialValue);
    }
  }, [open, patient, fieldName, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const updateData = fieldName === 'name'
        ? { fields: { firstName: values.firstName, lastName: values.lastName } }
        : { 
            fields: { 
              [fieldName]: values[fieldName] 
            } 
          };
      
      const updatedPatient = await updateMe(updateData);
      setOpen(false);
      onSuccess(updatedPatient);
    } catch (error) {
      console.error("Failed to update field:", error);
      if (error.response?.data?.code === 11000) {
        message.error('Account already exists with this email')
      } else {
        message.error(`Failed to update ${fieldName}`);
      }
    }
  };

  const renderFormItem = () => {
    switch (fieldName) {
      case 'name':
        return (
          <>
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[{ required: true, message: "Please enter first name" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[{ required: true, message: "Please enter last name" }]}
            >
              <Input />
            </Form.Item>
          </>
        );
      case 'gender':
        return (
          <Form.Item
            name="gender"
            label="Biological Sex"
            rules={[{ required: true }]}
          >
            <Select>
              <Select.Option value="Male">Male</Select.Option>
              <Select.Option value="Female">Female</Select.Option>
            </Select>
          </Form.Item>
        );
      case 'dob':
        return (
          <Form.Item
            name="dob"
            label="Date of Birth"
            rules={[
              { required: true, message: "Please enter date of birth" },
              {
                pattern: /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/,
                message: "Please enter a valid date format: MM/DD/YYYY"
              }
            ]}
          >
            <MaskedInput 
              mask="00/00/0000" 
              placeholder="MM/DD/YYYY"
            />
          </Form.Item>
        );
      case 'email':
        return (
          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true },
              { type: 'email', message: 'Please enter a valid email' }
            ]}
          >
            <Input />
          </Form.Item>
        );
      case 'isAthlete':
        return (
          <Form.Item
            name="isAthlete"
            label="Professional Athlete"
            valuePropName="checked"
          >
            <Select>
              <Select.Option value={true}>Yes</Select.Option>
              <Select.Option value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const getModalTitle = () => {
    if (!fieldName) return 'Edit Field';
    return `Edit ${fieldName === 'name' ? 'Name' : fieldName.charAt(0).toUpperCase() + fieldName.slice(1)}`;
  };

  return (
    <Modal
      title={getModalTitle()}
      visible={open}
      onCancel={() => setOpen(false)}
      onOk={handleSave}
      okText="Save"
    >
      <Form form={form} layout="vertical">
        {renderFormItem()}
      </Form>
    </Modal>
  );
};

export const Profile = () => {
  const [fieldName, setFieldName] = useState(null);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);
  const [profile, setProfile] = useState([]);
  const [isBasicFieldModalOpen, setIsBasicFieldModalOpen] = useState(false);
  const [activePatient, setActivePatient] = useState(null);
  const { currentUser } = useContext(UserContext);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const initializePatient = async () => {
      if (!activePatient) {
        try {
          const fetchedPatient = await getMe();
          if (!fetchedPatient) throw new Error('No patient data received');
          setActivePatient(fetchedPatient);
        } catch (error) {
          console.error("Failed to fetch patient data:", error);
          message.error(error.response?.data?.message || error.message || "Failed to load patient data. Please try again later.");
        }
      }
      setLoading(false); 
    };

    initializePatient();
  }, []);

  const handlePatientUpdate = (updatedPatient) => {
    setActivePatient(updatedPatient);
  };

  useEffect(() => {
    if (!activePatient) return;
    document.title = `${activePatient.firstName} ${activePatient.lastName} - Profile | Instalab`;

    const fetchProfileData = async () => {
      try {
        const weight = await PatientHelper.getWeight(activePatient);
        
        // Ensure we have location data before rendering
        const physicalLocation = activePatient.location ? getLocation(activePatient.location) : '';
        const shippingLocation = activePatient.shippingLocation ? getLocation(activePatient.shippingLocation) : '';

        let profileData = [
          { 
            key: 'Name', 
            value: (
              <>
                {`${activePatient.firstName} ${activePatient.lastName}`}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('name');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Biological sex', 
            value: (
              <>
                {activePatient.gender}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('gender');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Date of birth', 
            value: (
              <>
                {activePatient.dob}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('dob');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Email', 
            value: (
              <>
                {activePatient.email}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('email');
                    setIsBasicFieldModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Phone', 
            value: ( 
              <>{activePatient.phone}

                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('phone');
                    setIsPhoneModalOpen(true);
                  }}
                />
              
              </>
            ) 
          },
          { 
            key: 'Physical Address', 
            value: (
              <>
                {physicalLocation}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('location');
                    setIsLocationModalOpen(true);
                  }}
                />
              </>
            )
          },
          { 
            key: 'Shipping Address', 
            value: (
              <>
                {shippingLocation}
                <Button
                  icon={<EditOutlined />}
                  className="edit-btn"
                  onClick={() => {
                    setFieldName('shippingLocation');
                    setIsLocationModalOpen(true);
                  }}
                />
              </>
            )
          }
        ];

        if (UserHelper.isAdmin(currentUser) || UserHelper.isProvider(currentUser)) {
          profileData.push(
            { key: 'Height', value: activePatient.height ? `${activePatient.height} in` : '-' },
            { key: 'Weight', value: activePatient.weight ? `${weight} lbs` : '-' },
            { key: 'Instalab Member', value: await PatientHelper.isInstalabMember(activePatient) ? "Yes" : "No" },
            { 
              key: 'Professional Athlete', 
              value: (
                <>
                  {activePatient.isAthlete ? "Yes" : "No"}
                  <Button
                    icon={<EditOutlined />}
                    className="edit-btn"
                    onClick={() => {
                      setFieldName('isAthlete');
                      setIsBasicFieldModalOpen(true);
                    }}
                  />
                </>
              )
            }
          )
        }

        setProfile(profileData);
      } catch (error) {
        console.error('Error fetching profile data:', error);
        message.error('Failed to load profile data');
      }
    };

    fetchProfileData();
  }, [activePatient]);

  const getLocation = (location) => {
    if (!location) return 'No address set';
    const { streetAddress, streetAddress2, city, state, postalCode } = location;
    if (!streetAddress || !city || !state || !postalCode) return 'Incomplete address';
    
    let address = streetAddress;
    if (streetAddress2) address += ` ${streetAddress2}`;
    return `${address}, ${city}, ${state} ${postalCode}`;
  };

  return (
    <div className="profile">
      <PageHeader title='Profile' />

      <AdminLocationModal
        objectId={activePatient?._id}
        objectType={ObjectType.PATIENT}
        fieldName={fieldName}
        open={isLocationModalOpen}
        setOpen={setIsLocationModalOpen}
        onSuccess={handlePatientUpdate}
      />

      <PhoneEditModal
        open={isPhoneModalOpen}
        setOpen={setIsPhoneModalOpen}
        patient={activePatient}
        onSuccess={handlePatientUpdate}
      />

      <BasicFieldEditModal
        open={isBasicFieldModalOpen}
        setOpen={setIsBasicFieldModalOpen}
        patient={activePatient}
        fieldName={fieldName}
        onSuccess={handlePatientUpdate}
      />

      {profile.length > 0 && (
        <div className="profile-section">
          <Table
            loading={!profile.length}
            className='primary-table'
            pagination={false}
            rowKey='key'
            dataSource={profile}
            showHeader={false}
          >
            <Column dataIndex='key' width={150} />
            <Column dataIndex='value' render={(value) => value} />
          </Table>
        </div>
      )}
    </div>
  );
};
