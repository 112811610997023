import React, { useState } from 'react';
import { CheckOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import './fsaHsaBadge.scss';
import { addSlack } from '../../services/slack.service.js';
import { CheckListItem } from 'antd-mobile/es/components/check-list/check-list-item.js';
const FsaHsaBadge = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalOpen = async () => {
        setIsModalOpen(true);
        try {
            await addSlack({ 
                message: 'User Opened FSA/HSA Modal',
                channel: process.env.REACT_APP_PATIENT_SLACK
            });
        } catch (error) {
            console.error('Failed to log message to Slack:', error);
        }
    };

    return (
        <>
            <div 
                className="fsa-hsa-badge"
                onClick={handleModalOpen}
                role="button"
                tabIndex={0}
            >
                <CheckSquareOutlined/> FSA / HSA accepted
            </div>

            <Modal
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
            >
                <h2>HSA / FSA Reimbursable</h2>
                <p>All purchases on Instalab can be reimbursed through your Health Savings Account (HSA) or Flexible Spending Account (FSA).</p>
                <p>If you have a card from your HSA/FSA provider, please enter the card number in the checkout process. Otherwise, Instalab will provide an itemized receipt that you can submit for reimbursement.</p>
                <p>If you have any questions, please message us through the help chat or email us at concierge@instalab.com.</p>
            </Modal>
        </>
    );
};

export default FsaHsaBadge; 