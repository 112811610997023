import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import "./vitaminBAdvice.scss";
import TestCode from "../../../enums/testCode.enum";
import ReportHelper from "../../../helpers/report.helper";
import { TestItemList } from "../testItemLlist/testItemList.component";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import ReportSection from "../../../enums/reportSection.enum";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography;

export const VitaminBAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [riskLevel, setRiskLevel] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [bTests, setBTests] = useState();

  // progress
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();
  
  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.VITAMIN_B12_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.VITAMIN_B12_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.VITAMIN_B12_ADVICE));
  }, [report]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  useEffect(() => {
    setRiskLevel(getRiskLevel());
  }, [tests, results, report]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [riskLevel, report, isFixed, isBetter, isWorse]);

  useEffect(() => {
    setBTests(getBTests());
  }, [report, tests]);

  const getBTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionTests(report, ReportSection.VITAMIN_B_ADVICE, tests);
  };

  const getRiskLevel = () => {
    if (!tests || !results || !report) return null;
    return ReportHelper.getTestRisk(
      TestCode.VITAMIN_B12,
      report,
      results,
      tests
    );
  };

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !riskLevel) return null;
    
    return (
      <>
        Your <span>vitamin B9</span> and <span>B12</span> are <span>low</span>, which increases your long-term risk for anemia, heart disease and cognitive decline. 
      </>
    );
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report || !riskLevel) return null;

    return (
      <>
        Vitamin B9 and B12 work in concert for several key processes like DNA synthesis, red blood cell production, maintaining healthy homocysteine levels and much more.
        <br/><br/>Ensuring adequate intake of both these vitamins is crucial for overall health, especially since your body can’t produce them and relies on dietary sources or supplementation.
      </>
    )
  }

  return user && bTests &&(
    <div className="vitamin-b-advice">
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">{description}</Paragraph>

      <TestItemList 
        report={report} 
        testIds={bTests.map(({ _id }) => _id)}
        tests={tests}
        results={results}
        user={user}
        reports={reports}
      />
      <AdviceSection 
        report={report}
        section={section}
        results={results}
        tests={tests}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />
    </div>
  );
};
