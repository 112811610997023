import React, { useEffect, useState } from 'react';
import { Popover, Row, Col, Button, message, Tooltip, Tag, Modal, Alert } from 'antd'
import './membershipPlan.scss';
import { InfoCircleOutlined, CreditCardOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import { cancelMembership, resumeMembership, listMemberships } from '../../services/membership.service';
import moment from 'moment'
import { useNavigate } from 'react-router-dom';
import MembershipHelper from '../../helpers/membership.helper';
import MembershipTypeCode from '../../enums/membershipTypeCode.enum';
import { useRef } from 'react';
import { listMembershipTypes } from '../../services/membershipType.service'
import { listCards } from "../../services/card.service";
import Role from '../../enums/role.enum';
import { PayMembershipModal } from '../payMembershipModal/payMembershipModal.component';

const select = 'status startAt endAt stripeId';
const populate = [{ path: 'membershipType', select: 'code title' }];

export const MembershipPlan = ({
  membershipTypeCode, 
  currentMembership,
  onMembershipPaymentUpdate, 
  path=null, 
  shortenTitle=false
}) => {

  const navigate = useNavigate()
  const [membershipType, setMembershipType] = useState();
  const [membershipTypes, setMembershipTypes] = useState();
  const { memberships } = useContext(UserContext)
  const [resuming, setResuming] = useState([])
  const [canceling, setCanceling] = useState([])
  const [showPayMembershipModal, setShowPayMembershipModal] = useState(false);

  const resumingRef = useRef()
  resumingRef.current = resuming
  const cancelingRef = useRef()
  cancelingRef.current = canceling

  useEffect(() => {
    const fetchMembershipTypes = async () => {
      setMembershipTypes(await listMembershipTypes({
        filter: {
          code: {
            $in: [
              MembershipTypeCode.LONGEVITY,
              MembershipTypeCode.PREMIUM,
              MembershipTypeCode.HEART_HEALTH,
              MembershipTypeCode.LIPIDS,
              MembershipTypeCode.ULTIMATE,
              MembershipTypeCode.PREMIUM_12MO,
              MembershipTypeCode.LONGEVITY_12MO,

              MembershipTypeCode.DEMO,
              MembershipTypeCode.DEMO_12MO,
              MembershipTypeCode.DEMO_PREMIUM,
              MembershipTypeCode.DEMO_PREMIUM_12MO
            ]
          }
        }
      }))
    }
    fetchMembershipTypes()
  }, [])

  useEffect(() => {
    setMembershipType(getMembershipType(membershipTypeCode))
  }, [membershipTypes, membershipTypeCode])

  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType?.code === code)
  }

  const onCancelMembership = async (membership) => {
    // Check if the user has an active Heart Health add-on
    const heartHealthMembership = memberships.find(
      (m) => m.membershipType?.code === MembershipTypeCode.HEART_HEALTH && MembershipHelper.isActive(m) && !MembershipHelper.isExpiring(m)
    );

    // If trying to cancel Core while Heart Health is active, show a warning modal
    if ([MembershipTypeCode.PREMIUM, MembershipTypeCode.LONGEVITY, MembershipTypeCode.PREMIUM_12MO, MembershipTypeCode.LONGEVITY_12MO].includes(membership.membershipType?.code) && heartHealthMembership) {
      Modal.warning({
        title: "Cannot Cancel Membership First",
        content: "Core or Premium Membership is required to continue using your Heart Health Add-On. If you want to cancel everything, please first cancel the Heart Health Add-On, and then cancel the Core or Premium membership.",
        okText: "Understood",
      });
      return; // Prevent further execution
    }

  
    Modal.confirm({
      title: `Are you sure you want to cancel your membership?`,
      content: `After cancellation, your membership will stay active until the remaining value is fully used. Once this period ends, all membership benefits will stop.`,
      onOk: async () => {
        setCanceling([...cancelingRef.current, membership._id]);

        try {
          const updatedMembership = await cancelMembership(membership._id, {
            select: 'status startAt endAt',
            populate: [{
              path: 'membershipType',
              select: 'code',
            }],
          });
          message.info('Membership canceled');
          onMembershipPaymentUpdate(updatedMembership);
        } catch (err) {
          message.error('Failed to cancel membership');
        }

        setCanceling(cancelingRef.current.filter((membershipId) => membershipId !== membership._id));
      }
    });
  }

  const onResumeMembership = async (membership) => {

    setResuming([
      ...resumingRef.current,
      membership._id
    ])
    try {
      const updatedMembership = await resumeMembership(membership._id, {
        select,
        populate
      })
      onMembershipPaymentUpdate(updatedMembership)
      message.success('Membership reactivated')
    } catch (err) {
      message.error('Failed to reactivate membership')
    }
    setResuming(resumingRef.current.filter(membershipId => membershipId !== membership._id))
  }

  const ActionButton = ({membershipType, membership, path}) => {
    const [loading, setLoading] = useState(false);
    const hasMembership = membership?.membershipType?.code === membershipType?.code;

    const handleMembershipChange = async () => {
      setShowPayMembershipModal(true);
    };

    if (hasMembership) {
      const hasExpiringMembership = MembershipHelper.isExpiring(currentMembership);
      if (hasExpiringMembership) {
        return <Button 
          type="primary"
          className="join-btn"
          onClick={() => onResumeMembership(membership)}
          loading={resumingRef.current?.includes(membership._id)}
          disabled={loading}
        >
          Reactivate Membership
        </Button>
      } else {
        return (
          <div className="button-group">
            <Row gutter={[8, 8]}>
            <Col xs={24} sm={12}>
                <Button
                  type="default"
                  icon={<CreditCardOutlined />}
                  disabled={loading}
                  onClick={handleMembershipChange} 
                  className="join-btn"
                  block
                >
                  Update Payment
                </Button>
              </Col>
              <Col xs={24} sm={12}>
                <Button
                  type="default"
                  className="cancel-btn"
                  icon={<CloseCircleOutlined />}
                  onClick={() => onCancelMembership(membership)}
                  loading={cancelingRef.current?.includes(membership._id)}
                  disabled={loading}
                  block
                >
                  Cancel {membershipType?.isAddOn ? "Add-on" : "Membership"}
                </Button>
              </Col>

            </Row>
          </div>
        );
      }
    }


    const isDowngrade = MembershipHelper.isDowngrade(membershipType, currentMembership);
    const isUpgrade = MembershipHelper.isUpgrade(membershipType, currentMembership);

    if (isUpgrade || isDowngrade) {
      return (
        <Button
          type="primary"
          className={isUpgrade ? "join-btn" : "downgrade-btn join-btn"}
          onClick={handleMembershipChange} 
          loading={loading}
          disabled={loading}
        >
        {isUpgrade ? "Upgrade To This" : "Downgrade To This"}
      </Button>
      );      
    }

    return (<>
      <Button
        type={membershipType?.isAddOn ? "default" : "primary"}
        className={membershipType?.isAddOn ? "learn-more-btn" : "join-btn"}
        onClick={membershipType?.isAddOn || path ? () => navigate(path) : handleMembershipChange}
        disabled={loading}
      >
        {membershipType?.isAddOn ? "Learn More" : "Join Now"}
      </Button>
    </>)
  }

  const hasExpiringMembership = MembershipHelper.isExpiring(currentMembership);
  const existingMembership = MembershipHelper.existingMembership(currentMembership, membershipType);

  return membershipType && (
    <div className={`membership-plan-container ${existingMembership ? 'active' : ''}`}>
      <PayMembershipModal
        membershipType={membershipType}
        currentMembership={currentMembership}
        payMembershipModalVisible={showPayMembershipModal}
        setPayMembershipModalVisible={setShowPayMembershipModal}
        onMembershipPaymentUpdate={onMembershipPaymentUpdate}        
      />

      <div className="membership-plan-title">
        {shortenTitle ? <>{membershipType?.title.replace(" Membership","").replace(" Add-on","")}</>: <>{membershipType?.title}</>}{" "}

        </div>
        <div className="membership-plan-description">{membershipType.tagline}</div>
        <div className="membership-plan-price">
          {membershipType.isAddOn && <>+</>}
          ${membershipType?.isAnnual ? Math.round(membershipType.cost / 12) : membershipType?.cost} <span>per month</span>
        </div>
        <div className="membership-plan-cta">
          {existingMembership && hasExpiringMembership && currentMembership.endAt && 
            <Alert 
              description={`Your membership will end on ${moment(currentMembership.endAt).format('MMM D, YYYY')}.`} 
              type="warning" />
          }
          <ActionButton membershipType={membershipType} membership={currentMembership} path={path}/>
        </div>

        {membershipType?.features?.length && 
          <div className="membership-features-container">
            <div className="feature-item">Includes the following:</div>

            {membershipType?.features.map(({ title, description, key }) => (
              <div key={key} className="feature-item">
                <Popover content={description} title={title} trigger="click" placement="topLeft">
                   
                    <div className="title">
                    <InfoCircleOutlined className="checkmark"/> {title}
                    </div>
              
                </Popover>
              </div>
            ))}
          </div>
        }
    </div>);
      

}