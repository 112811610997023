import { useEffect } from 'react'
import { Form, Typography } from "antd"
import { Selector } from 'antd-mobile'
import { CheckOutlined, WarningFilled } from '@ant-design/icons'
import "./singleSelect.scss"
import { FlowStepFooter } from '../../flowStep/flowStepFooter.component'
import _ from 'lodash'

const { Item } = Form
const { Text } = Typography

export const SingleSelect = ({
  step,
  onSubmit,
  form,
  isSubmitting,
}) => {
  const fieldWatch = Form.useWatch(step?.field.name, form)
  const letters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <div className="single-select">
      <Form
        form={form}
        className="single-select-form"
      >
        <Item 
          key={`field-${step.field.name}`}
          name={step.field.name}
          className="single-select-item"
          validateTrigger='onSubmit'
          rules={[{ 
            required: true, 
            message: <><WarningFilled />&nbsp; Please select an option</>,
          }]}
        >
          <Selector 
            onChange={value => {
              if (value?.length) {
                onSubmit()
              }
            }}
            options={step.field.options.map(({ label, value }, optionIndex) => {
              return {
                value,
                label: (
                  <div className="single-select-label">
                    <div className="single-select-abbr">
                      {step.field.options?.length > 2 ? letters[optionIndex] : label[0]}
                    </div>

                    <Text className="single-select-text">
                      {label}
                    </Text>

                    <CheckOutlined 
                      className="single-select-check"
                    />
                  </div>
                ),
              }
            })}
            className="single-select-field"
          />
        </Item>
        
        {fieldWatch?.length > 0 && (
          <FlowStepFooter 
            onSubmit={() => onSubmit()} 
            isSubmitting={isSubmitting}
          />
        )}
      </Form>
    </div>
  )
}