import React, { useState, useEffect, useContext } from 'react';
import { Card, Typography, Button, Modal } from 'antd';
import { DeleteOutlined, CheckOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { cardLogos } from '../../utils/cardLogos';
import { listCards, removeCard } from '../../services/card.service';
import { CardModal } from '../cardModal/cardModal.component';
import { UserContext } from '../../contexts/user.context';  
import MembershipHelper from '../../helpers/membership.helper';
import Role from '../../enums/role.enum';
import './paymentMethods.scss';
const { Text } = Typography;

const PaymentMethods = ({ activeCardId, onSetDefault, flow }) => {

    const [cards, setCards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [openCardModal, setOpenCardModal] = useState()
    const [chargePersonalCard, setChargePersonalCard] = useState()
    const { currentUser, memberships, token } = useContext(UserContext)

    
    useEffect(() => {
        fetchCards();
    }, []);

    useEffect(() => {
        setChargePersonalCard(getChargePersonalCard());
      }, [currentUser])
    
    
    
    const fetchCards = async () => {
        if (token) {        
            try {
                setIsLoading(true);
                const data = await listCards({
                select: '_id last4 brand expMonth expYear',
                filter: {
                    isRemoved: false
                },
                flow: flow?._id,
                populate: []
                })
                setCards(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        }

    };

    const onCardSuccess = async () => {
        try {
            setIsLoading(true);
            await fetchCards();
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    }

    const onDeleteCard = async (cardId) => {

        // first make sure card is is not being used by membership
        const isUsedByMembership = memberships.some(membership => membership.card?._id?.toString() === cardId && MembershipHelper.isActive(membership) && !MembershipHelper.isExpiring(membership))

        if (isUsedByMembership) {
            Modal.error({
                title: 'Cannot Remove Card',
                content: 'This card is being used by a membership and cannot be removed. Please update your membership first.'
            });
            return;
        }

        else {
            Modal.confirm({
                title: 'Confirm Removal',
                content: 'Are you sure you want to remove this payment method?',
                okText: 'Yes, remove',
                cancelText: 'Cancel',
                onOk: async () => {
                    await removeCard(cardId);
                    await fetchCards();
                }
            });
    
        }

    }

    const getChargePersonalCard = () => {
        if (currentUser?.role === Role.ADMIN) return false
        if (currentUser?.role === Role.PROVIDER && !currentUser?.chargePersonalCard) return false
        return true
    }
    
    return (
        <div className="payment-methods-container">

            <CardModal
                open={openCardModal}
                setOpen={setOpenCardModal}
                onSuccess={onCardSuccess}
                flow={flow}
            />

            {cards.map((card) => (
            <Card 
                key={card._id} 
                className={`saved-card ${card?._id === activeCardId ? 'active' : ''}`}
                onClick={onSetDefault ? () => {
                    activeCardId = card?._id
                    onSetDefault(card?._id);
                } : undefined}
            >
                <div className="card-content">
                    <div className="card-info">
                        {/* Card Logo */}
                        <div className="card-brand">
                        {cardLogos[card.brand?.toLowerCase().replace(/ /g, '_')]}
                        </div>
                        
                        {/* Card Details */}
                        <div className="card-details">
                        <Text strong>•••• {card.last4}</Text>
                        <Text type="secondary">Expires {card.expMonth}/{card.expYear}</Text>
                        </div>
                    </div>

                    {/* Card Actions */}
                    <div className="card-actions">
                        {card?._id === activeCardId && (
                            <CheckOutlined/>
                        )}

                        {!activeCardId && <Button 
                            type="link" 
                            size="small"
                            icon={<DeleteOutlined />}
                            onClick={() => onDeleteCard(card?._id)}
                        >
                                Remove
                            </Button>
                        }
                    </div>
                </div>
            </Card>
            ))}


            {/* Add New Payment Method Card */}            
            <Card className="saved-card add-card" onClick={() => setOpenCardModal(true)}>
                <div className="card-content">
                    <div className="card-info">
                        <div className="card-brand">
                            <PlusCircleOutlined style={{ fontSize: '24px' }} />
                        </div>
                        <div className="card-details">
                            <Text strong>Add Debit/Credit Card</Text>
                        </div>
                    </div>
                </div>
            </Card>
         
        </div>
    );
};

export default PaymentMethods;