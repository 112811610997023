import { Modal } from 'antd'
import './allergyModal.scss'

export const AllergyModal = ({ open, setOpen }) => {
  const onCancel = () => {
    setOpen(false)
  }

  const allergies = [
    `Buckwheat`,
    `Corn`,
    `Oat`,
    `Rice`,
    `Sesame`,
    `Soybean`,
    `Wheat`,
    `Egg white`,
    `Cow's milk`,
    `Almond`,
    `Brazil nut`,
    `Coconut`,
    `Hazelnut`,
    `Peanut`,
    `Blue mussel`,
    `Codfish`,
    `Salmon`,
    `Shrimp`,
    `Tuna`,
  ]

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className="allergy-modal"
      title={'The Allergy Test'}
      footer={null}
      width={950}
    >
      <ul>
        {allergies.map((allergy, index) => (
          <li key={`allergy-${index}`}>
            {allergy}
          </li>
        ))}
      </ul>
    </Modal>
  )
}