import { Typography, Row, Col, Tag }  from 'antd'
import Breakpoint from "../../enums/breakpoint.enum";
import useWidth from '../../hooks/useWidth.hook.js';

const { Text, Paragraph } = Typography

export const PrenuvoIntro = ({ footer, productType }) => {
  const width = useWidth()

  const instructions = [
    {
      sections: [{
        title: 'Book Scan',
        description: <>After filling out your info and payment, Instalab Concierge will schedule your scan at the closest Prenuvo location to you.</>
      }]
    }, 
    {
      sections: [{
        title: 'Get Scan Done',
        description: <>Prenuvo's clinics are calm and welcoming. Sit back, relax and watch a show or listen to beats while Prenuvo's MRIs do their magic.</>
      }]
    }, 
    {
      sections: [{
        title: 'Review Your Results',
        description: <>Prenuvo provides a specialist to walk you through your results. You can also book an appointment with one of our longevity physicians.</>
      }]
    }
  ]

  return (
    <div 
      className="flow-intro"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >

      <div 
        className="flow-intro-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <Paragraph 
          className="flow-title"
          style={{
            fontSize: width > Breakpoint.SM ? 32 : 28,
          }}
        >
           {productType?.title} 
        </Paragraph>

        <Paragraph>
        <div className="flow-product-pricing">
                              <span className="product-original-price">${productType?.cost}</span>
                              <span> or </span>
                              <span className="product-member-price">${productType?.memberCost}</span>
                              <Tag className="member-tag">For Members</Tag>
                            </div> 

        </Paragraph>
        <Paragraph 
          className="flow-description"
          style={{
            fontSize: width > Breakpoint.SM ? 16 : 14,
          }}
        >
          Prenuvo helps detect cancers and other conditions blood tests cannot. 1 in 20 scans result in a potentially life-saving diagnosis.
          
        </Paragraph>

        <div 
          className="instruction-list"
          style={{
            marginTop: width > Breakpoint.SM ? 50 : 30,
            marginBottom: width > Breakpoint.SM ? -15 : -25,
            paddingTop: width > Breakpoint.SM ? 25 : 15,
          }}
        >
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row gutter={10}>
                <Col
                  xs={{ span: 5 }}
                  sm={{ span: 4 }}
                  md={{ span: 3 }}
                  lg={{ span: 3 }}
                  xl={{ span: 3 }}
                  xxl={{ span: 3 }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  xs={{ span: 19 }}
                  sm={{ span: 20 }}
                  md={{ span: 21 }}
                  lg={{ span: 21 }}
                  xl={{ span: 21 }}
                  xxl={{ span: 21 }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>

        <div className="flow-intro-btn-container">
          <div className="flow-intro-btn">
            {footer({ 
              buttonText: "Let's go",
              buttonCheck: false,
              buttonStyle: {
                height: 50,
                fontSize: 18,
                width: '100%',
                display: 'block',
              },
              enterStyle: {
                display: 'none',
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
