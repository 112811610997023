import { useContext } from "react";
import { Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { UserContext } from "../../../contexts/user.context";
import PosthogHelper from "../../../helpers/posthog.helper";
import ReactAudioPlayer from 'react-audio-player';
import { StickyBlogHeader } from "./stickyBlogHeader.component";

const { Paragraph } = Typography


export const OverexercisePost = () => {
  const { currentUser } = useContext(UserContext)
  const { blogId } = useParams()
  const navigate = useNavigate()

  return (
    <div className={classNames("blog-post", "overexercise-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          Can you exercise too much?
        </Paragraph>

        <ReactAudioPlayer
          src="/audio/overexercise.wav"
          controls
          className="blog-audio"
          onPlay={() => {
            PosthogHelper.track(currentUser, `[blog] play audio: ${blogId}`, true)
          }}
        />

        <Paragraph className="blog-subheading">
          We all get it—exercise isn't just good, it's foundational for longevity. But what about those of us pushing the limits, marathon after marathon, triathlon after triathlon? Can training for years at high intensity come with its own risks?
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt="Nov 5, 2024"
        readTime="3 Min Read"
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          When Fitness Isn't Straightforward
        </Paragraph>
        <Paragraph className="blog-description">
          A <a className="blog-link" onClick={() => window.open('https://www.ahajournals.org/doi/pdf/10.1161/CIRCULATIONAHA.116.026964', '_blank')}>2017 study in <i>Circulation</i></a> sent ripples through the endurance community. Researchers focused on <i>masters athletes</i>—seasoned individuals over 40 who'd been logging serious miles for decades. Their profiles were pristine: no hypertension, no dyslipidemia. 
        </Paragraph>
        <Paragraph className="blog-description">
          Yet, the data revealed something unexpected. Male athletes had more plaque in their coronary arteries than their non-athlete counterparts. The kicker? The plaques were more calcified, which typically signals stability and a lower chance of rupture.
        </Paragraph>
        <Paragraph className="blog-description">
          This paradox opened a complex dialogue: Why are highly active, metabolically healthy individuals accumulating more arterial plaque?
        </Paragraph>

        <Paragraph className="blog-title">
          Theories Behind the Numbers
        </Paragraph>
        <Paragraph className="blog-description">
          The current thinking involves cumulative physical stress. Years of relentless heart-pumping and arterial strain might lead to adaptive calcification—a sort of biological reinforcement. Other suspects? Oxidative stress and inflammation, both byproducts of chronic, high-intensity training, could drive the body to shield its vessels with calcium deposits.
        </Paragraph>

        <Paragraph className="blog-title">
          The Pushback and Unanswered Questions
        </Paragraph>
        <Paragraph className="blog-description">
          The findings sparked debate. Cardiologist Axel Schmermund's response dissected potential biases: Were the athletes who volunteered already worried about their heart health? Did non-standard definitions for family history skew the results? The original study authors stood firm, asserting that the type of calcification found was notable and couldn't be dismissed.
        </Paragraph>
        <Paragraph className="blog-description">
          The more profound issue is what this means for endurance athletes. Is plaque buildup a natural, even protective adaptation, or is it the body signaling distress? The presence of myocardial scarring in some athletes complicates this, hinting at possible long-term damage.
        </Paragraph>

        <Paragraph className="blog-title">
          Beyond the Outliers: General Takeaways
        </Paragraph>
        <Paragraph className="blog-description">
          Even as we untangle the nuances, the broader message hasn't changed: moderate exercise remains a powerful tool for cardiovascular health, boosting endothelial function, reducing inflammation, and optimizing lipid profiles. But for endurance athletes, the possibility of a U-shaped benefit curve—where extreme training could plateau or even harm—warrants attention.
        </Paragraph>

        <Paragraph className="blog-title">
          The Bottom Line
        </Paragraph>
        <ol className="blog-list">
          <li>
            <strong>Mind Your Metrics</strong>: For those regularly pushing VO2 max thresholds, we recommend tracking arterial health. <a className="blog-link" onClick={() => {
              PosthogHelper.track(currentUser, `[blog] click ${blogId} cta: schedule cac`, true)
              navigate('/cac')
            }}>Calcium scans</a> and <a className="blog-link" onClick={() => {
              PosthogHelper.track(currentUser, `[blog] click ${blogId} cta: order heart health test`, true)
              navigate('/heart-health-test')
            }}>other cardiac biomarkers</a> could provide early insights.
          </li>
          <li>
            <strong>Listen to Your Body</strong>: Chronic fatigue, stubborn inflammation, and erratic recovery can be signals. It's not just about doing more; it's about doing it smarter.
          </li> 
          <li>
            <strong>Balance is Key</strong>: If you're chasing longevity, remember that fitness is just one piece of the puzzle. Recovery, nutrition, and stress management matter just as much.
          </li>
        </ol>
        <Paragraph className="blog-description">
          Exercise is undoubtedly vital, but like anything powerful, it needs balance. The frontier of personalized exercise medicine promises deeper insights—until then, informed moderation might be the best hack for long-term heart health.
        </Paragraph>
      </div>
    </div>
  )
}