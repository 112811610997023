import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useState, useContext, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import { 
  Gender,
  MedicalCondition, 
  MembershipTypeCode,
  ProductTypeCode,
  SleepQuality 
} from "../../enums/index.enum"
import { QuizRecommendation } from "./custom/quizRecommendation/quizRecommendation.component"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service"

export const Step = {
  INTRO: 'intro',
  GENDER: 'gender',
  DOB: 'dob',
  MEDICAL_CONDITIONS: 'medical-conditions',
  FAMILY_HISTORY: 'family-history',
  FAMILY_HISTORY_CANCER: 'family-history-cancer',
  SMOKING: 'smoking',
  CURRENT_SMOKING: 'current-smoking',
  SLEEP_QUALITY: 'sleep-quality',
  SLEEP_HOURS: 'sleep-apnea',
  TOXIC: 'toxic',
  MOLD: 'mold',
  ACCOUNT: 'account',
  LAST_BLOOD_TEST: 'blood-test',
  RECOMMENDATION: 'recommendation',
}

export const QuizFlow = () => {
  const { instalabMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()

  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, hasPass, productTypes, instalabMembership, flow, initialUser])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.HEART_HEALTH,
          ]
        }
      }
    }))
  }
  
  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.HEART_HEALTH_TEST_KIT,
            ProductTypeCode.GALLERI,
            ProductTypeCode.LONGEVITY_PANEL,
            ProductTypeCode.CONSULT_HEART_HEALTH,
            ProductTypeCode.CONSULT_LONGEVITY,
          ]
        }
      }
    }))
  }

  const cancerConditions = [
    MedicalCondition.CANCER_BREAST,
    MedicalCondition.CANCER_COLORECTAL,
    MedicalCondition.CANCER_ENDOMETRIAL,
    MedicalCondition.CANCER_KIDNEY,
    MedicalCondition.CANCER_OVARIAN,
    MedicalCondition.CANCER_PANCREATIC,
    MedicalCondition.CANCER_PROSTATE,
    MedicalCondition.CANCER_SKIN,
    MedicalCondition.CANCER_THYROID
  ]

  const familyHistoryConditions = [
    MedicalCondition.BLOCKED_ARTERY,
    MedicalCondition.CANCER,
    MedicalCondition.DEMENTIA,
    MedicalCondition.DIABETES,
    MedicalCondition.HEART_ATTACK,
    MedicalCondition.HEART_DISEASE,
    MedicalCondition.HIGH_BLOOD_PRESSURE,
    MedicalCondition.HIGH_CHOLESTEROL,
    MedicalCondition.OSTEOPOROSIS,
    MedicalCondition.PERIPHERAL_ARTERY_DISEASE,
    MedicalCondition.STROKE,
  ]

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return

    setSkeleton({
      [Step.INTRO]: {
        type: StepType.STATIC,
        title: `Welcome! `,
        description:  `To learn the most important tests you should do to stay on top of your health, we just need a little info about you. It takes less than 2 minutes.`,
        nextStep: Step.GENDER,
        buttonText: "Let's go!",
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your biological sex?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.FAMILY_HISTORY,
        buildUser: true,
        title: `What's your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },
      [Step.FAMILY_HISTORY]: {
        type: StepType.MULTIPLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.familyHistory?.includes(MedicalCondition.CANCER)) {
            return Step.FAMILY_HISTORY_CANCER
          }
          else {
            return Step.SMOKING
          }
        },
        buildUser: true,
        title: <>Which medical conditions has your immediate family been diagnosed with <u>before age 60</u>?</>,
        description: 'This includes grandparents, parents, or siblings. Select all that apply.',
        field: {
          name: 'familyHistory',
          options: familyHistoryConditions
                      .map(value => ({label: value, value}))
                      .sort((a, b) => a.label.localeCompare(b.label))
        },
      },
      [Step.FAMILY_HISTORY_CANCER]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.SMOKING,
        buildUser: true,
        title: `What cancers has your immediate family been diagnosed with?`,
        description: 'Select all that apply.',
        field: {
          name: 'familyHistory',
          options: [...Object.values(cancerConditions)
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label)), {label:'Other', value: 'Other'}]
        },
      },
      [Step.SMOKING]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        onNextStep: async (patient) => {
          if (patient?.hasPreviousSmoking) {
            return Step.CURRENT_SMOKING
          } else {
            return Step.SLEEP_QUALITY
          }
        },
        title: `Have you ever smoked or vaped nicotine?`,
        field: {
          name: 'hasPreviousSmoking',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        }
      },
      [Step.CURRENT_SMOKING]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.SLEEP_HOURS,
        title: `Do you currently smoke or vape nicotine?`,
        field: {
          name: 'hasSmoking',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        }
      },
      [Step.SLEEP_HOURS]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.SLEEP_QUALITY,
        buildUser: true,
        title: `How many hours do you typically sleep per night?`,
        field: {
          name: 'sleepHours',
          placeholder: 'Type hours here ...',
        },
      },
      [Step.SLEEP_QUALITY]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.TOXIC,
        title: `How would you characterize your sleep quality?`,
        field: {
          name: 'sleepQuality',
          options:Object.values(SleepQuality).map(value => ({label: value, value: value}))
        }
      },
      [Step.TOXIC]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.ACCOUNT,
        title: `Have you had potential exposure to toxic metals through your occupation, environment, or diet?`,
        description: 'This includes heavy metals such as arsenic, lead and mercury.',
        field: {
          name: 'possibleToxicExposure',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        }
      },
      // [Step.MOLD]: {
      //   type: StepType.SINGLE_SELECT,
      //   buildUser: true,
      //   nextStep: Step.ACCOUNT,
      //   title: `Have you had potential exposure to mold through your occupation or environment?`,
      //   description: 'Molds can excrete toxic compounds called mycotoxins, secondary metabolites produced by fungi under certain conditions.',
      //   field: {
      //     name: 'possibleMoldExposure',
      //     options: [{
      //       label: 'Yes',
      //       value: true,
      //     }, {
      //       label: 'No',
      //       value: false,
      //     }]
      //   }
      // },

      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.RECOMMENDATION,
        addFreeProduct: true,
        productType: ProductTypeCode.TEST_MATRIX,
        buildUser: true,
        title: `Create your Instalab acccount.`,
        description: `Your recommended tests will be available in your account.`,
        fields: [
          {
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.RECOMMENDATION]: {
        type: StepType.CUSTOM,
        content: () => (
          <QuizRecommendation flow={flow} />
        ),
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
    />
  )
}