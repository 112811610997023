import { useContext, useState } from "react";
import { Typography, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import PosthogHelper from "../../../helpers/posthog.helper";
import classNames from "classnames";
import { UserContext } from "../../../contexts/user.context";
import { SubscribeModal } from "../../subscribeModal/subscribeModal.component";
import { StickyBlogHeader } from "./stickyBlogHeader.component";

const { Paragraph } = Typography;

export const ExercisePost = () => {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const { blogId } = useParams();

  return (
    <div className={classNames("blog-post", "exercise-post")}>
      <SubscribeModal
        open={openSubscribe}
        setOpen={setOpenSubscribe}
      />

      <div className="blog-intro">
        <Paragraph className="blog-heading">
          What's the best exercise routine for busy people seeking longevity?
        </Paragraph>

        <Paragraph className="blog-subheading">
          When time is tight, every minute counts—especially for your health. If you're looking to make the most of 30-60 minutes a day to strengthen your heart and boost your longevity, you're in good company. 
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt="Nov 16, 2024"
        readTime="7 Min Read"
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          The Power of Resistance Training—Even Just Once a Week
        </Paragraph>
        <Paragraph className="blog-description">
          A <a onClick={() => window.open('https://doi.org/10.1249/MSS.0000000000001834', '_blank')} className="blog-link">study published in JAMA</a> explored the impact of resistance training (RT) on cardiovascular disease (CVD) and mortality. The findings were significant: just one session per week of resistance exercise was associated with a <strong>40% to 70%</strong> reduction in cardiovascular events, such as heart attacks and strokes.
        </Paragraph>
        <Paragraph className="blog-description">
          Even more compelling, this study showed that weekly resistance training was linked to a <strong>29%</strong> reduction in all-cause mortality—a nearly one-third decrease in the risk of death from any cause, simply by incorporating resistance exercises weekly.
        </Paragraph>

        <Paragraph className="blog-description">
          Resistance training includes weightlifting, bodyweight exercises like push-ups and squats, and resistance bands—any exercise where your muscles work against a force.
        </Paragraph>

        <Paragraph className="blog-title">
          Striking the Right Balance in Resistance Training
        </Paragraph>
        <Paragraph className="blog-description">
          Interestingly, the <i>JAMA study</i> found that the benefits of resistance training followed a "U-shaped relationship"—the greatest health benefits came from one to three sessions per week. Doing more than three sessions did not add additional benefits and could even increase cardiovascular strain. To maximize benefits without overburdening your heart, aim for <strong>one to three well-rounded sessions weekly</strong>.
        </Paragraph>

        <Paragraph className="blog-title">
          Cardio: Moderate vs. High-Intensity—What's Better for Your Heart?
        </Paragraph>
        <Paragraph className="blog-description">
          Resistance training is powerful, but cardio still plays a crucial role in heart health. Research has compared two main types:
        </Paragraph>

        <ul className="blog-list">
          <li>
            <strong>Moderate Continuous Training (MCT):</strong> Traditional steady-state cardio like brisk walking or cycling, where effort is sustained over time.
          </li>
          <li>
            <strong>High-Intensity Interval Training (HIIT):</strong> Short bursts of intense effort, such as sprinting, followed by rest periods.
          </li>
        </ul>

        <Paragraph className="blog-title">
          Both Approaches Are Effective
        </Paragraph>
        <Paragraph className="blog-description">
          The <a onClick={() => window.open('https://doi.org/10.1161/CIRCULATIONAHA.116.022424', '_blank')} className="blog-link"><i>SMARTEX Heart Failure Study</i></a> showed that both MCT and HIIT led to similar improvements in heart function. Participants in both groups saw gains in VO₂ max (a measure of oxygen use and fitness) and improved heart pumping ability. In other words, both MCT and HIIT are effective for heart health, so pick the one that suits your style and schedule.
        </Paragraph>

        <Paragraph className="blog-title">
          Additional Evidence for HIIT's Effectiveness
        </Paragraph>
        <Paragraph className="blog-description">
          A systematic review in <i>British Journal of Sports Medicine</i> quantified HIIT's benefits further. HIIT improved VO₂ max—a crucial marker of cardiovascular fitness—by <strong>9.1%</strong>, nearly double the gains seen with moderate-intensity continuous training (MCT). For those who enjoy intense workouts and can maintain the pace, HIIT may provide additional cardiovascular advantages over traditional cardio.
        </Paragraph>

        <Paragraph className="blog-title">
          The Real-World Catch with HIIT
        </Paragraph>
        <Paragraph className="blog-description">
          While HIIT's intensity is appealing for its efficiency, the study highlighted a common challenge: many participants found it hard to maintain the high intensity over time, especially outside a supervised setting. So while HIIT is effective, MCT may be more sustainable for long-term heart health. The bottom line? The best exercise is the one you can stick with consistently.
        </Paragraph>

        <Paragraph className="blog-title">
          Building a Balanced Routine: Cardio + Resistance for Maximum Heart Health
        </Paragraph>
        <Paragraph className="blog-description">
          So, what does this mean for someone who has just 30-60 minutes a day? Here's a balanced weekly plan based on the evidence:
        </Paragraph>

        <Paragraph className="blog-title">
          A Sample Weekly Routine
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>2-3 Days of Resistance Training:</strong> Focus on compound exercises like squats, lunges, push-ups, and planks to target major muscle groups. Each session can last 20-30 minutes. Just one session weekly is beneficial, but aiming for up to three maximizes heart and longevity benefits.
          </li>
          <li>
            <strong>3-4 Days of Cardio:</strong> Alternate between MCT (e.g., brisk walking or cycling) for 30-45 minutes, and HIIT if you prefer a shorter, high-intensity session. Aim for 3-4 intense intervals of 3-4 minutes each.
          </li>
        </ul>

        <Paragraph className="blog-title">
          Key Takeaways for a Heart-Healthy Routine
        </Paragraph>
        <Paragraph className="blog-description">
          In summary, here's how to make the most of limited workout time:
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>Resistance Training:</strong> One to three sessions per week significantly reduce cardiovascular risk and overall mortality.
          </li>
          <li>
            <strong>Cardio:</strong> Both moderate and high-intensity cardio benefit heart health. Pick the approach you enjoy and can stick with.
          </li>
          <li>
            <strong>Balance and Consistency:</strong> A mix of resistance and moderate cardio is ideal for heart health and longevity.
          </li>
          <li>
            <strong>Sustainability:</strong> The best exercise routine is one you'll maintain, so focus on finding what fits your lifestyle.
          </li>
        </ul>
        <Paragraph className="blog-description">
          Fitness doesn't have to mean perfection. Even small, consistent efforts can add up to big gains in heart health and longevity. Whether you prefer a brisk walk, a set of squats, or an intense HIIT session, the key is finding what works for you—and making it part of your life.
        </Paragraph>

        <br/>

        <Paragraph className="blog-description">
          To keep up to date with the latest longevity research, <a onClick={() => {
            PosthogHelper.track(currentUser, `[blog] click subscribe btn: ${blogId}`, true)
            setOpenSubscribe(true)
          }} className="blog-link">subscribe to our newsletter</a>.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Zhao, M., Veeranki, S. P., Li, S., & Wang, Y. (2019). Muscle-strengthening physical activity and mortality in US adults: The National Health Interview Survey 1997–2001. Medicine & Science in Sports & Exercise, 51(4), 605-613. <a onClick={() => window.open('https://doi.org/10.1249/MSS.0000000000001834', '_blank')}>https://doi.org/10.1249/MSS.0000000000001834</a>
            </li>
            <li>
              Pattyn, N., Beulque, R., & Cornelissen, V. A. (2018). Aerobic interval training vs. moderate continuous training in coronary artery disease patients: A systematic review and meta-analysis. Sports Medicine, 48(4), 893-907. <a onClick={() => window.open('https://doi.org/10.1007/s40279-018-0871-5', '_blank')}>https://doi.org/10.1007/s40279-018-0871-5</a>
            </li>
            <li>
              Stensvold, D., et al. (2020). Effect of exercise training for five years on all-cause mortality in older adults—the Generation 100 study: Randomized controlled trial. BMJ, 371, m3485. <a onClick={() => window.open('https://doi.org/10.1136/bmj.m3485', '_blank')}>https://doi.org/10.1136/bmj.m3485</a>
            </li>
            <li>
              Ellingsen, Ø., et al. (2017). High-intensity interval training in heart failure patients: The SMARTEX Heart Failure study. Circulation, 135(9), 839-849. <a onClick={() => window.open('https://doi.org/10.1161/CIRCULATIONAHA.116.022424', '_blank')}>https://doi.org/10.1161/CIRCULATIONAHA.116.022424</a>
            </li>
            <li>
              Kraus, W. E., et al. (2002). Effects of the amount and intensity of exercise on plasma lipoproteins. New England Journal of Medicine, 347(19), 1483-1492. <a onClick={() => window.open('https://doi.org/10.1056/NEJMoa020194', '_blank')}>https://doi.org/10.1056/NEJMoa020194</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
