import { useState, useEffect, useContext } from 'react';
import { Button, Col, Row, Typography, Tag, Tooltip } from 'antd';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import { listProductTypes } from '../../services/productType.service';
import { ReactComponent as UrineSvg } from "../../assets/svg/urine_sample.svg";
import { ReactComponent as BloodSvg } from "../../assets/svg/blood_drop.svg";
import { ReactComponent as CTSvg } from "../../assets/svg/tac.svg";
import { ReactComponent as BPSvg } from "../../assets/svg/blood_pressure.svg";
import { ReactComponent as SalivaSvg } from "../../assets/svg/saliva.svg"
import CollectionMethod from '../../enums/collectionMethod.enum';
import Breakpoint from '../../enums/breakpoint.enum';
import PosthogHelper from '../../helpers/posthog.helper';
import EventCode from '../../enums/eventCode.enum';
import './testCatalog.scss';
import useWidth from '../../hooks/useWidth.hook';
import { UserContext } from '../../contexts/user.context';
import { DownOutlined } from "@ant-design/icons";
import { addSlack } from "../../services/slack.service"
import {RightOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import Role from '../../enums/role.enum';
import ProductHelper from '../../helpers/product.helper';
import { RiseOutlined } from '@ant-design/icons';
const { Paragraph } = Typography;

const getAvailableCategories = (panels) => {
  const categories = new Set();

  panels.forEach(panel => {
    panel.category.forEach(cat => categories.add(cat));
  });

  return Array.from(categories);
};

export const TestCatalog = ({ 
  xs = 24, 
  sm = 12, 
  md = 12, 
  lg = 12, 
  xl = 8, 
  xxl = 8, 
  showAll = false, 
  provider=false 
}) => {
  const { instalabMembership, currentUser, token } = useContext(UserContext);
  const width = useWidth();
  const navigate = useNavigate()

  const [showAllState, setShowAllState] = useState(showAll);
  const [panels, setPanels] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all'); // Default to 'all'
  const [filteredPanels, setFilteredPanels] = useState()
  const [displayPanels, setDisplayPanels] = useState()
  const [availableCategories, setAvailableCategories] = useState([]);
 

  useEffect(() => {
    trackPosthog();
  }, [currentUser]);

  const trackPosthog = () => {
    if (currentUser) {
      PosthogHelper.track(currentUser, EventCode.TEST_CATALOG_VIEW);
    }
  };

  let panelCodes = [
    { code: ProductTypeCode.LONGEVITY_PANEL, category: ['all', 'longevity'] },
    { code: ProductTypeCode.CTCALCIUM, category: ['heart'] },
    { code: ProductTypeCode.GALLERI, category: ['cancer', 'longevity'] },
    { code: ProductTypeCode.WATCHPAT_ONE, category: ['heart', 'longevity','fatigue', 'performance'] },
    { code: ProductTypeCode.FERTILITY, category: ['longevity', 'fatigue'] },
    { code: ProductTypeCode.TESTOSTERONE_PANEL_KIT, category: ['fatigue', 'performance'] },
    { code: ProductTypeCode.HEART_HEALTH_TEST_KIT, category: ['heart'] },
    { code: ProductTypeCode.HEART_HEALTH_GENETIC_TEST, category: ['heart'] },
    { code: ProductTypeCode.BIOBEAT, category: ['heart'] },
    { code: ProductTypeCode.TOXIC_METALS_URINE_TEST, category: ['fatigue'] },
    { code: ProductTypeCode.MYCOTOX_PROFILE, category: ['fatigue'] },
    { code: ProductTypeCode.CLEERLY, category: ['heart'] },
    { code: ProductTypeCode.PRENUVO, category: ['cancer', 'longevity'] },
    { code: ProductTypeCode.DIURNAL_CORTISOL_PROFILE, category: ['fatigue'] },
    { code: ProductTypeCode.DEXA_BODYCOMP, category: ['heart', 'longevity'] },
    { code: ProductTypeCode.DEXA_BONE, category: ['all'] },
    { code: ProductTypeCode.VO2MAX, category: ['heart', 'longevity', 'performance'] },
    // { code: ProductTypeCode.CONSULT_LONGEVITY, category: ['longevity', 'performance'] }
    
  ];

  if (currentUser?.role === Role.PROVIDER || provider) {
    panelCodes = [
      { code: ProductTypeCode.LONGEVITY_PANEL, category: ['all', 'longevity'] },
      { code: ProductTypeCode.WATCHPAT_ONE, category: ['heart', 'fatigue'] },
    ]    
  }

  if (currentUser?.isAthlete || provider)  {
    panelCodes = [{code: ProductTypeCode.ATHLETE_PANEL, category: ['all', 'performance', 'longevity']}, ...panelCodes]
  }

  useEffect(() => {
    fetchPanels();
  }, []);

  useEffect(() => {
    const filtered = panels.filter(panel => 
      selectedCategory === 'all' || panel.category.includes(selectedCategory)
    );
    setFilteredPanels(filtered);
    setDisplayPanels(showAllState ? filtered : filtered.slice(0, 6));
  }, [selectedCategory, panels, showAllState]);

  const fetchPanels = async () => {
    const fetchedPanels = await listProductTypes({
      filter: {
        code: {
          $in: panelCodes.map(panel => panel.code)
        }
      }
    });
    const categorizedPanels = fetchedPanels.map(fetchedPanel => {
      const panelWithCategory = panelCodes.find(panel => panel.code === fetchedPanel.code);
      return { ...fetchedPanel, category: panelWithCategory.category };
    });

    // Sort panels to match the order in panelCodes
    const sortedPanels = panelCodes.map(panelCode =>
      categorizedPanels.find(panel => panel.code === panelCode.code)
    );

    setPanels(sortedPanels);
    setAvailableCategories(getAvailableCategories(sortedPanels)); 
  };

  const TestPanel = ({ test, isMostPopular, isTrending }) => {
    const handlePanelClick = async () => {
      PosthogHelper.track(currentUser, `[${EventCode.TEST_CATALOG_CLICK}] ${test.code}`);
  
      try {
        await addSlack({
          message: currentUser.firstName ? `[${currentUser.firstName} ${currentUser.lastName}] Test Catalog Click: ${test.code}` : `Test Catalog Click: ${test.code}`,
          channel: process.env.REACT_APP_PATIENT_SLACK
        });
      } catch (error) {
        console.error('Failed to log message to Slack:', error);
      }
  
      if (currentUser?.role === Role.PROVIDER) {
        navigate(`/pro-flow/${test.proFlowType}`);
      }
      else if (test.path) {
        navigate(test.path);
      } else if (test.flowType) {
        navigate(`/flow/${test.flowType}`);
      }
    };
  
    return (
      <div
        className={`test-catalog-item ${isMostPopular ? 'most-popular' : isTrending ? 'is-trending': ''}`}
        onClick={handlePanelClick} // Make the entire panel clickable
      >
        <div className="test-labels">
          <div className="test-label-tags">
            {isMostPopular && <Tag className="most-popular-tag">Most Popular</Tag>}
            {isTrending && <Tag className="is-trending-tag">Trending <RiseOutlined /></Tag>}
            {test.memberOnly && <Tag className="member-only tag">member only</Tag>}
            {test.selfCollection ?  <Tag className="self-collect tag">self collect</Tag> : test.atHome ? <Tag className="at-home tag">at home</Tag> : <Tag className="at-office tag">office visit</Tag>}
          </div>
          {!isMostPopular && !isTrending && <div className="test-label-icons">
            {test.collectionMethod === CollectionMethod.BLOOD && <BloodSvg className="test-label-svg blood" />}
            {test.collectionMethod === CollectionMethod.URINE && <UrineSvg className="test-label-svg urine" />}
            {test.collectionMethod === CollectionMethod.CT_SCAN && <CTSvg className="test-label-svg scan" />}
            {test.collectionMethod === CollectionMethod.MRI_SCAN && <CTSvg className="test-label-svg scan" />}
            {test.collectionMethod === CollectionMethod.FDA_DEVICE && <BPSvg className="test-label-svg device" />}
            {test.collectionMethod === CollectionMethod.SALIVA && <SalivaSvg className="test-label-svg saliva"/>}
          </div>}
        </div>
        <Paragraph className="test-title" ellipsis={1}>
          {test.title}
        </Paragraph>
        <Paragraph className="test-description" ellipsis={{ rows: width > Breakpoint.SM ? 2 : 3 }}>
          {test.tagline}
        </Paragraph>
        <div className="learn-more-button-container">

        {currentUser?.credits?.includes(test.code) ? 
          <Tag color="blue">
            {currentUser.credits.filter(credit => credit === test.code).length} free credit{currentUser.credits.filter(credit => credit === test.code).length > 1 ? 's' : ''}
          </Tag> :
          token && <div className="test-price">

            {(!test.cost || test.cost === 0 || test.memberCost === 0) ? (
              <Tooltip title="Price is based on your location. Instalab will find the best price for you.">
                Price varies by location
              </Tooltip>
            ) 
            : (currentUser?.role === Role.PROVIDER) ? 
              <>
              <span className="regular-price">${test.cost.toLocaleString()}</span>
                <span className="true-price">${ProductHelper.getProviderCost(test)}</span>
              </>
            :  instalabMembership && test.cost && test.memberCost && (test.cost > test.memberCost) ? 
            <>
              <span className="regular-price">${test.cost.toLocaleString()}</span>
                <span className="member-price">${test.memberCost.toLocaleString()}</span>
                <>{" "}<Tag className="member-tag">For Members</Tag></>
              </>
            : (
              <><span className="original-price">${test.cost.toLocaleString()}</span> 
            
              {(test.memberCost && (test.memberCost < test.cost)) && <> or <span className="member-price">${test.memberCost.toLocaleString()}</span> <Tag className="member-tag">For Members</Tag></>}</>
            )}
          </div>}
            <a className='secondary-link learn-more-button'>
            {(currentUser?.role === Role.PROVIDER) ? <>Order Now</> : <>Learn More</>} <RightOutlined style={{fontSize:10}}/>
            </a>
          </div>
      </div>
    );
  };

  return panels.length > 0 && (
    <div className="test-catalog">
      {/* Filter Buttons */}
      {panels.length>4 && <div className="filter-buttons">
        {availableCategories.includes('all') && (
          <Button className={selectedCategory === 'all' ? 'active' : ''} onClick={() => setSelectedCategory('all')}>All</Button>
        )}
        {availableCategories.includes('longevity') && (
          <Button className={selectedCategory === 'longevity' ? 'active' : ''} onClick={() => setSelectedCategory('longevity')}>Longevity</Button>
        )}
        {availableCategories.includes('heart') && (
          <Button className={selectedCategory === 'heart' ? 'active' : ''} onClick={() => setSelectedCategory('heart')}>Heart Disease</Button>
        )}
        {availableCategories.includes('cancer') && (
          <Button className={selectedCategory === 'cancer' ? 'active' : ''} onClick={() => setSelectedCategory('cancer')}>Cancer</Button>
        )}
        {availableCategories.includes('fatigue') && (
          <Button className={selectedCategory === 'fatigue' ? 'active' : ''} onClick={() => setSelectedCategory('fatigue')}>Fatigue</Button>
        )}
      </div>}

      {/* Test Panels */}
      {filteredPanels.length > 0 ? (
        <Row gutter={24}>
          {displayPanels.map((test, index) => (
            <Col key={`panel-${test.code}`} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
              <TestPanel test={test} isMostPopular={index === 0} isTrending={index !== 0 &&test.code === ProductTypeCode.CTCALCIUM} />
            </Col>
          ))}
        </Row>
      ) : (
        <div>No tests available.</div>
      )}

      {!showAllState && filteredPanels.length > 6 && (
        <div className="view-more-wrapper">
          <Button
            className="view-btn"
            onClick={() => setShowAllState(true)}
          >
            View More
            <DownOutlined className="view-btn-icon" />
          </Button>
        </div>
      )}
    </div>
  );
};
