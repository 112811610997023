import { Typography, Button, Row, Col } from 'antd'
import { PhoneOutlined, CheckOutlined } from '@ant-design/icons'
import { Questions } from '../../questions/questions.component'
import "./pro.scss";
import PhotoHelper from '../../../helpers/photo.helper';
import classNames from 'classnames';
import ProductTypeCode from '../../../enums/productTypeCode.enum'
import ProductHelper from '../../../helpers/product.helper';
import { useEffect, useState } from 'react';
import { getProductTypeByCode } from '../../../services/productType.service';
import { useNavigate } from 'react-router-dom';
import { advancedPanel } from '../../../data/advancedPanel.data';
import { PanelModal } from '../../panelModal/panelModal.component';
import FlowType from '../../../enums/flowType.enum';
import { convertMe, updateMe } from '../../../services/user.service';
import Role from '../../../enums/role.enum';
import { useContext } from 'react';
import { UserContext } from '../../../contexts/user.context';

const { Paragraph } = Typography

const quoteItems = [{
  img: 'skylor',
  content: `Instalab is my go-to blood testing service for all my clients in the US. Their lightning-fast response time and user-friendly dashboard have made it much easier to keep track of my clients' health. I can't wait for their launch in Canada.`,
  name: 'Skylor, Longevity Coach',
}, {
  img: 'steven',
  content: `Staying on top of the cardiovascular and metabolic health of my patients is effortless with Instalab. It's the most affordable and convenient option I've found, and my patients always praise how easy their blood draws are.`,
  name: 'Steven, Lipidologist',
}, {
  img: 'amanda',
  content: `The convenience of at-home blood testing with Instalab has significantly improved patient compliance with therapy. It also encourages my patients to follow up with me for futher guidance, ensuring better overal health outcomes.`,
  name: 'Amanda, Gastroenterologist',
}, ]

const accessItems = [
  `Schedule at-home blood draw appointments for your clients`,
  `Speak with an Instalab physician to discuss your client's test results`,
  `Stay in the loop with your client's tests results with email and text notifications`,
  `Exclusive 10% discount on blood tests you order for your clients`,
]

export const Pro = () => {
  const navigate = useNavigate()
  const [productType, setProductType] = useState()
  const [openPanel, setOpenPanel] = useState()
  const { setCurrentUser } = useContext(UserContext)

  const howItems = [{
    title: `Schedule Blood Draws`,
    description: <>An Instalab phlebotomist meets at your client's home to draw their blood and sends it to our clinical lab. We test <a onClick={() => setOpenPanel(true)}>60+ biomarkers</a> to track high cholesterol, diabetes, and more.</>,
    img: `schedule`,
  }, {
    title: `View Test Results`,
    description: <>Within 5 business days, view your client's test results in your dashboard. Instalab stores your client's blood temporarily in case followup tests are necessary.</>,
    img: `results`,
  }, {
    title: `Medical Specialist Support`,
    description: <>Speak with an Instalab physician to discuss your client's results. Our physicians specialize in working with health coaches on cardiovascular and metabolic issues.</>,
    img: `specialist`,
  }, {
    title: `10% Discount: All Services`,
    description: <>InstalabPro gives you offers a 10% discount on all services. When you order a blood test for your clients, you benefit from a special reduced price your clients can't get directly.</>,
    img: `discount`,
  }, {
    title: `Email / Text Notifications`,
    description: <>Stay in the loop about your clients' blood draw appointments and test results. You can also email Instalab with any questions regarding blood testing and we'll respond quickly.</>,
    img: `notifications`,
  }, {
    title: `Study Biomarker Trends`,
    description: <>See how your client's biomarkers change due to diet, exericse, sleep and more. Personalize health recommendations over time based on what actually works.</>,
    img: `trend`,
  }, ]

  useEffect(() => {
    fetchProductType()
  }, [])

  const onChat = () => { 
    window.FrontChat("show"); 
  }

  const fetchProductType = async () => {
    setProductType(await getProductTypeByCode(ProductTypeCode.LONGEVITY_PANEL))
  }

  const questionItems = [{
    question: `What biomarkers does Instalab test for?`,
    answer: <>
      Our <a onClick={() => setOpenPanel(true)}>Longevity Panel</a> contains 60+ biomarkers and has been carefully curated by medical experts from diverse specialties, providing a comprehensive assessment of your client's ever-evolving health.
      <br/><br/>
      Additionally you can add on many other biomarkers to this panel.
    </>,
  }, {
    question: `How much does InstalabPro cost?`,
    answer: <>There is no fee to use InstalabPro tools and support. You just pay for any test you order for your client and as a practitioner, you'll get 10% off any tests. For example, our well known Longevity Panel would just be {ProductHelper.formatDiscountCost(productType)}, which includes at-home phlebotomy, test results, and reports. If you have any questions, you can speak with one of our physicians.</>,
  }, {
    question: `What locations do Instalab phlebotomists service?`,
    answer: <>We are available in all 50 states in the US.</>,
  }, {
    question: `How will Instalab communicate with my clients?`,
    answer: <>We act as the lab testing "team" for your practice. All our communications will be solely related to appointment logistics and new results.</>,
  }, ]

  const onIntroCall = () => {
    window.Calendly.initPopupWidget({ url: 'https://calendly.com/d/4kj-7q2-bnb?hide_gdpr_banner=1' });
  }

  const onSignup = async () => {
    const updatedCurrentUser = await convertMe({
      fields: {
        role: Role.PROVIDER,
      },
      select: '_id role'
    })
    console.log('updatedCurrentUser', updatedCurrentUser)
    setCurrentUser(cachedCurrentUser => {
      return {
        ...cachedCurrentUser,
        role: Role.PROVIDER
      }
    })
    navigate(`/provider-flow/${FlowType.PRO_SIGNUP}`)
  }

  return (
    <div className="pro">
      <PanelModal
        panel={advancedPanel}
        title="The Instalab Blood Panel"
        open={openPanel}
        setOpen={setOpenPanel}
      />

      <div className="pro-sections">
        <div className="intro">
          <Paragraph className="section-title">
            Deliver at-home blood testing to your clients.
          </Paragraph>
          <Paragraph className="section-subtitle">
            As an elite health coach, use advanced blood testing to verify if your clients are improving their health.
          </Paragraph>
          <div className="intro-actions">
            <Button 
              type='primary'
              className={classNames("section-btn")}
              onClick={onSignup}
            >
              Let's Get Started
            </Button>
            <Button 
              icon={<PhoneOutlined />}
              className={classNames("section-btn", "default-btn")}
              onClick={onIntroCall}
            >
              Schedule Intro Call
            </Button>
          </div>
          <div className="hide-mobile">
            {PhotoHelper.format({
              jpg: '/img/coach/laptop.jpg',
              webp: '/img/coach/laptop.webp',
              className: 'intro-img',
            })}
          </div>
        </div>

        <div className="how">
          <Paragraph className="section-label">
            How it works
          </Paragraph>
          <Paragraph className="section-title">
            Manage your clients' health, effortlessly.
          </Paragraph>

          <Row gutter={24}>
            {howItems.map(({ title, description, img }, index) => (
              <Col
                key={`how-${index}`}
                className="how-item"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
                xxl={{ span: 8 }}
              >
                <div className="how-index">
                  {index + 1}
                </div>
                <Paragraph className="how-title">
                  {title}
                </Paragraph>

                <Paragraph className="how-description">
                  {description}
                </Paragraph>

                {PhotoHelper.format({
                  jpg: `/img/coach/${img}.jpg`,
                  webp: `/img/coach/${img}.webp`,
                  className: 'how-img',
                })}
              </Col>
            ))}
          </Row>
        </div>

        <div className="quotes">
          <Paragraph className="section-label">
            Don't just take our word for it
          </Paragraph>
          <Paragraph className="section-title">
            Here's what our customers are saying.
          </Paragraph>

          <Row 
            gutter={24}
            className="quote-list"
          >
            {quoteItems.map(({ img, content, name }, index) => (
              <Col
                key={`quote-${index}`}
                className="quote-item"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
                xxl={{ span: 8 }}
              >
                {PhotoHelper.format({
                  jpg: `/img/coach/${img}.jpg`,
                  webp: `/img/coach/${img}.webp`,
                  className: 'quote-img',
                })}

                <div className="quote-text">
                  {PhotoHelper.format({
                    png: `/img/coach/triangle.png`,
                    webp: `/img/coach/triangle.webp`,
                    className: 'triangle-img',
                  })}
                  
                  <Paragraph className="quote-content">
                    {content}
                  </Paragraph>

                  <Paragraph className="quote-name">
                    {name}
                  </Paragraph>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        <div className="access">
          <Paragraph className={classNames("access-title", "section-title")}>
            Enjoy free access to a suite of tools designed to help you work with your clients on their goals.
          </Paragraph>

          {accessItems.map((item, index) => (
            <Row
              key={`access-${index}`}
            >
              <Col 
                span={2}
                className="check-col"
              >
                <CheckOutlined className="access-icon" />
              </Col>
              <Col span={22}>
                <Paragraph className="access-content">
                  {item}
                </Paragraph>
              </Col>
            </Row>
          ))}

          <Button
            className={classNames("section-btn", "default-btn")}
            onClick={onSignup}
          >
            Get Started Now
          </Button>
        </div>

        <div className="faq">
          <Paragraph className="section-label">
            FAQ
          </Paragraph>
          <Paragraph className="section-title">
            If you have any questions, <a onClick={onChat}>we'd love to chat!</a>
          </Paragraph>

          <Questions items={questionItems} />
        </div>
      </div>
    </div>
  )
}