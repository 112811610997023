import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'


export const listReferrals = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/referrals`, {
    params: ServiceHelper.getParams(params),
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const listReferralsByPatient = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/referrals/patient/${id}`, {
    params: ServiceHelper.getParams(params),
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}


export const getReferral = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/referrals/${id}`, {
    params: ServiceHelper.getParams(params),
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const addReferral = async (params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/referrals`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeReferralFromPatient = async ({ patientId, referralId }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/referrals/patient/removeReferral`, {
    patientId,
    referralId,
  }, {
    headers: ServiceHelper.getHeaders(),
  });
  console.log(response)
  return response.data;
};

export const addReferralForPatient = async ({ patientId, referralId }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/referrals/patient/${patientId}`, {
    patientId,
    referralId
  }, {
    headers: ServiceHelper.getHeaders()
  });
  return response.data;
};

export const listReferralsByPatientAndType = async (patientId, referralType) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/referrals/patient/${patientId}/referralType`, {
    params: { referralType },
    headers: ServiceHelper.getHeaders(),
  });
  return response.data;
};


export const updateReferral = async (id, params={}) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/referrals/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeReferral = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/referrals/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}