import { useContext } from "react";
import { Typography } from "antd";
import classNames from "classnames";
import { UserContext } from "../../../contexts/user.context";
import PosthogHelper from "../../../helpers/posthog.helper";
import { useNavigate, useParams } from "react-router-dom";
import { StickyBlogHeader } from "./stickyBlogHeader.component";

const { Paragraph } = Typography


export const FounderPost = () => {
  const navigate = useNavigate()
  const { blogId } = useParams()
  const { currentUser } = useContext(UserContext)

  return (
    <div className={classNames("blog-post", "founder-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          Founder Health: The Silent Sacrifice in the Pursuit of Success
        </Paragraph>

        <Paragraph className="blog-subheading">
          Starting a startup often means pushing health to the back burner. In the race to success, it's easy to think "I'll deal with my health when my startup succeeds." 
          <br /><br />
          But "success" turns out to be a moving goal post. From raising the next big round, to making that pivotal hire, to achieving a growth milestone, to navigating a board issue. It's never ending. Your health is perpetually sidelined.
        </Paragraph>
      </div>

      <StickyBlogHeader 
        authorPhoto="/img/adora-square.jpg"
        authorName="Adora Cheung"
        publishedAt="Nov 5, 2023"
        readTime="4 Min Read"
      />

      <div className="blog-body">
        <img 
          src="/img/blogs/founder.jpg" 
          className="blog-image"
        />

        <Paragraph className="blog-description">
          I know this because this was me over a decade ago when I founded Homejoy. Weight gain, unstable blood pressure, debilitating insomnia, and a diet defined more by convenience than nutrition became my new normal. The situation especially exacerbated after our Series A funding, with escalating pressures stemming from investor expectations, customer feedback, team growth and product challenges.
        </Paragraph>
        <Paragraph className="blog-description">
          After Homejoy, I made a promise to myself: prioritize my health. After extensive testing, numerous consultations with physicians, and countless hours immersed in scientific research, I came to a sobering realization: my choice to prioritize my startup inadvertently caused significant harm to my body. 
        </Paragraph>
        <Paragraph className="blog-description">
          Contrary to popular belief, many chronic conditions we associate with "aging" actually take root in our younger years, silently eroding our well-being over time. Peter Attia's <a className="blog-link" onClick={() => window.open('https://www.amazon.com/Outlive-Science-Longevity-Peter-Attia-ebook/dp/B0B1BTJLJN/ref=sr_1_1?dib=eyJ2IjoiMSJ9.C2hgMckAFDA_vImELekFZQXjVeO29wbNkRh9zC5eDj4SlVEYjDl36l9tun1lWdA2R8HBIr3NbADetxTWBztKPzhg6S73fdX_eZFYrTGTdL8ImtNXTHYFOYE_y-RM7dZWY5Y1RkETd3cnhnjPemK9EN-yQUmeXHViEn0BnxwlEXGaR94dV8sLFLCrZccH1oubO4QtH9RD2naEFxWm_WuCIWkMIolubTCk5SkVt7HUPrw.kW6ivwzHFuSsKu4Czwr9OqKFUzJGQdNL_zBLFr-xfNA&dib_tag=se&hvadid=693825931186&hvdev=c&hvexpln=67&hvlocphy=1014251&hvnetw=g&hvocijid=8539604236437223192--&hvqmt=e&hvrand=8539604236437223192&hvtargid=kwd-1994305841254&hydadcr=8482_13544997&keywords=outlive+by+peter+attia&qid=1730835824&sr=8-1')}>Outlive</a> dives deep into this subject and is a must-read. Echoing his insights, my lifestyle had unintentionally rolled out the red carpet for the "Four Horsemen": heart disease, cancer, neurodegenerative disorders and diabetes. Unbeknownst to me, I was storing up future problems that shortened my expected lifespan.
        </Paragraph>
        <Paragraph className="blog-description">
          Looking back at my Homejoy days, it's also now clear how my health hindered my ability to lead effectively. The constant mental cloudiness and reduced sharpness weren't just personal burdens; they influenced crucial business choices and team interactions. Might a healthier version of me have steered Homejoy down a more successful path? I believe so.
        </Paragraph>
        <Paragraph className="blog-description">
          This realization is the driving force behind Instalab. Founders shouldn't need to wrestle with the juxtaposition of chasing dreams and sacrificing health. Instalab makes that choice obsolete. 
        </Paragraph>

        <Paragraph className="blog-title">
          About Instalab
        </Paragraph>
        <Paragraph className="blog-description">
          Instalab is born from firsthand experience of the unique pressures founders face. Our mission is two-fold: to improve your daily performance and, more critically, to get you on the path of pursuing longevity – all while keeping in mind the energy, time, and cognitive load your startup demands of you. Here's our approach:
        </Paragraph>

        <ul className="blog-list">
          <li>
            <strong>No Guesswork</strong>: We guide you through the comprehensive testing and results needed to understand your current health, introduce you to physicians who can explain your results and answer any of your questions, craft you an easy to follow health protocol and much more.
          </li>
          <li>
            <strong>Real Data, No Fluff</strong>: In our startups, we live by our numbers. Why should our health be any different? If we can obsess over analytics for our products, we can, and should, do the same for our bodies. Knowledge is power, and with the right data, we can make informed choices for our well-being.
          </li>
          <li>
            <strong>Advice That Fits Your Schedule</strong>: Time is of the essence; completely overhauling one's lifestyle isn't feasible. Sometimes, a mere 10-minute window is all we get. Instalab slightly tweaks your existing routine, ensuring health recommendations meld effortlessly into your daily life, rather than causing upheaval.
          </li>
          <li>
            <strong>Objective Over Subjective</strong>: With Instalab, you're immediately equipped with concrete data about your health risks and action items to reduce those risks. This knowledge enables precise experimentation with lifestyle changes, offering a clear lens into what truly benefits you. Tracking progress over time, coupled with expert counsel, ensures you remain on the right path.
          </li>
        </ul>

        <Paragraph className="blog-description">
          <a onClick={() => {
            PosthogHelper.track(currentUser, `[blog] click ${blogId} cta: start`, true)
            navigate('/longevity-panel')
          }} className="blog-link">Are you ready to get started?</a>
        </Paragraph>
      </div>
    </div>
  )
}