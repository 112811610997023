import { Button, Spin, message } from "antd"
import { useState, useEffect, useContext } from "react"
import { useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentMethodMessagingElement } from '@stripe/react-stripe-js';
import { useLocation } from "react-router-dom";
import { getProductTypeByCode } from "../../services/productType.service";
import { createPaymentIntent } from '../../services/stripe.service';
import { addStripeProduct } from "../../services/product.service";
import { UserContext } from "../../contexts/user.context";
import { ReactComponent as NewWindowIcon } from "../../assets/svg/new-window.svg";
import "./klarnaForm.scss"
import ProductHelper from "../../helpers/product.helper";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const KlarnaPayComponent = ({ clientSecret, isProcessingPayment, setIsProcessingPayment, payer }) => {
    const stripe = useStripe();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!clientSecret || isProcessingPayment || !stripe) return;

        const handleKlarnaSubmit = async () => {
            setLoading(true);
            setIsProcessingPayment(true);

            try {
                const { location, shippingLocation } = payer;
                const address = {
                    line1: location?.streetAdress || shippingLocation?.streetAdress,
                    city: location?.city || shippingLocation?.city,
                    state: location?.state || shippingLocation?.state,
                    country: "US",
                    postal_code: location?.postalCode || shippingLocation?.postalCode,
                };

                const result = await stripe.confirmKlarnaPayment(clientSecret, {
                    payment_method: {
                        billing_details: {
                            email: payer.email,
                            name: `${payer.firstName} ${payer.lastName}`,
                            address,
                        },
                    },
                    return_url: `${window.location.href}${window.location.href.includes('?') ? '&' : '?'}payment_type=klarna`
                });

                result.error ? message.error(result.error.message) : message.success("Redirecting to Klarna...");
            } catch (error) {
                message.error("Failed to process Klarna payment.");
                console.error("Klarna payment error:", error);
            } finally {
                setLoading(false);
            }
        };

        handleKlarnaSubmit();
    }, [clientSecret, isProcessingPayment, stripe, payer, setIsProcessingPayment]);

    return (
        <div className="klarna-pay">
            {loading && (
                <div className="spinner-container">
                    <div className="spinner-content" style={{textAlign: 'center'}}>
                        <Spin size="large" />
                        <div style={{marginTop: 10}}>One Moment Please<br/>Loading Klarna</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export const KlarnaForm = ({
    flow,
    onNextStep,
    productTypeCode,
}) => {
    const [loading, setLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState(null);
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);
    const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);
    const location = useLocation();
    const stripe = useStripe();
    const [productTypes, setProductTypes] = useState([]);
    const [messagingElementLoading, setMessagingElementLoading] = useState(true);
    const { currentUser, instalabMembership } = useContext(UserContext);

    // klarna redirect handling
    const query = new URLSearchParams(location.search);
    const paymentIntentId = query.get("payment_intent");
    const redirectStatus = query.get("redirect_status");
    const paymentIntentClientSecret = query.get("payment_intent_client_secret");
    const paymentType = query.get("payment_type");

    useEffect(() => {
        const fetchProductTypes = async () => {
            const codes = Array.isArray(productTypeCode) ? productTypeCode : [productTypeCode];
            const types = await Promise.all(codes.map(code => getProductTypeByCode(code)));
            setProductTypes(types);
        }
        fetchProductTypes();
    }, [productTypeCode]);

    useEffect(() => {
        const handleKlarnaRedirect = async () => {
            
            try {
                const result = await stripe.confirmKlarnaPayment(paymentIntentClientSecret);
                
                if (result.error) {
                    message.error("Klarna payment could not be confirmed.");
                } else {
                    const fields = {
                        paymentIntent: paymentIntentId,
                        type: productTypeCode,
                        flowId: flow._id,
                        patient: flow?.user?._id
                    };
                    await addStripeProduct({ fields });
                    onNextStep();
                    message.success("Klarna payment confirmed!");
                }
            } catch (error) {
                message.error("An unexpected error occurred.");
                console.error("Klarna payment error:", error);
            } finally {
                setIsProcessingPayment(false);
            }
        
        };

        if (paymentIntentId && paymentIntentClientSecret && paymentType === "klarna" && redirectStatus === "succeeded" && !isProcessingPayment) {
            setIsProcessingPayment(true);
            setIsConfirmingPayment(true);
            handleKlarnaRedirect();
        }
    }, [paymentIntentId, redirectStatus, paymentIntentClientSecret]);

    const onConfirm = async () => {
        setLoading(true);
        try {
            const totalCost = ProductHelper.getTotalCost(productTypes, instalabMembership, currentUser);
            const response = await createPaymentIntent({
                amount: totalCost * 100,
                currency: "usd",
                payment_method_types: ["klarna"],
                payerId: currentUser?._id
            });
            setClientSecret(response.clientSecret);
        } catch (error) {
            message.error("Failed to initialize Klarna payment.");
            console.error("Klarna payment error:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {isConfirmingPayment ? (
                <div className="spinner-container" style={{textAlign: 'center'}}>
                    <Spin size="large" />
                    <div className="spinner-content" style={{textAlign: 'center'}}>Confirming Klarna Payment</div>
                </div>
            ) : clientSecret ? (
                <KlarnaPayComponent
                    clientSecret={clientSecret}
                    payer={flow.user}
                    isProcessingPayment={isProcessingPayment}
                    setIsProcessingPayment={setIsProcessingPayment}
                />
            ) : (
                productTypes.length > 0 ? (
                    <div className="klarna-form">
                        <Elements stripe={stripePromise}>
                            <div className="messaging-element-container">
                                {messagingElementLoading && (
                                    <div className="spinner-container">
                                        <Spin size="medium" />
                                    </div>
                                )} 
                                    <PaymentMethodMessagingElement 
                                        options={{
                                            amount: ProductHelper.getTotalCost(productTypes, instalabMembership, currentUser) * 100,
                                            currency: 'USD',
                                            countryCode: 'US',
                                            paymentMethodTypes: ['klarna'],
                                           
                                        }}
                                        onReady={() => setMessagingElementLoading(false)}
                                    />

                        <div className="new-window-text">
                            <NewWindowIcon className="new-window-icon" />
                            You'll be redirected to Klarna to complete your payment securely, then return here for final steps.
                        </div>
                                
                            </div>
                        </Elements>

                        <Button
                            type='primary'
                            className='confirm-btn'
                            onClick={onConfirm}
                            loading={loading}
                        >
                            {'Continue to Klarna'}
                        </Button>
                    </div>
                ) : (
                    <div className="spinner-container">
                        <div className="spinner-content" style={{textAlign: 'center'}}>
                            <Spin spinning={true} />
                        </div>
                    </div>
                )
            )}
        </>
    )
} 