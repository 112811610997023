import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import GalleriApproval from "./static/galleriApproval/galleriApproval.component"
import { listProductTypes } from '../../services/productType.service'
import { completeFlow } from "../../services/flow.service"
import FlowType from "../../enums/flowType.enum.js"
import { useNavigate, useParams } from "react-router-dom"
import { updateProduct } from "../../services/product.service.js"
import ProductHelper from "../../helpers/product.helper.js"

export const Step = {
  ACCOUNT: 'account',
  NAME: 'name',
  PHONE: 'phone',
  DELIVERY: 'delivery',
  GENDER: 'gender',
  DOB: 'dob',
  PAY: 'pay',
  CONFIRM: 'confirm',
}

export const GalleriFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()
  const [initialUser, setInitialUser] = useState()
  const hasCompletedFlowRef = useRef(false)

  useEffect(() => {
    fetchSkeleton()
  }, [hasPass, productTypes, instalabMembership, flow, initialUser])

  useEffect(() => {
    fetchProductTypes()
  }, [])
  
  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.GALLERI,
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return

    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.GALLERI_TEST}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        },
        buttonText: 'Create Account'

      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.DELIVERY,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this for your lab order for the test.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.DELIVERY]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.PAY,
        title: `What's your delivery address?`,
        description: `We’ll send your Galleri kit to this location. This contains the supplies needed for the blood draw, which you'll be able to schedule on receipt.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },      
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.PHONE,
        addProduct: true,
        productType: ProductTypeCode.GALLERI,
        title: instalabMembership ?  <>Pay <span className="true-price">${getProductType(ProductTypeCode.GALLERI)?.memberCost}</span> for the {getProductType(ProductTypeCode.GALLERI)?.title}.</> : <>Pay <span className="true-price">${getProductType(ProductTypeCode.GALLERI)?.cost}</span> for the {getProductType(ProductTypeCode.GALLERI)?.title}.</>,
        description: `This includes an at-home blood draw and results and report within 2 weeks.`,
        isLocked: () => {
          return flow?.products.filter(product => [ProductTypeCode.GALLERI].includes(product.productType.code)).length > 0
        },
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },

      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your biological sex?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: <>You’re all set! 🎉</>,
        description: `Galleri will send you a kit for your blood draw within 1 business day. Below are next steps after you receive it.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <GalleriApproval/>,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
  
    })
  }

  return (
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
      productTypeCode={ProductTypeCode.GALLERI}
    />
  )
}