import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TabBar } from 'antd-mobile';
import {
  HeartOutline,
  UnorderedListOutline,
  MessageOutline,
  GiftOutline,
  AddCircleOutline
} from 'antd-mobile-icons';
import { UserContext } from "../../contexts/user.context.js";
import FlowType  from "../../enums/flowType.enum.js";
import "./footer.scss";
import MembershipHelper from "../../helpers/membership.helper.js";

export const Footer = ({  hasMobile = true }) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(''); // Set no tab active by default
  const [healthMenuVisible, setHealthMenuVisible] = useState(false); // Control visibility of health menu
  const [ordersMenuVisible, setOrdersMenuVisible] = useState(false); // Control visibility of orders menu
  const [careMenuVisible, setCareMenuVisible] = useState(false); // Control visibility of care menu
  const { token, counts, instalabMembership, memberships } = useContext(UserContext);
  const healthMenuRef = useRef(null); // Reference for the health menu
  const ordersMenuRef = useRef(null); // Reference for the orders menu
  const careMenuRef = useRef(null); // Reference for the care menu


  // My Health Menu
  const HealthMenu = ({ isVisible, onClose }) => {
    if (!isVisible) return null; // Only show the menu if it's visible

    return (
      <div className="health-menu" ref={healthMenuRef}>
        <ul>
          {counts.results?.toLocaleString() > 0 && <li onClick={() => { navigate('patient/results'); setSelectedTab('myHealth'); onClose(); }}>Test Results</li>}

          {counts.reports > 0 && <li onClick={() => { navigate('patient/report'); setSelectedTab('myHealth'); onClose(); }}>Latest Report</li>}
          {counts.reports > 0 && <li onClick={() => { navigate('patient/plan'); setSelectedTab('myHealth'); onClose(); }}>Game Plan</li>}
          <li onClick={() => { navigate('patient/medical'); setSelectedTab('myHealth'); onClose(); }}>Health Profile</li>
          <li onClick={() => { navigate('patient/files'); setSelectedTab('myHealth'); onClose(); }}>Health Files</li>

        </ul>
      </div>
    );
  };

    // Orders Menu
    const OrdersMenu = ({ isVisible, onClose }) => {
      if (!isVisible) return null;
  
      return (
        <div className="orders-menu" ref={ordersMenuRef}>
          <ul>
            <li onClick={() => { navigate('/patient/appointments'); setSelectedTab('orders'); onClose(); }}>Appointments</li>
            <li onClick={() => { navigate('/patient/kits'); setSelectedTab('orders'); onClose(); }}>Test Kits</li>
            <li onClick={() => { navigate('/patient/consults'); setSelectedTab('orders'); onClose(); }}>Consults</li>
            <li onClick={() => { navigate('/patient/treatments'); setSelectedTab('orders'); onClose(); }}>Prescriptions</li>
          </ul>
        </div>
      );
    };

    // Care Menu
    const CareMenu = ({ isVisible, onClose }) => {
      if (!isVisible) return null;
  
      return (
        <div className="care-menu" ref={careMenuRef}>
          <ul>
            <li onClick={() => { navigate('/catalog'); setSelectedTab('care'); onClose(); }}>Order Test</li>
            <li onClick={() => { navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`); setSelectedTab('care'); onClose(); }}>Longevity Consult</li>
            {MembershipHelper.getActiveHHMembership(memberships) && <li onClick={() => { navigate(`/flow/${FlowType.HEART_MEMBER_CONSULT}`); setSelectedTab('care'); onClose(); }}>Heart Health Consult</li>}
            <li onClick={() => { window.FrontChat("show"); setSelectedTab('care'); onClose(); }}>Message Concierge</li>
            <li onClick={() => { navigate(`/flow/${FlowType.REFERRAL}`); setSelectedTab('care'); onClose(); }}>Specialist Referral</li> 

            {!instalabMembership && <li onClick={() => { navigate('/patient/memberships'); setSelectedTab('care'); onClose(); }}>Join as Member</li>}
          </ul>
        </div>
      );
    };
  

  // Handle outside click to close both Health and Orders menus
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (healthMenuRef.current && !healthMenuRef.current.contains(event.target)) {
        setHealthMenuVisible(false);
      }
      if (ordersMenuRef.current && !ordersMenuRef.current.contains(event.target)) {
        setOrdersMenuVisible(false);
      }

      if (careMenuRef.current && !careMenuRef.current.contains(event.target)) {
        setCareMenuVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onTabChange = (key) => {
    if (key !== 'myHealth' && key !== 'orders' && key !== 'care') {
      setSelectedTab(key);
      setHealthMenuVisible(false); 
      setOrdersMenuVisible(false);
      setCareMenuVisible(false);
    }

    if (key === 'help') {
      navigate('/help');
    } else if (key === 'gift') {
      navigate(`/flow/${FlowType.GIFT}`)
    }
  };

  const handleMyHealthClick = () => {
    setHealthMenuVisible((prev) => !prev);
    setOrdersMenuVisible(false); // Close Orders menu if open
    setCareMenuVisible(false);
  };

  const handleOrdersClick = () => {
    setOrdersMenuVisible((prev) => !prev);
    setHealthMenuVisible(false); 
    setCareMenuVisible(false);
  };

  const handleCareClick = () => {
    setCareMenuVisible((prev) => !prev);
    setHealthMenuVisible(false); // Close Health menu if open
    setOrdersMenuVisible(false); // Close Orders menu if open
  };

  return token && hasMobile && (
    <>
      {/* TabBar for Footer */}
      <TabBar activeKey={selectedTab} onChange={onTabChange} className="fixed-footer">
        {/* Manually handle click for My Health */}
        <TabBar.Item
          key="myHealth"
          icon={<div onClick={handleMyHealthClick}><HeartOutline /></div>}
          title={<div onClick={handleMyHealthClick}>My Health</div>}
        />
        {/* Manually handle click for Orders */}
        <TabBar.Item
          key="orders"
          icon={<div onClick={handleOrdersClick}><UnorderedListOutline /></div>}
          title={<div onClick={handleOrdersClick}>My Orders</div>}
        />
        <TabBar.Item
          key="care"
          icon={<div onClick={handleCareClick}><AddCircleOutline /></div>}
          title={<div onClick={handleCareClick}>Get Care</div>}
        />
        <TabBar.Item
          key="gift"
          icon={<GiftOutline />}
          title="Gift"
        />
        <TabBar.Item
          key="help"
          icon={<MessageOutline />}
          title="Help"
        />
      </TabBar>

      {/* HealthMenu and OrdersMenu show up when their respective tab is clicked */}
      <HealthMenu isVisible={healthMenuVisible} onClose={() => setHealthMenuVisible(false)} />
      <OrdersMenu isVisible={ordersMenuVisible} onClose={() => setOrdersMenuVisible(false)} />
      <CareMenu isVisible={careMenuVisible} onClose={() => setCareMenuVisible(false)} />

    </>
  );
};