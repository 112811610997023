export const TrackingHelper = {

    getDeliveryService: (tracking) => {
        let result = { service: null, url: null };
        
        const uspsPatterns = [
            '^(94|93|92|94|95)[0-9]{24}$',
            '^(94|93|92|94|95)[0-9]{20}$',
            '^(94|93|92|94|95)[0-9]{22}$',
            '^(70|14|23|03)[0-9]{14}$',
            '^(M0|82)[0-9]{8}$',
            '^([A-Z]{2})[0-9]{9}([A-Z]{2})$',
        ];
        
        const upsPatterns = [
            '^(1Z)[0-9A-Z]{16}$',
            '^(T)+[0-9A-Z]{10}$',
            '^[0-9]{9}$',
            '^[0-9]{26}$'
        ];
        
        const fedexPatterns = [
            '^[0-9]{20}$',
            '^[0-9]{15}$',
            '^[0-9]{12}$',
            '^[0-9]{22}$'
        ];
        
        const usps = new RegExp(uspsPatterns.join('|'), 'i');
        const ups = new RegExp(upsPatterns.join('|'), 'i');
        const fedex = new RegExp(fedexPatterns.join('|'), 'i');

        
        if (usps.test(tracking)) {
            result.service = 'USPS';
            result.url = `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracking}`;
        } else if (ups.test(tracking)) {
            result.service = 'UPS';
            result.url = `https://www.ups.com/track?tracknum=${tracking}`;
        } else if (fedex.test(tracking)) {
            result.service = 'FedEx';
            result.url = `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${tracking}`;
        }
        
        return result;
        
    }
      
}

export default TrackingHelper

