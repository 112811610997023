import React, { useEffect, useState } from 'react';
import { Avatar, Space } from 'antd'
import './supportSidebar.scss';
import { UserOutlined } from '@ant-design/icons'
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { Breakpoint, FlowType, Role } from "../../enums/index.enum";
import { addSlack } from "../../services/slack.service"
import FrontChatHelper from '../../helpers/fronchat.helper';
import MembershipHelper from '../../helpers/membership.helper';
import MembershipTypeCode from "../../enums/membershipTypeCode.enum";

export const SupportSidebar = () => {
  const navigate = useNavigate()
  const { currentUser, instalabMembership, memberships } = useContext(UserContext)
  const [heartMembership, setHeartMembership] = useState()

  useEffect(() => {
    document.title = `Home | Instalab`
  }, [])

  useEffect(() => {
    FrontChatHelper.identifyUser(currentUser);
  }, [currentUser]);

  const onChat = () => {
    window.FrontChat("show");
  };

  const onIntroCall = () => {
    window.Calendly.initPopupWidget({ url: 'https://calendly.com/d/4kj-7q2-bnb?hide_gdpr_banner=1' });
  }

  const onBecomeMember = async() => {
    try {
      await addSlack({
      message: `${currentUser.firstName} ${currentUser.lastName}: Membership Upgrade Click - Support Sidebar`,
      channel: process.env.REACT_APP_PATIENT_SLACK
    });
    }
    catch (error) {
      console.error('Failed to log message to Slack:', error);
    }
    navigate("/patient/memberships")
  }

  const onBookSession = () => {
    navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`)
  }

  const onBookAthleteSession = () => {
    navigate(`/flow/${FlowType.ATHLETE_CONSULT}`)
  }

  const onBookHHSession = () => {
    navigate(`/flow/${FlowType.HEART_MEMBER_CONSULT}`)
  }

  const onReferral = () => {
    navigate(`/flow/${FlowType.REFERRAL}`)
  }

  useEffect(() => {
    setHeartMembership(getHeartMembership())
  }, [memberships])

  const getHeartMembership = () => {
    if (!memberships?.length) return null
    const heartHealthMembership = MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.HEART_HEALTH)
    if (heartHealthMembership) {
      return heartHealthMembership
    }
    return null
  }

  const ConciergeComponent = () => {
    return (
      <div className={`support-medical-team`}>
        {!instalabMembership && (
          <div className="members-only-message" onClick={onBecomeMember}>
            Members-only feature. Learn more.
          </div>
        )}

        <div className="support-subtitle">Member Concierge</div>
        <div className={`support-medical-team-content ${!instalabMembership ? 'blur-effect' : ''}`}>

          <div className="support-description"><a onClick={onChat} className="secondary-link">Message us</a> with any health-related questions or for help with your account.
          </div>
        </div>
      </div>

    );
  }

  const PhysicianComponent = () => {
    return (
      <>
      {currentUser.isAthlete ? 
        <div className={`support-medical-team`}>
          <div className="support-subtitle">Athlete Physician</div>
          <div className={`support-medical-team-content`}>
            <div className="support-description"><a onClick={onBookAthleteSession} className="secondary-link">Schedule call</a> to discuss your long-term health with a physician specializing in pro athletes.</div>
          </div>
        </div> : 
        
        <div className={`support-medical-team`}>
          <div className="support-subtitle">Longevity Physician</div>
          <div className={`support-medical-team-content`}>
            <div className="support-description">
              <a onClick={onBookSession} className="secondary-link">Schedule call</a> to discuss your long-term health with a longevity expert.
            </div>
        </div>
      </div>}

      {heartMembership &&
        <div className={`support-medical-team`}>
          <div className="support-subtitle">Hearth Health Physician</div>
          <div className={`support-medical-team-content`}>
            <div className="support-description">
              <a onClick={onBookHHSession} className="secondary-link">Schedule call</a> to discuss your health health treatment.
            </div>
        </div> 
      </div>}
      </>
    );
  }

  const FounderComponent = () => {
    return ( 
      <><div className="support-subtitle">Talk to our Founders</div>
      <div className="support-description"><a onClick={onIntroCall} className="secondary-link">Schedule a call</a> with our founders. They're eager to speak with you.</div></>
    )
  }

  const ReferralComponent = () => {
    return (<>                
      <div className="support-subtitle">Specialist Referral</div>
      <div className="support-description"><a onClick={onReferral} className="secondary-link">Request</a> a referral for anything that requires a specific type of specialist.</div> </>)
  }
    
  return  currentUser && 
    <div className="support-sidebar">
    <div className={classNames('support', {'dashboard-box': window.innerWidth < Breakpoint.LG })}>
        <div className="support-avatars">
        <Space wrap size={13}>
          <Avatar className="support-avatar" src="/img/steven-square.png" size={55} icon={<UserOutlined />} />
          <Avatar className="support-avatar" src= "/img/adora-square.png" size={55} icon={<UserOutlined />} />
          <Avatar className="support-avatar" src= "/img/dan-square.png" size={55} icon={<UserOutlined />} />
          </Space>
        </div>

        <div className="support-title">Have a question?</div>
        <div className="support-description">Our team is here to help.</div>

        {instalabMembership  ?
          <ConciergeComponent />
          :
          <>        
          <div className="support-subtitle">Message Support</div>
          <div className="support-description"><a onClick={onChat} className="secondary-link">Ask a question</a> about any of Instalab's products and services or your account.</div>
          </>
        }

        {currentUser.role === Role.PROVIDER ? <FounderComponent/> : <>
          <PhysicianComponent />
          {/* <ReferralComponent/> */}
        </>}

    </div>

    
    </div>
}