  const RecommendedTest = {
    COMPREHENSIVE_BLOOD_PANEL: 0,
    BP: 1,
    GUM: 2,
    STI: 3,
    HEPC: 4,
    SKIN_CANCER: 5,
    PAP_SMEAR: 6,
    HPV: 7,
    DEXA_BODY_COMP: 8,
    VO2MAX: 9,
    GALLERI: 10,
    CAC: 11,
    FIT_STOOL: 12,
    GLAUCOMA: 13,
    DEXA_BONE: 14,
    MAMMOGRAM: 15,
    COLONOSCOPY: 16,
    PROSTATE_PHYSICAL: 17,
    TOXIC_METALS: 18,
    WHOLE_BODY_MRI: 19,
    LOW_DOSE_SCAN: 20,
    MOLD_TEST: 21,
    SLEEP_APNEA_TEST: 22,
    CCTA: 23,

  }

  export default RecommendedTest