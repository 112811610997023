import React, { useState, useContext, useEffect } from 'react';
import { Button, Tooltip } from 'antd'
import { PageHeader } from '../pageHeader/pageHeader.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Step as LongevityStep } from '../longevityConsultFlow/longevityConsultFlow.component'
import { Step as AthleteStep } from '../athleteConsultFlow/athleteConsultFlow.component.js'
import "./consults.scss";
import { CalendarOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { UserContext } from '../../contexts/user.context.js';
import advanced from 'dayjs/plugin/advancedFormat'
import { useNavigate } from "react-router-dom";
import FlowType from '../../enums/flowType.enum.js';
import ProductTypeCode from '../../enums/productTypeCode.enum.js';
import { listConsults } from '../../services/consult.service.js';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import MembershipHelper from '../../helpers/membership.helper.js';
import MembershipTypeCode from '../../enums/membershipTypeCode.enum.js';
import ScheduleMenu from '../scheduleMenu/scheduleMenu.component';

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

export const Consults = () => {
  const navigate = useNavigate()
  const { currentUser, token, memberships } = useContext(UserContext)
  const [consults, setConsults] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const [isLoading, setIsLoading] = useState()
  const [heartMembership, setHeartMembership] = useState()

  useEffect(() => {
    document.title = 'Instalab | Consultations'
  }, [])

  useEffect(() => {
    fetchConsults()
  }, [token])

  useEffect(() => {
    setHeartMembership(getHeartMembership())
  }, [memberships])

  const getHeartMembership = () => {
    if (!memberships?.length) return null
    const heartHealthMembership = MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.HEART_HEALTH)
    if (heartHealthMembership) {
      return heartHealthMembership
    }
    return null
  }


  const fetchConsults = async () => {
    setIsLoading(true)
    if (token) {
      const fetchedConsults = await listConsults({
        select: '_id name start status remoteLocation reason',
        populate: [{
          path: 'provider',
          select: 'firstName lastName photo npi'
        }, {
          path: 'flow',
          select: '_id products type',
          populate: {
            path: 'products',
            select: 'productType',
            populate: {
              path: 'productType',
              select: 'code',
            }
          }
        }],
        sort: '-start'
      })

      setConsults(fetchedConsults)
      setFilteredCount(fetchedConsults.length)
    } else {
      setConsults(null)
      setFilteredCount(0)
    }
    setIsLoading(false)
  }

  

  const getActionItems = (consult) => {
    let menuItems = []

    const isFuture = new Date(consult.start) > new Date()
    
    if (consult.flow && isFuture) {
      const flowStep = {
        [ProductTypeCode.CONSULT_LONGEVITY]: LongevityStep.CONSULT,
        [ProductTypeCode.CONSULT_ATHLETE]: AthleteStep.REASON
      }

      if (consult.remoteLocation) {
        menuItems.push( {
          key: 'meeting',
          label: (
            <Button onClick={() => {
              window.open(consult.remoteLocation, '_blank');
            }}>
              <VideoCameraOutlined /> Go To Meeting
            </Button>
          )
  
        })
      }
      if (consult.flow.products?.length) {
        menuItems.push({  
          key: 'reschedule',
          label: (
            <Button onClick={() => {
              navigate(`/flow/${consult.flow.type}/${flowStep[consult.flow.products[0].productType.code]}/${consult.flow._id}`)
            }}>
              <CalendarOutlined /> Reschedule
            </Button>
          )
        });
      }
    }
    return menuItems;
  }

  const getCustomFilter = (consult, value) => {
    return {
      provider: () => consult.provider && `${consult.provider.firstName} ${consult.provider.lastName}`.toLowerCase().includes(value.toLowerCase())
    }
  }

  const convertTimeZone = (time) => {
    const currentUserTimeZone = currentUser?.location?.timeZoneId || 'UTC'; // Default to UTC if timezone is not provided
    const formattedDate = dayjs(time).tz(currentUserTimeZone);
    return formattedDate
  }


  let productTypes = [];

  if (heartMembership) {
    productTypes.push(
      {
        title: 'Heart Health Consult',
        code: ProductTypeCode.CONSULT_HEART_HEALTH,
        flowType: FlowType.HEART_MEMBER_CONSULT
      })
  }

  if (currentUser?.isAthlete) {
    productTypes.push({
      title: 'Athlete Consult',
      code: ProductTypeCode.CONSULT_HEART_HEALTH,
      flowType: FlowType.ATHLETE_CONSULT
    })
  } else {
    productTypes.push({
      title: 'Longevity Consult',
      code: ProductTypeCode.CONSULT_LONGEVITY,
      flowType: FlowType.LONGEVITY_CONSULT,
    })
  }

  return (
    <div className="consults">
      <PageHeader 
        title='Consultations' 
        count={filteredCount}
        actions={(
          <ScheduleMenu 
            productTypes={productTypes} 
            currentUser={currentUser} 
            menuText="Book Consultation"
          />
        )}
        // actions={(
        //   <Tooltip 
        //     title={credits?.length > 0 ? `${credits.length} ${credits.length === 1 ? 'credit available' : 'credits available'}` : null}
        //     placement='left'
        //   >
        //     <Button  
        //       type='primary'
        //       onClick={() => {
        //         if (heartMembership) {
        //           navigate(`/flow/${FlowType.HEART_MEMBER_CONSULT}`)
        //         } else if (currentUser?.isAthlete) {
        //           navigate(`/flow/${FlowType.ATHLETE_CONSULT}`)
        //         } 
        //         else {
        //           navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`)
        //         }
        //       }}
        //     >
        //       + Book Consultation

        //       {credits?.length > 0 && (
        //         <span className="credit-count">
        //           {credits.length}
        //         </span>
        //       )}
        //     </Button>
        //     </Tooltip>
        // )}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={consults}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[
        {
          title: 'Provider',
          dataIndex: 'provider',
          render: provider => <>{provider.npi && <>Dr. </>}{provider.firstName} {provider.lastName}</>,
        }, {
          title: 'Start',
          dataIndex: 'start',
          render: (start, { provider }) => start && convertTimeZone(start, provider).format('MMM D, YYYY')
        }, {
          dataIndex: 'startTime',
          render: (_, { start, provider }) => start && convertTimeZone(start, provider).format('h:mma z')
        },
      ]}
      />
    </div>
  )
}