
import { useRef, useEffect, useState } from "react";
import { EventCode, FlowType } from "../../enums/index.enum";
import { ProductDetail } from '../productDetail/productDetail.component';
import { ReactComponent as BloodSVG} from '../../assets/svg/blood.svg'; 
import { ReactComponent as CardSVG} from '../../assets/svg/card.svg'; 
import { ReactComponent as HomeSVG} from '../../assets/svg/house.svg'; 
import ProductTypeCode from "../../enums/productTypeCode.enum"
import ProductHelper from "../../helpers/product.helper";
import ProductDetailSection from "../../enums/productDetailSection.enum";
import { getProductTypeByCode } from "../../services/productType.service";

export const TestosteronePanel = () => {

  const [productType, setProductType] = useState()
  const [sections, setSections] = useState(null)
  const panelRef = useRef(null);

  // init + get prices
  useEffect(() => {
    fetchProductType(); 
  }, []);

  const fetchProductType = async () => {
    setProductType(await getProductTypeByCode(ProductTypeCode.TESTOSTERONE_PANEL_KIT))
  };

  
  // set product attributes
  useEffect(() => {
    if (!productType) return
    const faqs = [
      {
        question: "How do I get this test done?",
        answer: <>You’ll receive a blood collection kit in the mail, featuring the Tasso+ device for easy, virtually painless self-collection. After collecting your sample, simply drop it in the mail, and your results will be ready in about a week.</>
      },
      {
          question: "What biomarkers are included?",
          answer: <>Our comprehensive testosterone test provides insights on albumin and sex hormone-binding globulin (SHBG), total and free testosterone levels. Albumin and SHBG are proteins associated with testosterone and influence how much testosterone is available for bodily functions. By measuring albumin and SHBG, you get an accurate assessment of the free testosterone level in the body. Knowing albumin and SHBG levels alongside total and free testosterone can help you understand if your symptoms may be associated with high or low testosterone levels.</>
         
      },
      {
        question: "How is the blood collection virtually painless?",
        answer: "The Tasso+ device offers a more comfortable and convenient alternative to traditional finger pricks for blood collection. Unlike finger pricks, which can cause sharp pain by puncturing deeper into the skin, the Tasso+ uses microneedles and a gentle suction mechanism to draw blood from the skin’s surface with minimal penetration. This reduces the discomfort and potential bruising associated with finger pricks."
      },
      {
        question: `How long does it take to get my results?`,
        answer: <>It typically takes about 5 days to get results after your sample arrives at our lab. We’ll send you an email when your results and recommendations are ready to go!</>
      }, 
      {
        question: "How do I test my hormonal health at home?",
        answer: <>We’ll send you everything you need to test, along with instructions (and tips!) to support you throughout the process. After you collect your blood sample with the painless Tasso+ collection device, you’ll just pop it in the mail and our lab will handle the rest.</>,
      },
      {
          question: `Where is this available?`,
          answer: `Anywhere in the United States.`
      },
      {
          question: `Do I need to fast before I collect my blood?`,
          answer: `No fasting is needed for this test.`,
      }, 
      {
          question: `Where is my blood being sent?`,
          answer: `Your sample is sent to one of our CLIA + CAP certified labs for analysis. After the lab is done, any remaining sample is disposed.`,
      }, 
      {
          question: `Can I talk to an actual human physician to review my results with me?`,
          answer: `Yes. Once you receive your results, you have the option to schedule a video consultation with a clinical lipidologist. They'll be ready to address any of your questions.`,
      }, 
      {
          question: `Does Instalab help with any followup needs?`,
          answer: `Yes. Instalab helps members with any additional testing and referrals to specialists.`
      },
      {
        question: "How often should I get this panel done?",
        answer: `If you're making changes to your diet, fitness, lifestyle or anything else in your life, it's good to check every 3 months during this period to make sure those changes are helping you. If you have optimal levels already, it's good to check this once a year to make sure everything remains optimal and not trending in the wrong way.`,
      },   
          {
          question: `Does my health insurance cover this?`,
          answer: `For price transparency reasons, Instalab does not accept insurance. However, if you have an HSA/FSA, you may be eligible for reimbursement. We recommend using your HSA/FSA card when purchasing anything at Instalab. If you don’t have one, we can generate an itemized receipt for you to submit.`,
      }, 

    ]

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: productType?.title,
          memberPrice: ProductHelper.getMemberPrice(productType?.cost),
          originalPrice: productType?.cost,
          oneliner: "Male hormone check: get a complete picture with this comprehensive testosterone test.",
          panelRef: panelRef,
          cta: {
            text: "Buy Now",
            url: `/flow/${FlowType.TESTOSTERONE_PANEL}`
          },
          hasKlarna: true,
          photos: [
              {
                original: "/img/testosterone-panel.png", 
                thumbnail: "/img/testosterone-panel.png"
              },

              {
                original: "/img/tasso-man.png",
                thumbnail:  "/img/tasso-man.png"
              },

              {
                original: "/img/fertility-tasso-grid.png",
                thumbnail:  "/img/fertility-tasso-grid.png"
              },
          ],
          points: [
              <><BloodSVG className="point-icon"/>Test for albumin, SHBG, total and free testosterone levels</>,
              <><HomeSVG className="point-icon"/>Easy and painless sample collection with Tasso+</>,
              <><CardSVG className="point-icon" />HSA / FSA cards accepted</>,
          ],
          faqs: faqs
        },
      },
      { type: ProductDetailSection.DIRECTIONS,
        data: [
            {
                title: "Order test",
                description: "After you purchase your test, you'll receive your sample collection kit in the mail. This includes the Tasso+ collection unit.",
                image: "/img/tasso-box.png"
            },
            {
                title: "Collect & mail sample",
                description: "Complete your sample collection with the Tasso+ ideally between 8am and 10am when your testosterone is peaking. Then just pop it the mail.",
                image: "/img/tasso-grid.png"
            },
            {
                title: "Receive your results",
                description: "Results are reviewed by a physician and available within 1 week. A report and game plan will be available as well.",
                image: "/img/receive-results-man.png"
            },

        ],
      },

      { type: ProductDetailSection.FAQ,
        title: 'Frequently Asked Questions',
        data: faqs.slice(3),
      },

    ])
  
  }, [productType])


  return (
    sections && <div className="longevity-panel">

      <ProductDetail 
        sections={sections}
        trackCode={EventCode.VISIT_BLOOD_TEST}
      />

    </div>
  )

}
