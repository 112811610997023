import parse from 'html-react-parser';

const formatTestLink = (content, tests) => {
  const matches = content?.match(/{{[^`]+?\|[^`]+?}}/g)
  if (matches?.length) {
    for (const match of matches) {
      let testCode = match.match(/{{([^`]+?)\|/)
      if (testCode) testCode = testCode[1]
      const test = tests.find(({ code }) => code.toString() === testCode)
      let matchText = match.match(/\|([^`]+?)}}/)
      if (matchText) matchText = matchText[1]
      
      if (matchText && test) {
        content = content.replace(match, `<span id="test-link" name="${matchText}" open="${test._id}">${matchText}</span>`)
      }
    }
  }

  return content
}

const ContentHelper = {
  format: (content, tests, setTestId, setIsDrawerOpen) => {
    if (!content) return content
    return parse(formatTestLink(content, tests), {
      replace: ({ attribs }) => {
        if (attribs && attribs.id === 'test-link') {
          return <a 
            className="test-link"
            onClick={(e) => {
              e.stopPropagation()
              setTestId(attribs.open)
              if (setIsDrawerOpen) {
                setIsDrawerOpen(true)
              }
            }}
          >
            {attribs.name}
          </a>
        }
      }
    })
  }
}

export default ContentHelper
