import { Modal, Select, Input, Form, Checkbox, message } from 'antd'
import { listPatients } from '../../services/patient.service';
import PrescriptionType from '../../enums/prescriptionType.enum';
import { useState, useEffect } from 'react'
import './prescriptionForm.scss'
import { addPrescription } from '../../services/prescription.service';
import { ContinueButton } from '../continueButton/continueButton.component';
import Org from '../../enums/org.enum';
import DateInput from '../dateInput/dateInput.component';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

const { Item } = Form

export const PrescriptionForm = ({ open, setOpen, onSuccess, patientId }) => {
  const [patients, setPatients] = useState([])
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()

  useEffect(() => {
    if (!patientId) {
      fetchPatients()
    }
  }, [patientId])

  useEffect(() => {
    if (open) {
      form.setFieldsValue({
        daysToRefill: 90
      })
    } else {
      form.resetFields()
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const fetchPatients = async () => {
    const response = await listPatients({
      select: '_id firstName lastName',
      filter: {
        org: Org.INSTALAB,
        firstName: {
          $ne: null
        },
        email: {
          $ne: null
        }
      },
    })
    setPatients(response.filter(({ firstName, lastName }) => firstName && lastName))
  }
  
  const onOk = async () => {
    setIsSubmitting(true)
    try {
      message.success('Prescription added')
      let values = form.getFieldsValue()
      if (patientId) {
        values.patient = patientId
      }
      if (values.deliveredAt) {
        values.deliveredAt = dayjs.tz(values.deliveredAt, 'MM/DD/YYYY', 'America/New_York').toDate()
      }
      console.log('values', values)
      const newPrescription = await addPrescription(values)
      console.log('newPrescription', newPrescription)
      onSuccess(newPrescription)
      setOpen(false)
      form.resetFields()
    } catch (err) {
      console.log(err)
      message.error('Failed to add prescription')
    }
    setIsSubmitting(false)
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className="prescription-form"
      title="Add New Prescription"
      width={500}
      footer={null}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onOk}
      >
        {!patientId && (
          <Item
            label="Patient"
            name="patient"
          >
            <Select
              placeholder="Select patient"
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              options={patients?.map(({ _id, firstName, lastName }) => {
                return {
                  label: `${firstName} ${lastName}`,
                  value: _id,
                }
              })}
            />
          </Item>
        )}
        
        <Item
          name="type"
          label="Prescription Type"
        >
          <Select
            placeholder="Select prescription type"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => 
              option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
            }
            options={Object.values(PrescriptionType).map(prescriptionType => {
              return {
                label: prescriptionType,
                value: prescriptionType,
              }
            })}
          />
        </Item>

        <Item
          label="Units"
          name="units"
        >
          <Input
            placeholder="Units"
          />
        </Item>

        <Item
          label="Days to Refill"
          name="daysToRefill"
        >
          <Input
            placeholder="Days to Refill"
          />
        </Item>

        <Item
          label="Delivery Date"
          name="deliveredAt"
        >
          <DateInput
            value={form.getFieldValue('deliveredAt') || null}
          />
        </Item>

        <Item 
          label="Has Refill" 
          name="hasRefill" 
          initialValue={true} 
          valuePropName="checked">
          <Checkbox />
        </Item>

        <Item 
          label="Pickup Order" 
          name="isPickupOrder" 
          initialValue={false} 
          valuePropName="checked">
          <Checkbox />
        </Item>

        <ContinueButton
          text='Save Prescription'
          isSubmitting={isSubmitting}
        />
      </Form>
    </Modal>
  )
}