import React, { useContext, useEffect, useState } from 'react';
import { Tooltip, Typography, Button, Modal, message } from 'antd'
import moment from 'moment'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { TrackingHelper } from "../../helpers/tracking.helper.js"
import "./adminTestkits.scss"
import { UserContext } from '../../contexts/user.context.js';
import { listProducts, removeProduct } from '../../services/product.service.js';
import classNames from 'classnames';
import ScheduleMenu from '../scheduleMenu/scheduleMenu.component.js';
import ProductTypeCode from '../../enums/productTypeCode.enum.js';
import { listProductTypes} from '../../services/productType.service.js';
import { useNavigate, useParams } from 'react-router-dom';
import UrlHelper from '../../helpers/url.helper.js';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import { FilterDropdownType, ProductStatus, Role } from "../../enums/index.enum"
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { AdminProductModal } from '../adminProductModal/adminProductModal.component.js';

const { Text } = Typography
const select = '_id createdAt patient productType status hasKit tracking trackingNumber hasAppointment facility start shippingLocation'
const populate = [{
  path: 'patient',
  select: '_id firstName lastName'
}, {
  path: 'productType',
  select: 'title code'
}, {
  path: 'facility',
  select: 'name location'
}]

export const AdminTestkits = ({hasPatient=false}) => {
  const { patientId } = useParams()
  const { currentUser, setCounts } = useContext(UserContext)
  const [testKits, setTestKits] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [products, setProducts] = useState()
  const [productTypes, setProductTypes] = useState([]);
  const [filteredCount, setFilteredCount] = useState();
  const [productTypeTitles, setProductTypeTitles] = useState([])
  const [productId, setProductId] = useState()
  const [openAdminProductModal, setOpenAdminProductModal] = useState()


  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts()
  }, [])

  useEffect(() => {
    if (!products) return
    fetchTestKits()
  }, [products])


  // useEffect(() => {
  //   fetchProductTypes(); 
  // }, [currentUser])

  const fetchProducts= async () => {
    setIsLoading(true);
    if (hasPatient && !patientId) return;

    let params = {
      filter: {
        isRemoved: {
          $ne: true
        },
        hasKit: true
      },
      select: 'patient hasKit type tracking trackingNumber createdAt status',
      populate: [{
        path: 'productType',
        select: 'title'
      }, {
        path: 'patient'
      }]
    }
    if (hasPatient) {
      params.filter = {
        patient: patientId
      }
    }

    const kits = await listProducts(params)
    setProducts(kits)
    setFilteredCount(kits.length)
    setProductTypeTitles([...new Set(kits.filter(({ productType }) => productType).map(({ productType }) => productType.title))].sort())


  }
  
  const fetchTestKits = () => {
    if (!products) return
    setTestKits(products.filter(product => {
      return product.hasKit
    }))
    setIsLoading(false)
  }

  // Fetch product types for the menu
  // const fetchProductTypes = async () => {
  //   if (!currentUser) return;
  
  //   // Define a filter to only fetch specific product codes
  //   let filter = {
  //     code: { $in: [
  //       ProductTypeCode.TRUAGE_COMPLETE,
  //       ProductTypeCode.BIOBEAT,
  //       ProductTypeCode.FERTILITY,
  //       ProductTypeCode.GALLERI,
  //       ProductTypeCode.HEART_HEALTH_TEST_KIT,
  //       ProductTypeCode.HEART_HEALTH_GENETIC_TEST,
  //       ProductTypeCode.MYCOTOX_PROFILE,
  //       ProductTypeCode.WATCHPAT_ONE,
  //       ProductTypeCode.TESTOSTERONE_PANEL_KIT,
  //       ProductTypeCode.TOXIC_METALS_URINE_TEST,
      
  //     ] }
  //   };
  

  //   try {
  //     const types = await listProductTypes({ filter, sort:'title' }); // Pass the filter when calling API
  //     setProductTypes(types);
  //   } catch (error) {
  //     console.error("Error fetching product types:", error);
  //   }
  // };


  const onProductUpdate = (newProduct) => {

    if (products.some(p => p._id === newProduct._id)) {
      setProducts(cachedProducts => {
        return cachedProducts.map(p => p._id === newProduct._id ? newProduct : p)
      })
    } else {
      setProducts(cachedProducts => {
        return [
          newProduct,
          ...cachedProducts
        ]
      })
      setCounts(cachedCounts => {
        return {
          ...cachedCounts,
          products: cachedCounts.products + 1
        }
      })
      setFilteredCount(cachedFilterCount => cachedFilterCount+1)
    }
  }

  const onRemove = (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this product?',
      content: 'This action cannot be undone.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removeProduct(_id);
          setProducts(products.filter(product => product._id !== _id));
          setCounts(cachedCounts => {
            return {
              ...cachedCounts,
              products: cachedCounts.products - 1
            }
          })
          setFilteredCount(cachedFilterCount => cachedFilterCount-1)
          message.info('Product removed');
        } catch (err) {
          message.error('Failed to remove product');
        }
      }
    });
  }

  const getActionItems = (p) => {
    return [
      {
        key: 'tracking-number',
        label: (
          <Button onClick={() => {
            setProductId(p._id)
            setOpenAdminProductModal(true)
          }}>
            <EditOutlined /> Edit Product
          </Button>
        )
      }, {
        type: 'divider'
      }, {
        key: 'delete',
        label: (
          <Button 
            onClick={() => onRemove(p._id)}
            className="remove-item"
          >
            <DeleteOutlined /> Remove
          </Button>
        ),
        adminOnly: true
      }
    ]
  }

  const getCustomFilter = (product, value) => {
    return {
      patient: () => product.patient && `${product.patient.firstName} ${product.patient.lastName}`.toLowerCase().includes(value.toLowerCase()),
      productType: () => product.productType?.title === value
    }
  }

  return (
    <div className="admin-testkits">
      <AdminProductModal
        productId={productId}
        setProductId={setProductId}
        open={openAdminProductModal}
        setOpen={setOpenAdminProductModal}
        onSuccess={onProductUpdate}
        patientId={patientId}
        select={select}
        populate={populate}
      />

      <PageHeader 
        title='Test Kits'
        count={testKits.length}
        // actions={(
        //   <ScheduleMenu  
        //     productTypes={productTypes} 
        //     currentUser={currentUser} 
        //     menuText={"Get Test Kit"}
        //   />
        // )}
        />

      <FlexibleTable
        loading={isLoading}
        records={testKits}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={currentUser?.role === Role.ADMIN && getActionItems}

        columns={[
          {
            title:'Test',
            dataIndex:'productType',
            filterDropdownType: FilterDropdownType.CHECKBOX,
            filterOptions: productTypeTitles,
            render: (_, record) => record.productType ? <>{record.productType.title}</> : <>{record.type}</>,  
          },
          !hasPatient && {
            title: "Patient",
            dataIndex: "patient",
            filterDropdownType: FilterDropdownType.INPUT,
            render: (patient) => {
            if (!patient) return
            return (
              <span key={patient._id}>
                  <a 
                    className="patient-name"
                    onClick={() => navigate(UrlHelper.getPatientProfile(patient._id, 'Kits'))}
                  >
                    {patient.firstName} {patient.lastName}
                  </a>
              </span>)
            }
          },
          {
            title: 'Ordered',
            dataIndex: 'createdAt',
            render: createdAt => moment(createdAt).format('MM/DD/YYYY')
          },
          {
            title: 'Status',
            dataIndex: 'status',
            // filterDropdownType: FilterDropdownType.CHECKBOX,
            // filterOptions: Object.values(ProductStatus),
            render: (status, { _id }) => {
              return status ? <Text className={classNames(`${status}-status`, 'testkit-status')}>{status ? status : 'fulfilling'}</Text> : <Text className={classNames(`fulfilling-status`, 'testkit-status')}>fulfilling</Text>
            }
          },
          {
            title: 'Tracking',
            dataIndex: 'tracking',
            render: (tracking, { _id, trackingNumber, status }) => {
              
              if (tracking?.trackingEvents?.length > 0) {
                return <>{TrackingHelper.getDeliveryService(trackingNumber).service}:&nbsp; 
                <Tooltip 
                  overlayStyle={{ maxWidth: '390px'}}
                  title={(
                    <ul className="tracking-list">
                      {tracking.trackingEvents.map(({ eventTimestamp, eventType }, index) => (
                        <li key={`tracking-${_id}-${index}`}>
                          <strong>{moment(eventTimestamp).format('MM/DD/YYYY')}</strong>: {eventType}
                        </li>
                      ))}
                    </ul>
                  )}
                >
                  <a className="tracking-link" onClick={(e) => {
                    e.stopPropagation(); 
                    window.open(`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracking.trackingNumber}`, '_blank');
                  }}>{tracking.trackingNumber}</a>
                </Tooltip></>
              }
              else if (trackingNumber) {
              
                return  <>{TrackingHelper.getDeliveryService(trackingNumber).service}: <a className="link" href={TrackingHelper.getDeliveryService(trackingNumber).url} target="_blank">{trackingNumber}</a></>
              }
                
            }
          }    
        ]}
      />
                        
    </div>
  )
}