import { Typography } from "antd"
import './pregnantAdvice.scss'
import { TestItemList } from "../testItemLlist/testItemList.component"
import { useState, useEffect } from "react";
import ReportSection from "../../../enums/reportSection.enum";
import ReportHelper from '../../../helpers/report.helper';
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const converter = require('number-to-words');

const { Paragraph } = Typography

export const PregnantAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [pregnantTests, setPregnantTests] = useState()
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setPregnantTests(getPregnantTests())
  }, [report, tests])

  const getPregnantTests = () => {
    if (!report || !tests) return null
    return ReportHelper.getSectionTests(report, ReportSection.PREGNANT_ADVICE, tests)
  }

  useEffect(() => {
    setTitle(getTitle())
  }, [pregnantTests, report])

  useEffect(() => {
    setDescription(getDescription())
  }, [report])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
        }
      }
    })
  }, [title, description])

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

      if (!pregnantTests) return null

    return <>
      These <span>{converter.toWords(pregnantTests?.length)} {pregnantTests?.length === 1 ? 'biomarker' : 'biomarkers'}</span> are out of normal range, but could represent normal values during pregnancy.
    </>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    return <>
      After giving birth, your lipid, hormone, and metabolic levels will likely return to normal. It's recommended to schedule a follow-up blood draw to confirm this.
    </>
  }

  return pregnantTests && (
    <div className="pregnant-advice">
      <Paragraph className="report-template-title">
        {title}
      </Paragraph>
      <Paragraph className="report-template-description">
        {description}
      </Paragraph>

      <TestItemList 
        report={report} 
        testIds={pregnantTests.map(({ _id }) => _id)} 
        tests={tests}
        results={results}
        user={user}
        reports={reports}
      />
    </div>
  )
}