import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { CacConfirm } from "./cacConfirm.component.js"
import { PanelModal } from "../panelModal/panelModal.component"
import { TassoModal } from "../tassoModal/tassoModal.component"
import { heartHealthPanel } from "../../data/heartHealthPanel.data"
import { listProductTypes } from '../../services/productType.service'
import MembershipTypeCode from "../../enums/membershipTypeCode.enum.js"
import { getMembershipTypeByCode} from '../../services/membershipType.service'
import { MembershipPointsModal } from "../membershipPointsModal/membersipPointsModal.component";
import { completeFlow } from "../../services/flow.service.js"
import FlowType from "../../enums/flowType.enum.js"
import { useNavigate } from "react-router-dom"
import { Step as IntakeStep } from '../intakeFlow/intakeFlow.component'

export const Step = {
  ACCOUNT: 'account',
  LOCATION: 'location',
  FACILITY: 'facility',
  NAME: 'name',
  GENDER: 'gender',
  PREGNANCY: 'pregnancy',
  DOB: 'dob',
  BIOMETRICS: 'biometrics',
  HAS_MEDICATIONS: 'has-medications',
  MEDICATIONS: 'medications',
  PHONE: 'phone',
  PAY: 'pay',
  UPSELL: 'upsell',
  SHIPPING: 'shipping',
  CONFIRM: 'confirm',
}

export const CacFlow = () => {
  const navigate = useNavigate()
  const { instalabMembership, setHasInstalabMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [openPanel, setOpenPanel] = useState()
  const [openTasso, setOpenTasso] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()
  const [openMembershipPointsModal, setOpenMembershipPointsModal] = useState()
  const [hasPass, setHasPass] = useState()
  const hasCompletedFlowRef = useRef(false)
  const [membershipType, setMembershipType] = useState()
  const [facilityPrice, setFacilityPrice] = useState()

  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [productTypes, instalabMembership, setHasInstalabMembership, flow, hasPass, initialUser])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipType()
  }, [])

  useEffect(() => {
    if (flow?.facility && flow.facility.menu) {
      const product = flow.facility.menu.find(f => f.productType === getProductType(ProductTypeCode.CTCALCIUM)?._id);
      if (product && product.price) {
        setFacilityPrice(product.price);
      }
    }
  }, [flow, productTypes]);

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.CTCALCIUM,
            ProductTypeCode.HEART_HEALTH_TEST_KIT
          ]
        }
      }
    }))
  }

  const fetchMembershipType = async () => {
    setMembershipType(await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const fetchSkeleton = () => {
    if (hasPass === undefined || !initialUser) return

    setSkeleton({
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.LOCATION,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.CAC}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
          unique: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass 
        },
        buttonText: 'Create Account'
      },
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        buildProduct:true,
        nextStep: Step.FACILITY,
        title: `What's your current address?`,
        description: `We'll search for imaging centers near this address.`,
        field: {
          name: 'location'
        },
      },
      [Step.FACILITY]: {
        type: StepType.FACILITY,
        nextStep: Step.NAME,
        buildFlow: true,
        productTypeCode: ProductTypeCode.CTCALCIUM,
        showPrice: true,
        title: `Select your preferred imaging center.`,
        description: `We’ll book your coronary calcium scan at this facility. You’ll pay the facility directly at your appointment.`,
        field: {
          name: 'facility',
          required: true,
        },
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your full name?`,
        description: "This is required for the physician order.",
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUser?.firstName && initialUser?.lastName
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.gender === Gender.FEMALE) {
            return Step.PREGNANCY
          } else {
            return Step.DOB
          }
        },
        buildUser: true,
        title: `What's your biological sex?`,
        description: "This is required for the physician order.",
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.PREGNANCY]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `Are you currently pregnant?`,
        description: 'Please note you should not get the scan done if there is any chance you may be pregnant.',
        field: {
          name: 'isPregnant',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
        skipIf: () => {
          return initialUser?.gender === Gender.MALE
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.BIOMETRICS,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },
      [Step.BIOMETRICS]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.HAS_MEDICATIONS,
        buildUser: true,
        title: `What's your current height and weight?`,
        description: `Please list the reading of when you were last measured.`,
        fields: [{
          name: 'height',
          label: 'Height (inches)',
          placeholder: 'Type your height here...',
        }, {
          name: 'weight',
          label: 'Weight (lbs)',
          placeholder: 'Type your weight here...',
        }],
        skipIf: () => {
          return initialUser?.height && initialUser?.weight
        }
      },
      [Step.HAS_MEDICATIONS]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: (patient) => {
          if (patient?.hasMedications) {
            return Step.MEDICATIONS
          } 
          else {
            return Step.PHONE
          }
        },
        buildUser: true,
        title: 'Are you taking any prescribed medications?',
        field: {
          name: "hasMedications",
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.MEDICATIONS]: {
        type: StepType.TEXTAREA,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `Please list each prescribed medication you currently take.`,
        description: `Separate each one with a new line. Include dosage if you know it.`,
        field: {
          name: 'freeformMedications',
          placeholder: 'Type medications here ...',
        },
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PAY,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only send you urgent notifications about your account or results here.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUser?.phone
        }
      },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.CONFIRM,
        addProduct: !flow?.addLongevityMembership,
        productType: ProductTypeCode.CTCALCIUM,
        addLongevityMembership: false,
        title: (
          <>
            {(flow?.addLongevityMembership || instalabMembership) ? (
              <>
                Pay <span className="true-price">${getProductType(ProductTypeCode.CTCALCIUM)?.memberCost}</span> for the service fee and <span className="true-price">${membershipType?.cost}/month</span> for your Instalab membership.
              </>
            ) : (
              <>Pay <span className="true-price">${getProductType(ProductTypeCode.CTCALCIUM)?.cost}</span> for the service fee.</>
            )}
          </>
        ),
        description: <>This fee covers your required physician order, scheduling, result report, and a consultation with a physician to review your results if needed. <p><i>Note: This doesn't include the scan cost{facilityPrice && ` ($${facilityPrice})`}, which you'll pay directly to {flow?.facility ? flow?.facility.name : 'the facility'}.</i></p></>,
        membershipCTA: '$0 Service Fees with Instalab Membership',
        skipIf: () => {
          return instalabMembership 
        },
      },

      // [Step.UPSELL]: {
      //   type: StepType.PAY,
      //   nextStep: Step.SHIPPING,
      //   addProduct: true,
      //   productType: ProductTypeCode.HEART_HEALTH_TEST_KIT,
      //   title: <>Want to add the <span className="true-price">Advanced Heart Health Test</span> for just <span className="true-price">${getProductType(ProductTypeCode.HEART_HEALTH_TEST_KIT)?.cost}</span>?</>,
      //   description: "Get a more complete picture of your heart health by pairing your coronary calcium results with the most important blood biomarkers for heart health.",
      //   benefits: [
      //     <>Measures <a onClick={()=>setOpenPanel(true)} className="muted-link">8 key blood biomarkers</a> including ApoB, Lp(a), HbA1c and more.</>,
      //     <>Collect your sample easily and painlessly at home with the <a onClick={()=>setOpenTasso(true)} className="muted-link">Tasso+ device</a>.</>,
      //     <>Receive results and personalized recommendations in 1 week.</>,
      //   ],
      //   hasSkip: true,
      // },
      // [Step.SHIPPING]: {
      //   type: StepType.LOCATION,
      //   buildUser: true,
      //   nextStep: Step.CONFIRM,
      //   title: `What's your delivery address?`,
      //   description: `Your advanced heart health test will be sent to this location.`,
      //   field: {
      //     name: 'shippingLocation'
      //   },
      //   skipIf: (flow) => {
      //     const hasPurchased = flow?.products?.some(product => product.productType?.code === ProductTypeCode.HEART_HEALTH_TEST_KIT);
      //     return (!hasPurchased);
      //   }
      // },

      [Step.CONFIRM]: {
        type: StepType.STATIC,
        path: `/flow/${FlowType.INTAKE}/${IntakeStep.MEDICAL_CONDITIONS_CATEGORIES}`,
        title: `All set! We'll email you within 1 business day.`,
        description: <>An Instalab physician will review your details and send the your scan order to {flow?.facility?.name}. Once the facility has processed it, we'll email you to schedule your appointment.
        <p>Next, we need a litle bit more information to provide the most accurate analysis and personalized recommendations for your results.</p></>,
        buttonText: 'Continue',
        // enterStyle: { display: 'none' },
        // buttonStyle: { display: 'none' },
        // content: <CacConfirm flow={flow} />,
        onLoad: async () => {
          if (flow?._id && !hasCompletedFlowRef.current) {
            hasCompletedFlowRef.current = true; // Mark flow as completed
            await completeFlow(flow._id);
          }
        }
      },
    })
  }


  return ( <>
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
      startIndex={0}
      productTypeCode={ProductTypeCode.CTCALCIUM}
    />

    <MembershipPointsModal
      open={openMembershipPointsModal}
      setOpen={setOpenMembershipPointsModal}
    />

    <TassoModal
      open={openTasso}
      setOpen={setOpenTasso}
    />

    <PanelModal
      panel={heartHealthPanel}
      title="Advanced Heart Health Panel"
      open={openPanel}
      setOpen={setOpenPanel}
    />
    </>
  )
}