import React, { useContext, useEffect, useState } from 'react';
import { Table, Tooltip, Typography } from 'antd'
import moment from 'moment'
import { PageHeader } from '../pageHeader/pageHeader.component';
import "./adminGifts.scss"
import ProductTypeCode from '../../enums/productTypeCode.enum.js';
import { listProductTypes} from '../../services/productType.service.js';
import { listTransfers } from '../../services/transfer.service.js';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import "./adminGifts.scss"

const { Column } = Table

export const AdminGifts = () => {
  const [gifts, setGifts] = useState([])
  const [productTypes, setProductTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    document.title = '[Admin] Gifts'
  }, [])

  useEffect(() => {
    fetchProductTypes()
    fetchGifts()
  }, [])


  const fetchGifts = async () => {
    const transfers = await listTransfers({
      filter: {
        $and: [
          // { sender: { $exists: true } },
          // { user: { $exists: true } },
          { giftActivationDate: { $exists: true } }
        ]
      },
      populate: [{path: 'user'}, {path: 'sender'}]
    })

    console.log("transfers", transfers)

    setGifts(transfers)
    setIsLoading(false)
  }

  const fetchProductTypes = async () => {
  
    try {
      const types = await listProductTypes(); 
      setProductTypes(types);
    } catch (error) {
      console.error("Error fetching product types:", error);
    }
  };

  const getProductType = (code) => {
    return productTypes.find(type => type.code === code)
  }

  return (
    <div className="admin-gifts">
      <PageHeader 
        title='Gifts'
        count={gifts.length}
        />

      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={gifts}
        scroll={{ x: 'max-content' }}

      >
        <Column
          title='Recipient'
          dataIndex='user'
          render={(user, record) => (
            user ? (
              <a target="_blank" href={`/patients/${user._id}`}>
                {`${user.firstName} ${user.lastName}`}
              </a>
            ) : (
              `${record.recipientName} (${record.recipientEmail})` || 'Unknown'
            )
          )}
        />
        <Column
          title='Sender'
          dataIndex='sender'
          render={(sender, record) => (
            sender ? (
              <a target="_blank" href={`/patients/${sender._id}`}>
                {`${sender.firstName} ${sender.lastName}`}
              </a>
            ) : (
              `${record.senderName} (${record.senderEmail})` || 'Unknown'
            )
          )}
        />
        <Column
          title='Gift Item'
          dataIndex='productTypeCode'
          render={productTypeCode => getProductType(productTypeCode)?.title}
          fixed="left"
        />

        <Column
          title='Activation Date'
          dataIndex='giftActivationDate'
          render={giftActivationDate => moment(giftActivationDate).format('MM/DD/YYYY')}
        />

        <Column
          title='Sent'
          dataIndex='giftActivatedDate'
          render={giftActivatedDate => giftActivatedDate ? 
            <CheckCircleOutlined className="success" /> : 
            <CloseCircleOutlined className="error" />}
        />

        <Column
          title='Ordered'
          dataIndex='createdAt'
          render={createdAt => moment(createdAt).format('MM/DD/YYYY')}
        />

      </Table>
      
    </div>
  )
}