import { Typography, Button, Row, Col }  from 'antd'
import { useState, useEffect } from 'react'
import Breakpoint from '../../enums/breakpoint.enum.js'
import ProductTypeCode from "../../enums/productTypeCode.enum"
import useWidth from '../../hooks/useWidth.hook.js'
import Panels from "../panels/panels.component.js"

const { Text, Paragraph } = Typography

export const CacConfirm = ({ flow }) => {
  const width = useWidth()

  const productTypeCodes = [
    ProductTypeCode.HEART_HEALTH_TEST_KIT,
    ProductTypeCode.LONGEVITY_PANEL,
    ProductTypeCode.GALLERI,
    ProductTypeCode.WATCHPAT_ONE
  ]
  
  const instructions = [{
    sections: [
      {
      title: 'Schedule Your Scan',
      description: <>We'll email shortly to facilitate scheduling the scan at your preferred imaging center.</>
    }, 
    ]
  }, {
    sections: [{
      title: 'Get Scan Done',
      description: <>Avoid food, drink, caffeine and tobacco products for four hours before scan. The scan itself takes just a few minutes.</>
    }]
  }, {
    sections: [{
      title: 'Review Test Results',
      description: <>Most imaging centers will release results within 48 hours. We'll email you once they're uploaded to your Instalab account. Instalab specialists are available to discuss your results if needed.</>
    }]
  }]

  const kitInstructions = [{
    sections: [
      {
      title: 'Receive Test Kit',
      description: <>It'll come within one week. This includes everything you'll need to collect your blood and send to the clinical lab.</>
    }, 
  ]}, 
  {
    sections: [{
      title: 'Self Collect Blood + Mail Back',
      description: <>You'll use the Tasso+ device vs the usual fingerprick. This takes five minutes or less. Just drop in mail when done.</>
    }]
  }, 
  {
    sections: [{
      title: 'Review Test Results',
      description: <>Test results and personalized recommendations will be ready 1 week after your sample arrives at the lab..</>
    }]
  }]


  
  const hasKitPurchase = (flow?.products?.some(product => ProductTypeCode.HEART_HEALTH_TEST_KIT === product.productType?.code))

  return flow?.user && (
    <div className="prescription-approval">

      <div style={{fontWeight:600, marginBottom:10}}>Next steps for Coronary Calcium Scan:</div> 
      {instructions?.length > 0 && (
        <div className="instruction-list">
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
      )}

      {hasKitPurchase&& 
      <>
      <div style={{fontWeight:600, marginBottom:10}}>Next steps for Advanced Heart Health Test:</div> 
      {kitInstructions?.length > 0 && (
        <div className="instruction-list">
          {kitInstructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
      )}
      </>
      }

      <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button>

      <Panels productTypeCodes={productTypeCodes} />
    </div>
  )
}

