import React from 'react';
import { Menu, Dropdown, Button, Tooltip } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import "./scheduleMenu.scss"

const ScheduleMenu = ({
  productTypes = [],
  currentUser,
  menuText = "Schedule Appointment"
}) => {
  
    const navigate = useNavigate()

    // Filter credits specific to product types
    const getCreditsForProductType = (productType) => 
        currentUser?.credits?.filter(credit => credit === productType.code);

    // Calculate the total number of relevant credits for this menu
    const relevantCreditsCount = currentUser?.credits?.filter(credit => 
        productTypes.some(productType => productType.code === credit)
    ).length || 0;

    const scheduleMenu = (
        <Menu className="schedule-menu">

        {productTypes.map((productType) => {
            const creditsForProduct = getCreditsForProductType(productType);
            
            return (
                <Menu.Item
                    key={productType.code}
                    onClick={() => navigate(`/flow/${productType.flowType}`)}
                >
                    <div className="menu-item-container">
                        <span className="menu-item-title">{productType.title}</span>
                        {creditsForProduct?.length > 0 && (
                            <span className="menu-credit-count">
                                {creditsForProduct.length}
                            </span>
                        )}
                    </div>
                </Menu.Item>
            );
        })}
        </Menu>
    );

    return (
        <div>
        <Tooltip 
            title={relevantCreditsCount > 0 
                ? `${relevantCreditsCount} ${relevantCreditsCount === 1 ? 'credit available' : 'credits available'}` 
                : null}
            placement='left'
        >
        <Dropdown overlay={scheduleMenu} trigger={['click']}>
            <Button type='primary'>
            + {menuText} <DownOutlined />
            {relevantCreditsCount > 0 && (
                        <span className="credit-count">
                            {relevantCreditsCount}
                        </span>
                    )}
            </Button>
        </Dropdown>
        </Tooltip>
        </div>
    );
};

export default ScheduleMenu;