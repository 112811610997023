const Condition = Object.freeze({
  SEVERE_HYPERTENSION: 0,
  HYPERTENSION_STAGE_1: 1,
  ELEVATED_BLOOD_PRESSURE: 2,
  SEVERE_HYPERTRIGLYCERIDEMIA: 3,
  HYPERTRIGLYCERIDEMIA: 4,
  ELEVATED_TRIGYLCERIDES: 5,
  FAMILIAL_CHYLOMICRONEMIA_SYNDROME: 6,
  INSULIN_RESISTANCE: 7,
  LOW_VITAMIN_D: 8,
  LOW_VITAMIN_B12: 9,
  SUBOPTIMAL_CHOLESTEROL: 10,
  HYPERLIPIDEMIA: 11,
  HYPERURICEMIA: 12,
  HYPERHOMOCYSTEINEMIA: 13,
  ELEVATED_HOMOCYSTEINE: 14,
  HYPERCHOLESTEROLEMIA: 15,
  LOW_RESTING_HEART_RATE: 16,
  HIGH_RESTING_HEART_RATE: 17,
  TACHYCARDIA: 18,
  VASCULAR_INFLAMMATION: 19,
  HIGH_RISK_10_YEAR_CVD: 20,
  VERY_HIGH_RISK_10_YEAR_CVD: 21,
  MODERATE_10_YEAR_CVD: 22,
  APOE_E4_CARRIER: 23,
  PRE_DIABETES: 24,
  POSSIBLE_DIABETES: 25,
  METABOLIC_SYNDROME: 26,
  HYPERTHYROIDISM: 27,
  HYPOTHYROIDISM: 28,
  OBESITY: 29,
  OVERWEIGHT: 30,
  HIGH_HS_CRP: 31,
  HIGH_WBC_COUNT: 32,
  LOW_WBC_COUNT: 33,
  HIGH_FERRITIN: 34,
  LOW_FERRITIN: 35,
  CHRONIC_INFLAMMATION: 36,
  LIVER_DISEASE: 37,
  ANEMIA: 38,
  RENAL_DISEASE: 39,
  HYPOTENSION: 40,
  ABDOMINAL_OBESITY: 41,
  PRIMARY_HYPOGONADISM: 42,
  TESTOSTERONE_DEFICIENCY: 43,
  APOE_E4_CARRIER_DOUBLE: 44,
  CHRONIC_INFLAMMATION_MODERATE: 45,
  CHRONIC_INFLAMMATION_HIGH: 46,
  VASCULAR_INFLAMMATION_HIGH: 47,
  SEVERE_ANEMIA: 48,
  IRON_DEFICIENCY_ANEMIA_MALE: 49,
  IRON_DEFICIENCY_ANEMIA_FEMALE: 50,
  MODERATE_ANEMIA: 51,
  MILD_ANEMIA: 52,
  RENAL_DISEASE_SEVERE: 53,
  RENAL_DISEASE_MODERATE: 54,
  SECONDARY_HYPOGONADISM: 55,
  LIVER_DISEASE_SEVERE: 56,
  NAFLD_METABOLIC_SYNDROME: 57,
  NAFLD_METABOLIC_SYNDROME_LIKE: 58,
  LIVER_DISEASE_MODERATE: 59,
  LIVER_DISEASE_BORDERLINE: 60,
  DIABETES: 61,
  THYROID_ISSUE: 62,
  GILBERTS_SYNDROME: 63,
  B12_DEFICIENCY_ANEMIA: 64,
  HIGH_LPA: 65,
  LOW_GRIP_STRENGTH: 66,
  INSULIN_RESISTANCE_MAYBE: 67,
  VERY_LOW_GRIP_STRENGTH: 68,
  ISOLATED_HIGH_FREE_T4: 69,
  SMOKING: 70,
  ELEVATED_URIC_ACID: 71,
  LIVER_DISEASE_ALCOHOL: 72,
  HIGH_SHBG_BIRTH_CONTROL: 73,
  RHABDOMYOLYSIS: 74,
  DEHYDRATION: 75,
  ISOLATED_DIRECT_HYPERBILIRUBINEMIA: 76,
  LOW_VITAMIN_B9: 77,
  MACROCYTOSIS: 78,
  SIBO: 79,
  LOW_IRON: 80,
  RECENT_VIRAL_INFECTION: 81,
  POSSIBLE_FH: 82,
  HRT_LOW_FSH_LH: 83,
  HYPERTENSION_STAGE_2: 84,
  ISOLATED_ALT_AST: 85,
  POSSIBLE_T_SUPPLEMENTATION: 86,
  HIGH_CREATININE_SUPPLEMENTATION: 87,
  CKD: 88,
  CHOLESTEROL_OVER_ABSORBER: 89,
  CHOLESTEROL_OVER_PRODUCER: 90,
  HIGH_TESTOSTERONE_FEMALE: 91,
  VERY_MUSCULAR: 92,
  ISOLATED_INDIRECT_HYPERBILIRUBINEMIA: 93,
  POSSIBLE_FERTILITY_ISSUE: 94,
  CHRONIC_LOW_WBC_COUNT: 95,
  THALASSEMIA: 96,
  ISOLATED_HYPERBILIRUBINEMIA: 97,
  SUBPEAK_CHOLESTEROL: 98,
  HIGH_VITAMIN_B12: 99,
  POSSIBLE_NASH: 100,
  HIGH_VITAMIN_D: 101,
  MILD_HYPONATREMIA: 102,
  MODERATE_HYPONATREMIA: 103,
  SEVERE_HYPONATREMIA: 104,
  POSSIBLE_TRANSIENT_LIVER_ISSUE: 105,
  POSSIBLE_STATIN_SIDE_EFFECT: 106,
  SUBCLINICAL_HYPOTHYROIDISM: 107,
  COMPENSATED_HYPOGONADISM: 108,
  POSSIBLE_MODY2: 109,
  ISOLATED_GLUCOSE: 110,
  PROSTATE_ISSUE: 111,
  LOW_OMEGA_3: 112,
  CK_LIVER: 113,
  ELEVATED_GLUCOSE: 114,
  ELEVATED_HBA1C: 115,
  LOW_PLATLET_COUNT: 116,
  RENAL_DISEASE_BORDERLINE: 117,
  SERM_HIGH_FSH_LH: 118,
  ISOLATED_HIGH_ANTI_TPO: 119,
  AUTOIMMUNE_ISSUE: 120,
  AUTOIMMUNE_ISSUE_MAYBE: 121,
  HIGH_CAC: 122,
  LOW_ALMI: 123,
  HIGH_VAT: 124,
  POSSIBLE_EXERCISE_SIDE_EFFECT: 125,
  ZERO_CAC: 126,
  SLEEP_APNEA: 127,
  POSSIBLE_SLEEP_APNEA: 128,
  HIGH_CORTISOL: 129,
  LOW_CORTISOL: 130
});

export default Condition;
