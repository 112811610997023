import { Typography } from "antd";
import classNames from "classnames";
import { StickyBlogHeader } from "./stickyBlogHeader.component";

const { Paragraph } = Typography

export const ForecastPost = () => {
  return (
    <div className={classNames("blog-post", "forecast-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          The Growing Burden of Heart Disease in the United States: A Forecast for the Future
        </Paragraph>
        <Paragraph className="blog-subheading">
          Heart disease remains one of the most significant health challenges in the U.S., affecting millions of lives each year. According to the latest data from the American Heart Association's (AHA) 2024 Heart Disease and Stroke Statistics report, heart disease continues to be the leading cause of death in the country. 
          <br/><br/>
          And the outlook is alarming: projections indicate that by 2050, more than half of the U.S. adult population will have some form of cardiovascular disease (CVD), highlighting the need for urgent action to address this growing epidemic.
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt="Oct 23, 2024"
        readTime="5 Min Read"
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          Heart Disease Today: Alarming Prevalence and Rising Mortality
        </Paragraph>
        <Paragraph className="blog-description">
          Today, cardiovascular disease affects approximately <strong>127.9 million Americans</strong>, nearly half of the adult population. CVD includes conditions such as heart attacks, strokes, and heart failure, and it remains the number one cause of death in the U.S. Each year, CVD claims more than 655,000 lives—about one in every four deaths.
        </Paragraph>
        <Paragraph className="blog-description">
          Despite advancements in medical treatments and health awareness campaigns, the nation has seen a worrying reversal in mortality rates. After decades of decline, deaths from cardiovascular disease are once again on the rise, driven by factors such as increasing rates of obesity, diabetes, and an aging population.
        </Paragraph>

        <Paragraph className="blog-title">
          What the Future Holds: Over Half of Americans Will Be Affected by 2050
        </Paragraph>
        <Paragraph className="blog-description">
          The AHA's 2024 projections paint a stark picture of the future. <strong>By 2050, it is estimated that 61% of adults in the U.S. will suffer from cardiovascular disease</strong>, a significant jump from today's numbers. The rise is largely attributed to key risk factors like hypertension (high blood pressure), which is expected to affect over 60% of the population by mid-century.
        </Paragraph>
        <Paragraph className="blog-description">
          Other major risk factors for CVD, such as obesity and diabetes, are also expected to rise dramatically. By 2050, obesity prevalence is forecast to climb to over 60.6%, and diabetes prevalence will increase from 16.3% to 26.8%, compounding the nation's cardiovascular health crisis.
        </Paragraph>

        <Paragraph className="blog-title">
          The Economic Toll
        </Paragraph>
        <Paragraph className="blog-description">
          The economic impact of heart disease is staggering. Cardiovascular disease costs the U.S. an estimated $422.3 billion annually in direct medical expenses and indirect costs from lost productivity. As CVD prevalence rises, these costs are expected to soar, putting a tremendous strain on the healthcare system and the economy.
        </Paragraph>

        <Paragraph className="blog-title">
          A Widening Health Disparity
        </Paragraph>
        <Paragraph className="blog-description">
          Heart disease doesn't affect everyone equally. The AHA report highlights significant racial and ethnic disparities in cardiovascular health. Black, Hispanic, and American Indian/Alaska Native populations face disproportionately higher rates of cardiovascular disease and related risk factors. For example, Black adults have the highest prevalence of hypertension and heart failure, while Hispanic adults are projected to see the greatest increase in obesity and diabetes.
        </Paragraph>

        <Paragraph className="blog-title">
          Prevention Is Key: What Needs to Change
        </Paragraph>
        <Paragraph className="blog-description">
          The AHA emphasizes that prevention is crucial to stemming the tide of heart disease in the U.S. The organization's <i>Life's Essential 8</i>—a framework that includes maintaining a healthy diet, exercising regularly, avoiding smoking, managing blood pressure, and controlling cholesterol and blood sugar levels—is critical for reducing the burden of CVD. Even modest improvements in these key health behaviors and risk factors can significantly lower the risk of heart disease.
        </Paragraph>
        <Paragraph className="blog-description">
          However, the report also warns that if current trends continue unchecked, the prevalence of cardiovascular disease will continue to rise, leading to a public health crisis. Preventive measures, particularly targeting risk factors like obesity, diabetes, and hypertension, must be prioritized at both the individual and national levels to mitigate future health and economic impacts.
        </Paragraph>

        <Paragraph className="blog-title">
          Conclusion: Urgent Action Needed
        </Paragraph>
        <Paragraph className="blog-description">
          As the 2024 report shows, cardiovascular disease is poised to affect more than half of the U.S. population by 2050, making it one of the most pressing health challenges of our time. To reverse these trends, a comprehensive approach is needed—one that includes policy changes, community-level interventions, and personal responsibility for lifestyle changes. Addressing the risk factors that drive heart disease can save lives and reduce the growing strain on the healthcare system.
        </Paragraph>
        <Paragraph className="blog-description">
          The future of heart disease in America is not set in stone. With concerted efforts in prevention, treatment, and education, we can turn the tide and create a healthier future for all.
        </Paragraph>
      </div>
    </div>
  );
};