import { Typography, Table } from "antd";
import classNames from "classnames";
import { StickyBlogHeader } from "./stickyBlogHeader.component";

const { Paragraph } = Typography;

export const BloodPressureDietPost = () => {

  return (
    <div className={classNames("blog-post", "blood-pressure-diet-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          Can you lower blood pressure with just diet changes?
        </Paragraph>

        <Paragraph className="blog-subheading">
          For years, most people have accepted high blood pressure as a problem that needs pills to fix. And while medication is crucial for some, what if changing your diet could naturally lower your blood pressure? Research suggests that you don't always need a prescription to take control of your numbers—sometimes, it's as simple as what's on your plate.
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt="Nov 10, 2024"
        readTime="7 Min Read"
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          The Power of Sodium and Potassium
        </Paragraph>
        <Paragraph className="blog-description">
          Sodium and potassium aren't just nutrients—they're essential minerals that help regulate your body's fluid balance, nerve function, and even your blood pressure. Here's how they work and why tweaking these can make a big difference.
        </Paragraph>

        <Paragraph className="blog-title">
          Sodium: The "Water Magnet"
        </Paragraph>
        <Paragraph className="blog-description">
          Sodium, which we usually get from salt, acts like a sponge in your body, pulling water into your bloodstream. This water retention is necessary for keeping cells hydrated and allowing nerves and muscles to function. But when there's too much sodium in your system, it can increase the fluid volume in your blood vessels, creating pressure against the vessel walls—this is what raises your blood pressure.
        </Paragraph>
        <Paragraph className="blog-description">
          Think of your blood vessels as a garden hose. The more water you put through, the higher the pressure inside that hose. Lowering sodium is like turning down the water flow—it reduces fluid volume, easing pressure on your blood vessels and lowering your blood pressure.
        </Paragraph>

        <Paragraph className="blog-title">
          Potassium: The "Pressure Release Valve"
        </Paragraph>
        <Paragraph className="blog-description">
          Potassium, on the other hand, is found in foods like bananas, leafy greens, and sweet potatoes. It works as a counterbalance to sodium by helping your kidneys flush out excess sodium through urine. This mineral also relaxes the muscles surrounding blood vessels, helping them widen and allowing blood to flow with less resistance—like releasing pressure from that garden hose.
        </Paragraph>
        <Paragraph className="blog-description">
          When you have enough potassium, it naturally reduces blood pressure by making it easier for blood to flow. But when sodium levels are too high, potassium's balancing effects become limited, and blood pressure rises. That's why balancing both minerals can help create a natural way to support a healthy blood pressure.
        </Paragraph>

        <Paragraph className="blog-title">
          How Much of an Impact Can Dietary Changes Have on Blood Pressure?
        </Paragraph>
        <Paragraph className="blog-description">
          Below are data tables summarising research findings on the blood pressure-lowering effects of reducing sodium intake and increasing potassium intake, drawn from key studies.
        </Paragraph>

        <Paragraph className="blog-title">
          Table 1: Impact of Lowering Sodium on Blood Pressure
        </Paragraph>
        <Table
          className="blog-table"
          dataSource={[
            {
              study: 'He, Li & MacGregor',
              studyUrl: `https://doi.org/10.1136/bmj.f1325`,
              reduction: '75',
              systolic: '4.2',
              diastolic: '2.1'
            },
            {
              study: 'Graudal, Hubeck-Graudal & Jurgens',
              studyUrl: `https://doi.org/10.1002/14651858.CD004022.pub3`,
              reduction: '125',
              systolic: '1.3 (normotensive) / 5.5 (hypertensive)',
              diastolic: '0.05 (normotensive) / 2.8 (hypertensive)'
            },
            {
              study: 'Geleijnse, Kok & Grobbee',
              studyUrl: `https://doi.org/10.1093/eurpub/14.3.235`,
              reduction: '100',
              systolic: '3.5',
              diastolic: '1.5'
            }
          ]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column 
            title="Study" 
            dataIndex="study" 
            render={(text, record) => (
              <a onClick={() => window.open(record.studyUrl, '_blank')} className="blog-link">{text}</a>
            )}
          />
          <Table.Column title="Sodium Reduction (mmol/day)" dataIndex="reduction" />
          <Table.Column title="Systolic BP Reduction (mm Hg)" dataIndex="systolic" />
          <Table.Column title="Diastolic BP Reduction (mm Hg)" dataIndex="diastolic" />
        </Table>

        <Paragraph className="blog-title">
          Table 2: Impact of Increasing Potassium on Blood Pressure
        </Paragraph>
        <Table
          className="blog-table"
          dataSource={[
            {
              study: 'Aburto et al.',
              studyUrl: `https://doi.org/10.1136/bmj.f1378`,
              increase: '90-120',
              systolic: '3.5',
              diastolic: '2.0'
            },
            {
              study: 'He, Li & MacGregor',
              studyUrl: `https://doi.org/10.1136/bmj.f1378`,
              increase: '100',
              systolic: '4.0',
              diastolic: '2.0'
            },
            {
              study: 'Geleijnse, Kok & Grobbee',
              studyUrl: `https://doi.org/10.1093/eurpub/14.3.235`,
              increase: '100',
              systolic: '3.5',
              diastolic: '1.9'
            }
          ]}
          pagination={false}
          size='small'
          bordered
        >
          <Table.Column 
            title="Study" 
            dataIndex="study" 
            render={(text, record) => (
              <a onClick={() => window.open(record.studyUrl, '_blank')} className="blog-link">{text}</a>
            )}
          />
          <Table.Column title="Potassium Intake Increase (mmol/day)" dataIndex="increase" />
          <Table.Column title="Systolic BP Reduction (mm Hg)" dataIndex="systolic" />
          <Table.Column title="Diastolic BP Reduction (mm Hg)" dataIndex="diastolic" />
        </Table>

        <Paragraph className="blog-description">
          These tables highlight the average reductions in blood pressure that you might expect by adjusting sodium and potassium intake. Keep in mind results vary based on individual health factors like ethnicity and genetics.
        </Paragraph>

        <Paragraph className="blog-title">
          Practical Tips for Balancing Sodium and Potassium
        </Paragraph>
        <Paragraph className="blog-description">
          For those of us who don't want to spend time adding up every milligram of salt and potassium, here are simple ways to improve your intake naturally:
        </Paragraph>

        <ol className="blog-list">
          <li>
            <strong>Watch Out for Hidden Sodium: </strong> Check food labels to spot high-sodium sources like processed foods, sauces, and restaurant meals. Choosing lower-sodium options a few times a week can quickly cut hundreds of milligrams.
          </li>

          <li>
            <strong>Swap for Unsalted Snacks: </strong> Opt for unsalted versions of your go-to snacks, like nuts, seeds, or popcorn. This simple swap reduces sodium intake by hundreds of milligrams a week, all without extra planning.
          </li>

          <li>
            <strong>Sneak in Potassium with Easy Add-Ins: </strong> Add potassium-rich foods to meals you already enjoy. Think banana slices in cereal, beans in a salad, or spinach in pasta.
          </li>
        </ol>

        <Paragraph className="blog-description">
          With these quick swaps, you can support better blood pressure control—no calculations required.
        </Paragraph>

        <Paragraph className="blog-title">
          How Long Will It Take to See Results?
        </Paragraph>
        <Paragraph className="blog-description">
          Making changes to sodium and potassium in your diet isn't a quick fix. While some people may start noticing slight changes within a few weeks, research suggests it can take several months of consistent effort to see more significant reductions in blood pressure. 
        </Paragraph>
        <Paragraph className="blog-description">
          This process requires patience—your body needs time to adjust to these new levels, especially as it works to balance sodium and potassium over time. By sticking with these diet changes, you're making steady progress that will add up to meaningful results for your blood pressure.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Aburto, N. J., et al. (2013). Effect of increased potassium intake on cardiovascular risk factors and disease: systematic review and meta-analyses. BMJ, 346, f1378. <a onClick={() => window.open('https://doi.org/10.1136/bmj.f1378', '_blank')}>https://doi.org/10.1136/bmj.f1378</a>
            </li>
            <li>
              He, F. J., Li, J., & MacGregor, G. A. (2013). Effect of longer term modest salt reduction on blood pressure: Cochrane systematic review and meta-analysis of randomised trials. BMJ, 346, f1325. <a onClick={() => window.open('https://doi.org/10.1136/bmj.f1325', '_blank')}>https://doi.org/10.1136/bmj.f1325</a>
            </li>
            <li>
              Graudal, N. A., Hubeck-Graudal, T., & Jurgens, G. (2011). Effects of low sodium diet versus high sodium diet on blood pressure, renin, aldosterone, catecholamines, cholesterol, and triglyceride. Cochrane Database of Systematic Reviews, Issue 11, CD004022. <a onClick={() => window.open('https://doi.org/10.1002/14651858.CD004022.pub3', '_blank')}>https://doi.org/10.1002/14651858.CD004022.pub3</a>
            </li>
            <li>
              Geleijnse, J. M., Kok, F. J., & Grobbee, D. E. (2004). Impact of dietary and lifestyle factors on the prevalence of hypertension in Western populations. European Journal of Public Health, 14(3), 235-239. <a onClick={() => window.open('https://doi.org/10.1093/eurpub/14.3.235', '_blank')}>https://doi.org/10.1093/eurpub/14.3.235</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
