import { useEffect, useState } from 'react'
import { Form, Typography, Row, Col, Button, Alert, Spin} from "antd"
import { Selector } from 'antd-mobile'
import { WarningFilled, DownOutlined } from '@ant-design/icons'
import "./facility.scss"
import { FlowStepFooter } from '../../flowStep/flowStepFooter.component'
import { getNearbyFacilities } from '../../../../services/facility.service'

const { Item } = Form
const { Text, Paragraph } = Typography

export const Facility = ({
  step,
  flow,
  onSubmit,
  form,
  isSubmitting,
  onNextStep
}) => {
  const fieldWatch = Form.useWatch(step?.field?.name, form) || [] // Safe check for step and step.field
  const [rules, setRules] = useState([])
  const letters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
  const [location, setLocation] = useState()
  const [facilities, setFacilities] = useState([])
  const [visibleFacilities, setVisibleFacilities] = useState(3) 
  const [noFacilitiesFound, setNoFacilitiesFound] = useState(false)
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [onSubmit]);

  useEffect(() => {
    if (step) {
      fetchRules();
    }
  }, [step])

  useEffect(() => {
    if (flow) {
      fetchLocation();
    }
  }, [flow])

  useEffect(() => {
    if (location) {
      fetchClosestFacilities();
    }
  }, [location]);


  const fetchLocation = () => {
    setLocation(flow?.appointment?.location || flow?.user?.location);
  }

  const fetchRules = () => {
    if (!step?.field) return;
    setRules([{
      required: true,
      message: <><WarningFilled />&nbsp; Select an option.</>,
    }]);
  }

  const fetchClosestFacilities = async () => {
    if (!location || !step?.productTypeCode) return;
    setIsLoading(true); // Show loader

    try {
      const { latitude, longitude } = location
      const response = await getNearbyFacilities({
        latitude: latitude, 
        longitude: longitude, 
        productTypeCode: step.productTypeCode
      });

      setIsLoading(false); // Hide loader

      if (!response || !Array.isArray(response) || response.length === 0) {
        setNoFacilitiesFound(true);  
      } else {
        setNoFacilitiesFound(false);
        setFacilities(response);  
      }
    } catch (error) {
      console.error("Error fetching nearby facilities:", error);
      setIsLoading(false); // Hide loader in case of error
    }
  };

  // Handle displaying more facilities (3 at a time)
  const handleLoadMore = () => {
    setVisibleFacilities(prevVisible => prevVisible + 3);
  }

  // Facilities to display (first 3, then load more)
  const displayedFacilities = facilities.slice(0, visibleFacilities);

  // Create options from facilities to display
  const options = displayedFacilities.map((facility, index) => {

    let price = null;
    if (step.showPrice && step.productTypeCode) {
      const menu = facility.menu || null
      const menuItem = menu.find(item => item.productType.code === step.productTypeCode)
      price = menuItem.price
    }

    return {
      value: facility._id || facility.questId,
      label: (
        <div className="product-select-label">
          <Row>
            <Col className="col-1">
              <div className="product-select-abbr">
                {letters[index]}
              </div>
            </Col>
            <Col className="col-2">
              <Paragraph className="option-title">
                {facility.name} 
              </Paragraph>
              <Paragraph className="option-description">
                {facility.location.streetAddress ? `${facility.location.streetAddress}, ` : ''} 
                {facility.location.city ? `${facility.location.city}, ` : ''} 
                {facility.location.state ? facility.location.state : ' '}
                {facility.location.postalCode ? ` ${facility.location.postalCode}` : ''}
              </Paragraph>     
              <Text className="option-price">
                {price ===0 ? 'Free' : price ? `$${price}` : `${Number(facility.distance).toFixed(1)}  mi`}
              </Text>
                      
            </Col>
          </Row>
        </div>
      )
    }
  });

  return (
    facilities &&  <div className="facility-select">
       {isLoading ? ( // Display loader while fetching facilities
        <div style={{ textAlign: 'left', padding: '20px 0' }}>
          <Spin size="large" />
        </div>
      ) : noFacilitiesFound ? (
        <Alert 
          type="info"
          message={<>We don’t have any nearby facilities in our system right now, but no worries—we’re here to help! Email <a href="mailto:concierge@instalab.com">concierge@instalab.com</a>, and we’ll personally assist you in finding one.</>}
          ></Alert>
      ) :
        <Form
          form={form}
          className="product-select-form"
        >
          <Item 
            key={`field-${step.field.name}`}
            name={step.field.name}
            className="product-select-item"
            validateTrigger='onSubmit'
            rules={rules}
          >
            <Selector 
              onChange={value => {
                if (value?.length && !step.multiple) {
                  onSubmit();
                }
              }}
              disabled={step.isLocked && step.isLocked()}
              options={options}
              className="product-select-field"
            />
          </Item>

        {/* Load more button (shown if more facilities exist) */}
        {facilities.length > visibleFacilities && (
          <div className="load-more-container">
            <Button 
              type="default" 
              onClick={handleLoadMore} 
              className="load-more-button"
              size="large"
            >
             View more <DownOutlined/>
            </Button>
          </div>
        )}

          {(fieldWatch?.length > 0 ) && (
            <FlowStepFooter 
              step={step}
              onSubmit={() => onSubmit()} 
              isSubmitting={isSubmitting}
              buttonText={step.buttonText}
              onNextStep={onNextStep}
              hasSkip={step.hasSkip}
            />
          )}
        </Form>}
      
    </div>
  )
}