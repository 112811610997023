import { Typography } from "antd";
import classNames from "classnames";
import { StickyBlogHeader } from "./stickyBlogHeader.component";

const { Paragraph } = Typography

export const InsurancePost = () => {
  return (
    <div className={classNames("blog-post", "insurance-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          Maximize Your Health Savings with Instalab: Using HSA and FSA to Stay Proactive
        </Paragraph>
        <Paragraph className="blog-subheading">
          Health Savings Accounts (HSAs) and Flexible Spending Accounts (FSAs) are powerful tools to help you manage healthcare costs while focusing on proactive health care. By understanding how these accounts work and how you can leverage them for services like those provided by Instalab, you can make significant strides in your health journey—without breaking the bank.
          <br/><br/>
          Here's how you can make the most of your HSA and FSA, especially with the services available through Instalab.
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt="Oct 15, 2024"
        readTime="4 Min Read"
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          What is an HSA?
        </Paragraph>
        <Paragraph className="blog-description">
          An HSA is a tax-advantaged savings account designed for individuals with high-deductible health plans (HDHPs). It allows you to set aside pre-tax dollars for qualified medical expenses, including a broad range of health services—from routine doctor visits to medications, lab tests, and wellness programs.
        </Paragraph>

        <Paragraph className="blog-title">
          Key Benefits:
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>Triple tax advantage:</strong> Contributions, earnings, and withdrawals are all tax-free when used for qualified expenses.
          </li>
          <li>
            <strong>Funds roll over:</strong> HSA funds don't expire. If you don't use the money this year, it rolls over to the next—making it a great way to save for future health needs.
          </li>
          <li>
            <strong>Investment opportunities:</strong> You can invest your HSA funds to grow your savings for long-term health expenses.
          </li>
        </ul>

        <Paragraph className="blog-title">
          What is an FSA?
        </Paragraph>
        <Paragraph className="blog-description">
          An FSA allows you to save money on medical expenses using pre-tax dollars. Unlike an HSA, FSAs are typically available through your employer and can be used for a variety of health-related costs, such as medical, dental, vision, and dependent care.
        </Paragraph>

        <Paragraph className="blog-title">
          Key Benefits:
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>Tax savings:</strong> Your contributions lower your taxable income, meaning more savings.
          </li>
          <li>
            <strong>Eligible expenses:</strong> FSAs can be used for a wide range of healthcare expenses, from over-the-counter medications to therapy sessions.
          </li>
          <li>
            <strong>Employer contributions:</strong> Some employers may add extra contributions to your FSA balance.
          </li>
        </ul>
        <Paragraph className="blog-title">
          Key Differences Between HSA and FSA
        </Paragraph>
        <ul className="blog-list">
          <li>
            <strong>Eligibility:</strong> HSAs are only available if you have a high-deductible health plan (HDHP), while FSAs are employer-sponsored and available to most employees.
          </li>
          <li>
            <strong>Rollover rules:</strong> HSA funds roll over from year to year, but FSAs typically have a "use it or lose it" rule. Some FSAs may offer a grace period or allow a small portion of funds to carry over.
          </li>
          <li>
            <strong>Account ownership:</strong> HSAs are owned by you, meaning you keep the account if you change jobs. FSAs are tied to your employer.
          </li>
        </ul>

        <Paragraph className="blog-title">
          Maximize Your HSA/FSA with Instalab
        </Paragraph>
        <Paragraph className="blog-description">
          Using your HSA or FSA with Instalab is super simple. When booking services like lab tests, scans, or consultations through our platform, you can pay directly with your HSA or FSA card. If your HSA/FSA provider requires documentation, Instalab provides clear, itemized receipts for easy reimbursement. Our team is also available to help guide you through the process if you have any questions about eligibility or payment.
        </Paragraph>

        <Paragraph className="blog-description">
          <strong>P.S.</strong> Have questions about using your HSA or FSA with Instalab? Reach out to our team for guidance on how to make the most of your health savings accounts. We're here to support you on your health journey!
        </Paragraph>
      </div>
    </div>
  );
};