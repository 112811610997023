import { Typography } from "antd";
import classNames from "classnames";
import { StickyBlogHeader } from "./stickyBlogHeader.component";

const { Paragraph } = Typography

export const PeakPost = () => {
  return (
    <div className={classNames("blog-post", "peak-post")}>
      <div className="blog-intro">
        <Paragraph className="blog-heading">
          Peak Health Ranges: A Data-Driven Approach
        </Paragraph>
        <Paragraph className="blog-subheading">
          Understanding your lab results is crucial to making well-informed health decisions. Traditional reference ranges, based on average values of a healthy population, define normal upper and lower limits for lab tests. 
          <br/><br/>
          While useful, these ranges can fall short in actually detecting the early stages of disease and extending lifespan and healthspan. This gap is addressed by the concept of 'peak health' ranges, which provide a more nuanced, individualized, and proactive approach to health management.
        </Paragraph>
      </div>

      <StickyBlogHeader 
        publishedAt="Dec 18, 2023"
        readTime="6 Min Read"
      />

      <div className="blog-body">
        <Paragraph className="blog-title">
          Normal vs peak health ranges
        </Paragraph>
        <Paragraph className="blog-description">
          Normal ranges are statistical constructs, representing the 95th percentile of a healthy population based on a bell curve. Their primary purpose is to identify values that significantly deviate from the norm, which may indicate a health issue. While indicative of what's common, these ranges don't necessarily reflect optimal health. A critical limitation is that individuals in the early stages of a disease, especially well before symptoms appear, might still fall within these 'normal' ranges.
        </Paragraph>
        <Paragraph className="blog-description">
          In contrast, peak health ranges are often more specific and narrower. They are established by studying population segments exhibiting superior health outcomes. It's not just about reducing disease risk but aiding in the early detection of health issues and enhancing physical and mental performance where applicable.
        </Paragraph>

        <Paragraph className="blog-title">
          Instalab's methodology for peak health ranges
        </Paragraph>
        <Paragraph className="blog-description">
          Instalab's approach to defining peak health ranges is rooted in a rigorous, data-driven approach:
        </Paragraph>

        <Paragraph className="blog-description">
          <strong>Extensive data review</strong>: We leverage studies with large-scale population datasets that provide invaluable insights over extended periods. This approach is beneficial as it facilitates tracking long-term health trends and outcomes, which are essential for understanding the impacts of varying biomarker levels.
        </Paragraph>

        <Paragraph className="blog-description">
          <strong>Benchmarking against the top percentile</strong>: Biomarkers behave differently. For some, increasing or decreasing levels for an indefinite period of time is not only beneficial but has been proven to pose no collateral risks. For these biomarkers, we look at the top 1-2% of the population to establish achievable benchmarks for peak health. For example, continuously lowering ApoB and LDL cholesterol shows significant benefits in reducing heart attack and stroke risk, with peak health levels being &lt;54 mg/dL and &lt;70 mg/dL respectively, compared to &lt;90 and &lt;99 mg/dL, what is normally considered healthy.
        </Paragraph>

        <Paragraph className="blog-description">
          <strong>Determining in-between ranges</strong>: For biomarkers where extreme levels in either direction pose a health risk, we take a more nuanced approach. In particular, we look at how certain biomarker levels impact the risk of all relevant health conditions. By pinpointing these correlations, we can accurately determine the thresholds at which these risks escalate significantly, leading to reference ranges narrower than normally recommended. 
        </Paragraph>
        <Paragraph className="blog-description">
          For instance, fasting glucose, used to determine insulin resistance, has a normal range of 70-100 mg/dL. Levels above this indicate progressively worsening insulin resistance and an increased risk of developing diabetes, while levels below the range can lead to hypoglycemia and associated symptoms. However, research now points to a narrower fasting glucose range, between 85-99 mg/dL, peaking around 90 mg/dL, which can optimally decrease cardiovascular death.
        </Paragraph>
        <Paragraph className="blog-description">
          <strong>Keeping up to date</strong>: We're always evolving our peak health ranges based on new research and real-world experience from working with many patients. If you're not a patient yet, you can keep updated on these changes by subscribing to our newsletter.
        </Paragraph>

        <Paragraph className="blog-description">
          <strong>Dynamic, personalized ranges</strong>: A biomarker level might be optimal for one person but suboptimal for another for various reasons including differences in genetics, existing medical conditions, current medications, etc. When the datasets allow for such segmentation, we aim to personalize peak health ranges to account for such individual differences. For instance, optimal grip strength, a key indicator of reduced risk for dementia, age-related physical issues, and other various chronic conditions, needs to account not just for sex but also for body size.
        </Paragraph>

        <Paragraph className="blog-description">
          <strong>Continuous updating and refinement</strong>: As new research emerges and scientific understanding evolves, these peak health ranges are continuously updated and refined. This ensures they remain aligned with the latest scientific evidence.
        </Paragraph>

        <Paragraph className="blog-description">
          <strong>Collaboration with practicing physicians</strong>: We work closely with medical specialists to ensure that our findings are not only grounded in scientific research but are also clinically relevant and practical for real-world application.
        </Paragraph>

        <Paragraph className="blog-title">
          Keeping up to date
        </Paragraph>
        <Paragraph className="blog-description">
          We're always evolving our peak health ranges based on new research and real-world experience from working with many patients. If you're not a patient yet, you can keep updated on these changes by subscribing to our newsletter.
        </Paragraph>

        <div className="sources">
          <Paragraph className="blog-title">References</Paragraph>
          <ul className="source-list">
            <li>
              Impact of Lipids on Cardiovascular Health (Journal of the American College of Cardiology, 2018) <a onClick={() => window.open('https://pubmed.ncbi.nlm.nih.gov/30165986/', '_blank')}>https://pubmed.ncbi.nlm.nih.gov/30165986/</a>
            </li>
            <li>
              Efficacy and Safety of Further Lowering of Low-Density Lipoprotein Cholesterol in Patients Starting With Very Low Levels (JAMA Cardiology, 2018) <a onClick={() => window.open('https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6233651/', '_blank')}>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6233651/</a>
            </li>
            <li>
              Apolipoprotein B and cardiovascular disease risk: position statement from the AACC Lipoproteins and Vascular Diseases Division Working Group on Best Practices (Clinical Chemistry, 2009) <a onClick={() => window.open('https://pubmed.ncbi.nlm.nih.gov/19168552/', '_blank')}>https://pubmed.ncbi.nlm.nih.gov/19168552/</a>
            </li>
            <li>
              Apolipoprotein B <a onClick={() => window.open('https://www.labcorp.com/tests/167015/apolipoprotein-b', '_blank')}>https://www.labcorp.com/tests/167015/apolipoprotein-b</a>
            </li>
            <li>
              Low-density Lipoprotein Cholesterol (Direct) <a onClick={() => window.open('https://www.labcorp.com/tests/120295/low-density-lipoprotein-cholesterol-direct', '_blank')}>https://www.labcorp.com/tests/120295/low-density-lipoprotein-cholesterol-direct</a>
            </li>
            <li>
              Fasting Glucose Level and the Risk of Incident Atherosclerotic Cardiovascular Diseases (Diabetes Care, 2013) <a onClick={() => window.open('https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3687304/', '_blank')}>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3687304/</a>
            </li>
            <li>
              Handgrip strength and all‐cause dementia incidence and mortality: findings from the UK Biobank prospective cohort study (Journal of Cachexia, Sarcopenia and Muscle, 2022) <a onClick={() => window.open('https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9178163/', '_blank')}>https://www.ncbi.nlm.nih.gov/pmc/articles/PMC9178163/</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};