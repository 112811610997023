import React, { useEffect, useState, useContext, useRef } from 'react';
import { debounce } from 'lodash';

import Color from '../../colors.scss';
import parse from 'html-react-parser';
import moment from 'moment';
import { Typography, Row, Col, Statistic, Spin } from 'antd';
import { getAnnotatedTest } from '../../services/test.service';
import RiskLevel from '../../enums/riskLevel.enum';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../contexts/user.context';
import { listRiskRanges } from '../../services/test.service';
import { listAnnotatedResults } from '../../services/result.service';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import { Chart as ChartJS, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip as ChartTooltip, Legend } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import CountUp from 'react-countup';
import 'chartjs-adapter-date-fns';
import './testChart.scss';
import Role from '../../enums/role.enum';
import { listReports } from '../../services/report.service';
import { listPrescriptionEvents } from '../../services/prescription.service';
import ReportStatus from '../../enums/reportStatus.enum';
import PrescriptionEvent from '../../enums/prescriptionEvent.enum';
import PrescriptionType from '../../enums/prescriptionType.enum';
import TestCode from '../../enums/testCode.enum';

ChartJS.register(
  TimeScale, 
  LinearScale, 
  PointElement, 
  LineElement, 
  Title, 
  ChartTooltip, 
  Legend,
  annotationPlugin
);

const startColor = Color.success
const stopColor = Color.error

const APOE_TEST_ID = '621d2a9d5a43a99402ccd0be'
const APOE_GENOTYPES = ['e2/e2', 'e2/e3', 'e2/e4', 'e3/e3', 'e3/e4', 'e4/e4'];

const healthScoreRisk = [{
  level: RiskLevel.OPTIMAL,
  greaterThanOrEqual: 90,
},
{
  level: RiskLevel.MODERATE,
  greaterThanOrEqual: 75,
  lessThan: 90,
},
{
  level: RiskLevel.HIGH,
  lessThan: 75,
}]

const prescriptionEffects = [{
  // cholesterol
  prescriptionTypes: [
    PrescriptionType.EZETIMIBE_10MG,
    PrescriptionType.ROSUVASTATIN_2500MCG,
    PrescriptionType.ROSUVASTATIN_5MG,
    PrescriptionType.ROSUVASTATIN_10MG,
    PrescriptionType.ROSUVASTATIN_20MG,
    PrescriptionType.ROSUVASTATIN_40MG,
    PrescriptionType.BEMPEDOIC_ACID_180MG,
    PrescriptionType.ALIROCUMAB_75MG,
    PrescriptionType.ALIROCUMAB_150MG,
    PrescriptionType.EVOLOCUMAB_140MG,
    PrescriptionType.LEQVIO_284MG,
    PrescriptionType.BERBERINE_500MG,
    PrescriptionType.RED_YEAST_RICE_600MG,
    PrescriptionType.PITAVASTATIN_1MG,
    PrescriptionType.PITAVASTATIN_2MG,
    PrescriptionType.PITAVASTATIN_4MG,
  ],
  testCodes: [
    TestCode.APO_B,
    TestCode.LIPOPROTEIN_A,
    TestCode.TC,
    TestCode.DIRECT_LDL,
    TestCode.HDL,
    TestCode.VLDL,
    TestCode.TG,
  ]
}, {
  // uric acid
  prescriptionTypes: [
    PrescriptionType.ALLOPURINOL_100MG,
    PrescriptionType.ALLOPURINOL_300MG,
  ],
  testCodes: [
    TestCode.URIC_ACID,
  ]
}, {
  // insulin resistance
  prescriptionTypes: [
    PrescriptionType.METFORMIN_500MG,
    PrescriptionType.METFORMIN_850MG,
    PrescriptionType.METFORMIN_1000MG,
    PrescriptionType.METFORMIN_ER_500MG,
    PrescriptionType.METFORMIN_ER_750MG,
    PrescriptionType.METFORMIN_ER_1500MG,
    PrescriptionType.ICOSAPENT_ETHYL_1G,
    PrescriptionType.ICOSAPENT_ETHYL_2G,
    PrescriptionType.ICOSAPENT_ETHYL_4G,
    PrescriptionType.MOUNJARO_12500MCG,
  ],
  testCodes: [
    TestCode.HOMAIR,
    TestCode.GLUCOSE,
    TestCode.INSULIN,
    TestCode.HBA1C,
    TestCode.TGI,
  ]
}, {
  // vitamin d
  prescriptionTypes: [
    PrescriptionType.VITAMIN_D_5000IU,
    PrescriptionType.VITAMIN_D_10000IU,
  ],
  testCodes: [
    TestCode.VITAMIN_D,
  ]
}, {
  // vitamin b12
  prescriptionTypes: [
    PrescriptionType.VITAMIN_B12_5000MCG,
  ],
  testCodes: [
    TestCode.VITAMIN_B12,
    TestCode.HOMOCYSTEINE,
  ]
}, {
  // vitamin b9
  prescriptionTypes: [
    PrescriptionType.VITAMIN_B9_1000MCG,
  ],
  testCodes: [
    TestCode.FOLATE,
    TestCode.HOMOCYSTEINE,
  ]
}]

const LABEL_SPACING = 47 // pixels between labels

const { Text, Paragraph } = Typography;

const riskColors = {
  [RiskLevel.OPTIMAL]: Color.success,
  [RiskLevel.MODERATE]: Color.warning,
  [RiskLevel.HIGH]: Color.error,
  [RiskLevel.ABNORMAL]: Color.error,
}

const riskBackgroundColors = {
  [RiskLevel.OPTIMAL]: Color.success_bg,
  [RiskLevel.MODERATE]: Color.warning_bg,
  [RiskLevel.HIGH]: Color.error_bg,
  [RiskLevel.ABNORMAL]: Color.error_bg,
}

const riskLabels = {
  [RiskLevel.OPTIMAL]: 'Optimal',
  [RiskLevel.MODERATE]: 'Borderline risk',
  [RiskLevel.HIGH]: 'At risk',
  [RiskLevel.ABNORMAL]: 'At risk'
}

export const TestChart = ({ 
  id 
}) => {
  const [allPrescriptionEvents, setAllPrescriptionEvents] = useState([])
  const [labelPositions, setLabelPositions] = useState([]);
  const chartRef = useRef(null);
  const { patientId } = useParams()
  const isApoe = id === APOE_TEST_ID
  const [test, setTest] = useState(null)
  const [results, setResults] = useState([])
  const { currentUser } = useContext(UserContext)
  const [chartData, setChartData] = useState(null)
  const [stats, setStats] = useState({})
  const [chartOptions, setChartOptions] = useState(null)
  const [reports, setReports] = useState([])
  const [prescriptionEvents, setPrescriptionEvents] = useState([])
  const [biggestOffset, setBiggestOffset] = useState(false)
  const [chartTopPadding, setChartTopPadding] = useState(20)
  const [allResults, setAllResults] = useState([])
  const [riskRanges, setRiskRanges] = useState([])

  useEffect(() => {
    if (!test || !labelPositions?.length) {
      setChartTopPadding(0)
    } else if (isApoe) {
      setChartTopPadding(20)
    } else {
      setChartTopPadding(biggestOffset ? (biggestOffset + LABEL_SPACING - 7) : labelPositions?.length ? 40 : 20)
    }
  }, [labelPositions, isApoe, biggestOffset])

  // Update positions when chart or events change
  useEffect(() => {
    fetchLabelPositions()
  }, [prescriptionEvents, chartData, chartOptions]);

  useEffect(() => {
    fetchTest()
  }, [id, currentUser])

  useEffect(() => {
    fetchRiskRanges()
  }, [id, patientId])

  useEffect(() => {
    if (test && allPrescriptionEvents?.length) {
      setPrescriptionEvents(allPrescriptionEvents.filter(onFilterPrescriptionEvent))
    } else {
      setPrescriptionEvents([])
    }
  }, [allPrescriptionEvents, test])

  // Add resize listener
  useEffect(() => {
    const handleResize = () => {
      fetchLabelPositions(); // Reset positions
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    fetchAllResults()
    fetchReports()
  }, [currentUser, patientId])

  useEffect(() => {
    fetchResults()
  }, [allResults, id])

  useEffect(() => {
    fetchChartData()
  }, [results, reports, riskRanges])

  useEffect(() => {
    fetchStats()
  }, [results, reports, test, patientId, prescriptionEvents, isApoe])

  useEffect(() => {
    fetchChartOptions()
  }, [results, reports, test, patientId, prescriptionEvents, isApoe, riskRanges])

  useEffect(() => {
    fetchPrescriptionEvents()
  }, [currentUser, patientId])

  const onFilterPrescriptionEvent = (event) => {
    const { code } = test
    const prescriptionTypes = prescriptionEffects
      .filter(({ testCodes }) => testCodes.includes(code))
      .reduce((acc, { prescriptionTypes }) => [...acc, ...prescriptionTypes], [])
    
    return prescriptionTypes.includes(event.prescriptionType)
  }

  const fetchLabelPositions = () => {
    setLabelPositions([])

    if (!chartOptions || !chartData || !chartRef.current || !prescriptionEvents.length) {
      return;
    }

    const timer = setTimeout(() => {
      const chart = chartRef.current;
      const sortedEvents = [...prescriptionEvents].sort((a, b) => 
        new Date(a.timestamp) - new Date(b.timestamp)
      );
      
      const offset = sortedEvents.reduce((acc, event, index) => {
        const yOffset = getLabelOffset(event, index, prescriptionEvents);
        return Math.max(acc, yOffset || 0);
      }, 0);
      
      const positions = sortedEvents.map((event, index) => {
        const xPosition = chart.scales.x.getPixelForValue(new Date(event.timestamp));
        const yOffset = getLabelOffset(event, index, prescriptionEvents);

        const calculatedY = chart.chartArea.top - yOffset - 10;

        return {
          event,
          x: xPosition + 15,
          y: calculatedY + offset
        };
      });

      setBiggestOffset(offset)
      setLabelPositions(positions);
    }, 300);

    return () => clearTimeout(timer);
  }

  const fetchRiskRanges = async () => {
    if (!id || id === 'health-score') {
      setRiskRanges([])
      return
    }
    const riskRanges = await listRiskRanges(id, { patient: patientId })
    setRiskRanges(riskRanges.sort((a, b) => new Date(a.collectedAt) - new Date(b.collectedAt)))
  }

  const getLabelOffset = (currentEvent, index, sortedEvents) => {
    const TIME_PROXIMITY = 14 * 24 * 60 * 60 * 1000 // 30 days in milliseconds
    let offset = 0;
    // Look at all events before this one
    for (let i = 0; i < index; i++) {
      const prevEvent = sortedEvents[i];
      if (Math.abs(new Date(prevEvent.timestamp) - new Date(currentEvent.timestamp)) < TIME_PROXIMITY) {
        offset += LABEL_SPACING;
      }
    }
    return offset;
  };

  // New component for prescription labels
  const PrescriptionEventLabel = ({ event, x, y }) => (
    <div 
      className={`prescription-event ${event.eventType === PrescriptionEvent.PAUSED ? 'paused' : 'started'}`}
      style={{ 
        left: x,
        top: y+2,
        transform: 'translateX(-50%)',
      }}
    >
      <div className="event-type">{event.prescriptionType}</div>
      <div className="timestamp">{event.eventType} {moment(event.timestamp).format('MMM D, YYYY')}</div>
    </div>
  );

  const fetchPrescriptionEvents = async () => {
    if (!currentUser) return
    setAllPrescriptionEvents(await listPrescriptionEvents({ patient: patientId }))
  }

  const getTargets = () => {
    return id === 'health-score' ? reports : results
  }

  const fetchAllResults = async () => {
    if (!currentUser) return
    let params = {}
    if (currentUser?.role !== Role.PATIENT) {
      params.patient = patientId
    }
    let annotatedResults = await listAnnotatedResults(params)
    annotatedResults = annotatedResults
      .sort((a, b) => new Date(a.collectedAt) - new Date(b.collectedAt))
    setAllResults(annotatedResults)
  }

  const fetchResults = async () => {
    setResults(allResults.filter(onFilterResult).sort(onSortResults))
  }

  const fetchReports = async () => {
    if (!currentUser) return
    
    let filter = {
      healthScore: {
        '$exists': true,
        '$gt': 0
      },
      status: ReportStatus.APPROVED
    }
    if (currentUser?.role !== Role.PATIENT) {
      filter.patient = patientId
    }
    let fetchedReports = await listReports({
      filter,
      select: 'status healthScore',
      populate: [{
        path: 'result',
        select: 'collectedAt',
      }],
      sort: '-createdAt'
    })

    fetchedReports = fetchedReports.sort(onSortReport)
    setReports(fetchedReports)
  }

  const onSortReport = (a, b) => {
    return new Date(a.result.collectedAt) - new Date(b.result.collectedAt)
  }

  const onFilterResult = (result) => {
    return result.values.some(value => value.test === id && value.value)
  }

  const onSortResults = (a, b) => {
    return new Date(a.collectedAt) - new Date(b.collectedAt)
  }

  const getDistanceToOptimal = (testValue, testRisk) => {
    let distanceToOptimal = null

    if (testRisk !== RiskLevel.OPTIMAL) {
      let closestOptimalRange = null

      const optimalRanges = test.risk.filter(({ level }) => level === RiskLevel.OPTIMAL)
      for (const range of optimalRanges) {
        const {
          greaterThan,
          greaterThanOrEqual,
          lessThan,
          lessThanOrEqual
        } = range

        const rangeValue = greaterThanOrEqual || lessThanOrEqual || greaterThan || lessThan

        if (!closestOptimalRange || Math.abs(rangeValue - testValue) < Math.abs(closestOptimalRange - testValue)) {
          closestOptimalRange = rangeValue
        }
      }
      const decimalPlaces = Math.max(
        getDecimalPlaces(testValue), 
        getDecimalPlaces(closestOptimalRange)
      )
      distanceToOptimal = parseFloat((closestOptimalRange - testValue).toFixed(decimalPlaces))
    }

    return distanceToOptimal
  }

  const getDecimalPlaces = (num) => {
    if (typeof num !== 'number' || Math.floor(num) === num) return 0;
    return num.toString().split(".")[1]?.length || 0;
  }

  const formatNumber = (value) => {
    if (typeof value === 'string') {
      value = value.replace('<', '');
    }
    return !isNaN(value) ? Number(value) : value
  }

  const getHasImproved = (prevTarget, latestTarget) => {
    let prevValue, prevRisk, prevDistanceToOptimal = null
    if (prevTarget) {
      prevValue = getTargetValue(prevTarget)
      prevRisk = getTargetRisk(prevTarget)
      prevDistanceToOptimal = getDistanceToOptimal(prevValue, prevRisk)
    }
    const latestValue = getTargetValue(latestTarget)
    const latestRisk = getTargetRisk(latestTarget)
    const latestDistanceToOptimal = getDistanceToOptimal(latestValue, latestRisk)
    
    if (!prevDistanceToOptimal && !latestDistanceToOptimal) return null;
    return !latestDistanceToOptimal || Math.abs(latestDistanceToOptimal) < Math.abs(prevDistanceToOptimal) 
  }

  const fetchStats = async () => {
    const targets = getTargets()

    if (!targets.length || !test) return

    const latestTarget = targets[targets.length - 1]
    
    const latestRisk = getTargetRisk(latestTarget)
    const latestValue = getTargetValue(latestTarget)
    const latestTestDate = getTargetTestDate(latestTarget)

    let distanceToOptimal = getDistanceToOptimal(latestValue, latestRisk)

    let delta, prevTarget = null
    if (targets.length > 1) {
      prevTarget = targets[targets.length-2]
      const prevValue = getTargetValue(prevTarget)
      const decimalPlaces = Math.max(
        getDecimalPlaces(latestValue),
        getDecimalPlaces(prevValue)
      )
      delta = parseFloat((latestValue - prevValue).toFixed(decimalPlaces))
    }

    const hasImproved = getHasImproved(prevTarget, latestTarget)

    setStats({
      latestTestDate,
      latestRisk,
      latestValue,
      delta,
      distanceToOptimal,
      hasImproved
    })
  }

  const getTargetTestDate = (target) => {
    if (id === 'health-score') {
      return moment(target.result.collectedAt).format('MMM D, YYYY')
    } else {
      return moment(target.collectedAt).format('MMM D, YYYY')
    }
  }

  const getTargetRisk = (target) => {
    if (id === 'health-score') {
      const { healthScore } = target
      return healthScoreRisk.find(({ greaterThanOrEqual, lessThan }) => 
        (greaterThanOrEqual === undefined || healthScore >= greaterThanOrEqual) &&
        (lessThan === undefined || healthScore < lessThan)
      )?.level || RiskLevel.HIGH
    } else {
      return target.values.find(value => value.test === id)?.risk
    }
  }

  const getTargetValue = (target) => {
    if (id === 'health-score') {
      return formatNumber(target.healthScore)
    } else {
      return formatNumber(target.values.find(value => value.test === id)?.value)
    }
  }

  const fetchChartData = async () => {
    const targets = getTargets()
    if (!targets.length) return

    setChartData({
      datasets: [{
        tension: 0.1,
        pointRadius: 3.5,
        hoverRadius: 5,
        borderWidth: 2,
        borderColor: 'rgba(255,255,255,0)',
        data: targets.map(target => {
          const latestTestDate = getTargetTestDate(target)
          const testValue = getTargetValue(target)
          const testRisk = getTargetRisk(target)
          return {
            x: new Date(latestTestDate),
            y: formatNumber(testValue),
            risk: testRisk
          }
        }),
        pointBackgroundColor: (context) => {
          const index = context.dataIndex;
          if (typeof index !== 'number') return Color.secondary_bg
          const target = targets[index]
          const risk = getTargetRisk(target)
          return riskColors[risk];
        },
        segment: {
          borderColor: (context) => {
            const index = context.p0DataIndex;
            if (typeof index !== 'number' && results[index+1]) return Color.secondary_bg
            const target = targets[index+1]
            const risk = getTargetRisk(target)
            return riskColors[risk];
          }
        },
      }]
    })
  }
  
  const fetchChartOptions = () => {
    const targets = getTargets()
    
    if (!test || !targets?.length || (!isApoe && !riskRanges?.length)) {
      setChartOptions(null)
      return
    }

    if (isApoe) {
      setChartOptions({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month',
              displayFormats: {
                month: "MMM ''yy"
              }
            },
            title: {
              display: false,
            },
            grid: {
              display: false,
              drawBorder: true,
              z: 1,
            },
            ticks: {
              display: true,
              autoSkip: true,
              maxTicksLimit: 6,
              color: '#666666'    // Added color to make sure it's visible
            },
            border: {
              display: true      // Make sure border is displayed
            }
          },
          y: {
            type: 'category',
            labels: APOE_GENOTYPES,
            title: {
              display: true,
              text: 'Genotype'
            },
            grid: {
              z: 1,
            },
            offset: true,      // Centers labels between ticks
            ticks: {
              crossAlign: 'center'  // Centers labels vertically in their space
            },
            border: {
              dash: [2, 2],
            },
          }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false
          },
          annotation: {
            annotations: APOE_GENOTYPES.map((genotype, index) => {
              const risk = test.risk.find(r => {
                const { equal } = r
                return equal === genotype
              })?.level
  
              return {
                type: 'box',
                drawTime: 'beforeDatasetsDraw',
                xScaleID: 'x',
                yScaleID: 'y',
                yMin: index - 0.5,
                yMax: index + 0.5,
                backgroundColor: `${riskBackgroundColors[risk]}`,
                borderColor: 'transparent',
              }
            })
          },
          tooltip: {
            mode: 'nearest',
            intersect: false,
            callbacks: {
              title: function(context) {
                return format(new Date(context[0].parsed.x), 'MMM d, yyyy');
              },
              label: function(context) {
                const index = context.dataIndex;
                if (typeof index !== 'number') return
                const target = targets[index]
                const currentRisk = getTargetRisk(target)
                const genotype = getTargetValue(target)
                
                return [
                  ` Risk level:   ${riskLabels[currentRisk]}`,
                  ` Genotype:   ${genotype}`
                ]
              },
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: false,
        }
      })
    } else {
      setChartOptions({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'month',
              displayFormats: {
                month: "MMM ''yy"
              }
            },
            title: {
              display: false,
            },
            grid: {
              display: false,  // This will hide the vertical gridlines
              drawBorder: false, // This will keep the x-axis line visible
              z: 1,
            },
            min: (() => {
              let minDate = allResults.length ? moment(allResults[0].collectedAt) : null;
              
              // Check prescription events for earlier date
              if (prescriptionEvents?.length) {
                const earliestPrescription = moment(prescriptionEvents[0].timestamp);
                if (!minDate || earliestPrescription.isBefore(minDate)) {
                  minDate = earliestPrescription;
                }
              }
              
              return minDate ? minDate.startOf('month').toDate() : undefined;
            })(),
            max: (() => {
              let maxDate = allResults.length ? moment(allResults[allResults.length - 1].collectedAt) : null;
              
              // Check prescription events for later date
              if (prescriptionEvents?.length) {
                const latestPrescription = moment(prescriptionEvents[prescriptionEvents.length - 1].timestamp);
                if (!maxDate || latestPrescription.isAfter(maxDate)) {
                  maxDate = latestPrescription;
                }
              }
              
              return maxDate ? maxDate.endOf('month').toDate() : undefined;
            })(),
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: test?.unit
            },
            min: 0,
            border: {
              dash: [2, 2],
            },
            grid: {
              z: 1,
            }
          }
        },
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false
          },
          annotation: {
            annotations: {
              ...(riskRanges?.length > 0 ? 
              riskRanges.reduce((acc, { collectedAt, risk }, index) => {
                // Find previous range for x-axis start
                const prevRange = index > 0 ? riskRanges[index - 1] : null;

                // Find the latest date across all risk ranges
                const latestDate = Math.max(...riskRanges.map(r => new Date(r.collectedAt).getTime()));
                const isLastDate = new Date(collectedAt).getTime() === latestDate;
                
                let riskRangeIndex = 0
                for (const riskRange of risk) {
                  acc[`risk-range-${index}-${riskRangeIndex}`] = {
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    xScaleID: 'x',
                    yScaleID: 'y',
                    // X bounds: from previous date (or start of chart) to current date
                    xMin: prevRange ? new Date(prevRange.collectedAt) : undefined,
                    xMax: isLastDate ? undefined : new Date(collectedAt),
                    // Y bounds: from risk range values
                    yMin: riskRange.greaterThan || riskRange.greaterThanOrEqual || 0,
                    yMax: riskRange.lessThan || riskRange.lessThanOrEqual,
                    backgroundColor: `${riskBackgroundColors[riskRange.level]}`,
                    borderColor: 'transparent',
                    adjustScaleRange: true
                  }
                  riskRangeIndex += 1
                }
                return acc
              }, {}) :
              test.risk.filter(({
                equal
              }) => {
                return equal === undefined
              }).reduce((acc, riskItem, index) => {
                acc[`risk-${index}`] = {
                  type: 'box',
                  drawTime: 'beforeDatasetsDraw',
                  xScaleID: 'x',
                  yScaleID: 'y',
                  yMin: riskItem.greaterThan || riskItem.greaterThanOrEqual || 0,
                  yMax: riskItem.lessThan || riskItem.lessThanOrEqual,
                  backgroundColor: `${riskBackgroundColors[riskItem.level]}`,
                  borderColor: 'transparent',
                };
                return acc;
              }, {})),
              ...prescriptionEvents.reduce((acc, event, index) => {
                acc[`prescription-${index}`] = {
                  type: 'line',
                  scaleID: 'x',
                  value: new Date(event.timestamp),
                  borderColor: event.eventType === PrescriptionEvent.PAUSED ? stopColor : startColor,
                  borderDash: [2,2],
                  borderWidth: 1,
                  label: { display: false }
                };
                return acc;
              }, {})
            }
          },
          tooltip: {
            mode: 'nearest',
            intersect: false,
            callbacks: {
              title: function(context) {
                return format(new Date(context[0].parsed.x), 'MMM d, yyyy');
              },
              label: function(context) {
                const index = context.dataIndex;
                if (typeof index !== 'number') return
                const targets = getTargets()
                const target = targets[index]
                const currentRisk = getTargetRisk(target)
                const currentValue = getTargetValue(target)
                const distanceToOptimal = getDistanceToOptimal(currentValue, currentRisk)
    
                let delta = null
                if (index !== 0) {
                  const prevTarget = targets[index-1]
                  const prevValue = getTargetValue(prevTarget)
                  const decimalPlaces = Math.max(
                    getDecimalPlaces(currentValue),
                    getDecimalPlaces(prevValue),
                  )
                  delta = parseFloat((currentValue - prevValue).toFixed(decimalPlaces))
                  if (delta > 0) {
                    delta = `+${delta}`
                  }
                }
                let labels = [
                  ` Risk level:             ${riskLabels[currentRisk]}`,
                  ` Test value:            ${context.parsed.y}${test?.unit ? ` ${test.unit}` : ``}`,
                ]
                if (distanceToOptimal !== null) {
                  labels.push(` To reach optimal: ${distanceToOptimal > 0 ? '+' : ''}${distanceToOptimal}${test?.unit ? ` ${test.unit}` : ``}`)
                }
                if (delta !== null) {
                  labels.push(` Change:               ${delta}${test?.unit ? ` ${test.unit}` : ``} from previous`)
                }
                return labels
              },
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: false,
        }
      })
    }
  }

  const getTrendColor = (stats) => {
    if (stats?.hasImproved === null) {
      return Color.primary_text
    } else if (stats?.hasImproved) {
      return Color.success
    } else {
      return Color.error
    }
  }

  const fetchTest = async () => {
    if (!id || !currentUser) {
      setTest(null)
      return
    }

    if (id === 'health-score') {
      setTest({
        code: id,
        name: `Longevity Score`,
        unit: '%',
        details: `The longevity score, a scale from 0 to 100, is a comprehensive measure of your overall health and its potential impact on both your lifespan and healthspan. The higher your score, the closer you are to optimal health and the more potential you may have for disease-free years.
        <br/><br/>The score is calculated using a statistical algorithm that considers factors such as your biomarker levels, family and medical history, and current lifestyle. It asks the question: if everything stayed the same from today onward, how much are you at risk for aging-related diseases, including cardiovascular disease, diabetes, hypertension, dementia, chronic kidney disease, and more. By making changes to improve these factors, you can change the trajectory of your longevity score significantly.
        <br/><br/>Your percentile is determined by comparing scores with other Instalab users of the same age and biological sex.`,
        risk: healthScoreRisk,
        absoluteCategory: {
          name: 'Calculated',
        }
      })
    } else {
      let params = {}
      if (currentUser?.role !== Role.PATIENT) {
        params.patient = patientId
      }
      setTest(await getAnnotatedTest(id, params))
    }
  }

  const formatter = (startValue, endValue) => {
    return (
      <CountUp 
        start={startValue}
        end={endValue} 
        separator="," 
        decimals={Math.max(
          getDecimalPlaces(startValue),
          getDecimalPlaces(endValue)
        )}
      />
    )
  }

  return (test && chartData && chartOptions && (isApoe || riskRanges?.length)) ? (
    <div className={`test-chart-component`}>
      <Row className="test-chart-row">
        <Col 
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 16 }}
          xxl={{ span: 16 }}
        > 
          <div className="chart-area">
            <div 
              className="chart-container"
              style={{
                paddingTop: chartTopPadding
              }}
            >
              <Line 
                ref={chartRef}
                data={chartData} 
                options={chartOptions} 
              />

              {labelPositions.map((pos, index) => (
                <PrescriptionEventLabel 
                  key={index}
                  event={pos.event}
                  x={pos.x}
                  y={pos.y}
                />
              ))}
            </div>
          </div>  
        </Col>

        <Col 
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 8 }}
          xxl={{ span: 8 }}
        >
          <div className="test-chart-sidebar">
            <div className="sidebar-content">
              <div className="latest-result">
                {isApoe ? (
                  <Row gutter={12}>
                    <Col span={12}>
                      <Statistic 
                        title="Test Date" 
                        value={stats?.latestTestDate} 
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic 
                        title="Type" 
                        value={test?.absoluteCategory?.name} 
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic 
                        title="Risk" 
                        value={stats?.latestRisk && riskLabels[stats.latestRisk]} 
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic 
                        title="Test Value" 
                        value={stats?.latestValue} 
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={12}>
                    <Col span={12}>
                      <Statistic 
                        title={getTargets()?.length > 1 ? 'Last Test Date' : 'Test Date'}
                        value={stats?.latestTestDate} 
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic 
                        title="Type" 
                        value={test?.absoluteCategory?.name} 
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic 
                        title="Current Risk" 
                        value={stats?.latestRisk && riskLabels[stats.latestRisk]} 
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic 
                        title="Current Test Value" 
                        value={stats?.latestValue} 
                        formatter={formatter} 
                        suffix={test?.unit}
                      />
                    </Col>
                    {stats?.distanceToOptimal ? (
                      <Col span={12}>
                        <Statistic 
                          title="To Reach Optimal" 
                          value={stats?.distanceToOptimal && Math.abs(stats.distanceToOptimal)} 
                          formatter={formatter} 
                          suffix={test?.unit}
                          prefix={stats?.distanceToOptimal === 0 ? '' : stats?.distanceToOptimal > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                        />
                      </Col>
                    ) : null}
                    {getTargets()?.length > 1 ? (
                      <Col span={12}>
                        <Statistic 
                          title="Change From Previous" 
                          value={stats?.delta && Math.abs(stats.delta)} 
                          formatter={formatter} 
                          suffix={test?.unit}
                          prefix={stats?.delta === 0 ? '' : stats?.delta > 0 ? <ArrowUpOutlined style={{ color: getTrendColor(stats) }} /> : <ArrowDownOutlined style={{ color: getTrendColor(stats) }} />}
                        />
                      </Col>
                    ) : null}
                  </Row>
                )}
              </div>

              {test.details ? (
                <Paragraph className="test-details">{parse(test.details)}</Paragraph>
              ) : (
                <Paragraph className="test-details">{test.tagline}</Paragraph>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  ) : (
    <div className="test-chart-loading"> 
      <div className="loading-text">
        <Spin className="loading-icon" /> <Text className="loading-text">Loading...</Text>
      </div>
    </div>
  )
}
