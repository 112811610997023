import TestCode from "../enums/testCode.enum";

export const heartHealthPanel = {
  Lipoproteins: [
    TestCode.APO_B, 
    TestCode.LIPOPROTEIN_A,
  ],
  Lipids: [
    TestCode.TC,
    TestCode.DIRECT_LDL,
    TestCode.HDL,
    TestCode.VLDL,
    TestCode.TG,
  ],
  "Insulin Resistance": [
    TestCode.HBA1C
  ],
  Inflammation: [
    TestCode.HS_CRP,
  ],
};