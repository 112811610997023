import { Button } from 'antd'
import { PlusSquareOutlined } from '@ant-design/icons'
import './sidebarScheduleBtnLayout.scss';
import { useNavigate } from 'react-router-dom';
import { EventType, Role } from '../../enums/index.enum';
import { addEvent } from '../../services/event.service';
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';

const SidebarScheduleBtnLayout = () => {
  const navigate = useNavigate()
  const { currentUser, instalabMembership, token } = useContext(UserContext)

  const onMember = async () => {
    await addEvent({eventType: EventType.CLICK_SIGNUP_MEMBERSHIP_SIDEBAR})
    navigate('patient/memberships')  
  }

  const onSignUp = async () => {
    await addEvent({eventType: EventType.CLICK_SIGNUP_SIDEBAR})
    navigate('flow/intake')  
  }

  return <>
  {!token && currentUser?.role === Role.PATIENT && (
        <Button 
      id={'member-cta-sidebar'}
      className="sidebar-schedule-btn-layout"
      onClick={onSignUp}
      icon={<PlusSquareOutlined />}
      type='primary'
    >
      Sign Up
      </Button>
  )}
  {token && !instalabMembership && currentUser?.role === Role.PATIENT && <Button 
      id={'member-cta-sidebar'}
      className="sidebar-schedule-btn-layout"
      onClick={onMember}
      icon={<PlusSquareOutlined />}
      type='primary'
    >
      Become Member
    </Button>}
  </>
}

export default SidebarScheduleBtnLayout